import React, { useState, useEffect } from "react";
import { Select, notification } from "antd";
import { View, Text, StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import ajaxCall from "./shared-components/ajaxCall";

function TaskAction(props) {
  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [data, setData] = useState([]);

  const reload = () => props.refresh();

  const getTaskActions = async () => {
    const json = await ajaxCall(
      global.urlBase + "tasks/" + props.taskId + "/actions"
    );
    const jsonArray = json.map((obj) => {
      return { value: obj.id, label: obj.description };
    });
    setData(jsonArray);
    setSelectedOptions([]);
  };

  useEffect(() => {
    getTaskActions();
  }, [props.taskId]);

  const handleSelect = (value) => {
    const selectedOption = data.find((option) => option.value === value);
    setSelectedOptions([...selectedOptions, selectedOption]);
    fetch(global.urlBase + "actions/" + value + "/run/" + props.taskId, {
      headers: new Headers({ Authorization: "Bearer " + global.token }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json == "success") {
          notification.success({
            message: 'Success',
            description: selectedOption.label + " " + "has been selected",
            duration: 2,
            onClose: () => props.refresh(),
          });
        }
        if (json == "error") {
          notification.error({
            message: 'Error',
            description: data.label,
            duration: 2,
            onClose: () => props.refresh(),
          });
        }
      });
  };

  return (
    <View style={styles.container}>
      <Text style={{ fontWeight: "bold", fontSize: RFValue(8, screen.height) }}>
        Action
      </Text>
      <Select
        onBlur={props.onActionDropdownBlur}
        onFocus={props.onActionDropdownFocus}
        placeholder="Select Action"
        options={data}
        value={selectedOptions}
        onChange={handleSelect}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: -5,
    flex: 1,
    margin: 8,
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
  },
});

export default TaskAction;
