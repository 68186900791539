import GenericButton from "./genericButton";
import { CheckOutlined } from "@ant-design/icons";
import { useEffect } from "react";

function ClaimedButton({
  claimedPressed,
  taskData,
  setClaimedPressed,
  listOfPrimaryUsers,
  listOfSecondaryUsers,
  setListOfPrimaryUsers,
  setListOfSecondaryUsers,
  refresh
}) {
  const handleClaimUnclaim = async (claim) => {
    try {
      const url =
        global.urlBase + `tasks/${taskData.id}/${claim ? "claim" : "unclaim"}`;
      const response = await fetch(url, {
        headers: new Headers({ Authorization: "Bearer " + global.token }),
      });
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const handleClick = (claim) => {
    if (claim) {
      if (listOfPrimaryUsers.includes(global.userInfo.id)) {
        setListOfPrimaryUsers(
          listOfPrimaryUsers.filter((item) => item !== global.userInfo.id)
        );
      }
      if (
        listOfSecondaryUsers &&
        listOfSecondaryUsers.includes(global.userInfo.id)
      ) {
        setListOfSecondaryUsers(
          listOfSecondaryUsers.filter((item) => item !== global.userInfo.id)
        );
      }
    }
    if (!claim) {
      if (
        listOfPrimaryUsers === undefined ||
        (listOfPrimaryUsers !== undefined && listOfPrimaryUsers.length === 0)
      ) {
        setListOfPrimaryUsers([global.userInfo.id]);
        return;
      }
      if (
        listOfSecondaryUsers &&
        !listOfSecondaryUsers.includes(global.userInfo.id)
      ) {
        const myArray = listOfSecondaryUsers;
        const myNewArray = [...myArray, global.userInfo.id];
        setListOfSecondaryUsers(myNewArray);
      }
    }
    setClaimedPressed(!claimedPressed);
    handleClaimUnclaim(!claimedPressed);
  };

  return (
    <>
      {claimedPressed && (
        <GenericButton
          buttonColor={"rgba(19, 196, 38, 0.8)"}
          buttonText={"Claimed"}
          icon={<CheckOutlined />}
          onClick={() => {
            handleClick(true);
            handleClaimUnclaim(false);
          }}
        />
      )}
      {!claimedPressed && (
        <GenericButton
          buttonText={"Claim this task"}
          onClick={() => {
            handleClick(false);
            handleClaimUnclaim(true);
          }}
        />
      )}
    </>
  );
}

export default ClaimedButton;
