import ScheduledTaskList from "../components/ScheduledTaskList.js";

const ScheduledTaskListPage = () => {
  return (
    <>
      <ScheduledTaskList
        userID="4"
        onPress={() => {
          alert("Manager");
        }}
      />
    </>
  );
};

export default ScheduledTaskListPage;
