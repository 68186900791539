import StatusesList from "../components/StatusesListView";

const StatusesListPage = () => {
  return (
    <>
      <StatusesList />
    </>
  );
};

export default StatusesListPage;
