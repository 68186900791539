import ajaxCall from "./shared-components/ajaxCall";
import { View, StyleSheet } from "react-native";
import { useEffect, useState } from "react";
import { Layout, Table, Button } from "antd";
import StatusModal from "./shared-components/statusViewModal";

function StatusesList() {
  const { Content, Header } = Layout;
  const [statusesData, setStatusesData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rowId, setRowId] = useState(undefined);

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (!visible) {
      setRowId(undefined);
    }
  }, [visible]);

  const getStatuses = async () => {
    if (statusesData.length === 0) {
      const json = await ajaxCall(global.urlBase + "statuses");
      setStatusesData(json);
    }
  };

  useEffect(() => {
    getStatuses();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "First/Last",
      dataIndex: "firstlast",
      key: "firstlast",
    },
  ];

  const processedData = statusesData.map((item) => ({
    key: item.id,
    id: item.id && item.id,
    name: item.status && item.status,
    firstlast:
      item.is_first_status === 1
        ? "First"
        : item.is_last_status === 1
        ? "Last"
        : "",
  }));

  return (
    <Layout>
      <Header style={styles.header}>
        <Button onClick={showModal}>Create Status</Button>
      </Header>
      <View
        style={{
          backgroundColor: "white",
          borderTopColor: "rgb(33, 150, 243)",
          borderTopWidth: 10,
          borderRadius: 10,
          margin: 20,
        }}
      >
        <Content>
          <Table
            dataSource={processedData}
            columns={columns}
            loading={statusesData.length > 0 ? false : true}
            onRow={(record) => {
              return {
                onClick: () => {
                  setRowId(record.key);
                  showModal();
                },
                style: {
                  cursor: "pointer",
                },
              };
            }}
          />
          <StatusModal
            visible={visible}
            setVisible={setVisible}
            rowId={rowId}
            statusesData={statusesData}
          />
        </Content>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  header: {
    height: "auto",
    display: "flex",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#F2F2F2",
  },
});

export default StatusesList;
