import { Button } from "antd";
import { useState } from "react";

const modifyColor = (color) => {
  // Extract the red, green, and blue values from the input color string
  const [, red, green, blue] = /rgba\((\d+), (\d+), (\d+),/.exec(color);

  // Modify the red, green, and blue values
  const modifiedRed = parseInt(red, 10) + 50;
  const modifiedGreen = parseInt(green, 10) + 50;
  const modifiedBlue = parseInt(blue, 10) + 50;

  // Create a new color string with the modified red, green, and blue values
  const modifiedColor = `rgba(${modifiedRed}, ${modifiedGreen}, ${modifiedBlue}, 0.8)`;

  return modifiedColor;
};

function GenericButton({
  buttonColor = "rgba(0, 0, 165, 0.8)",
  buttonText,
  fontColor,
  fontSize = 12,
  fontWeight = "bold",
  height = 50,
  hoverInButtonColor = modifyColor(buttonColor),
  icon,
  onClick,
  type = "primary", // primary, ghost, dashed, link, text, default
  width = 125,
}) {
  const [color, setColor] = useState(buttonColor);
  return (
    <>
      <Button
        icon={icon}
        onClick={onClick}
        onMouseEnter={() => setColor(hoverInButtonColor)}
        onMouseLeave={() => setColor(buttonColor)}
        style={{
          backgroundColor: color,
          color: fontColor,
          fontSize: fontSize,
          fontWeight: fontWeight,
          height: height,
          width: width,
        }}
        type={type}
      >
        {buttonText}
      </Button>
    </>
  );
}

export default GenericButton;
