import CategoriesList from "../components/CategoriesListView";

const CategoriesListPage = () => {
  return (
    <>
      <CategoriesList />
    </>
  );
};

export default CategoriesListPage;
