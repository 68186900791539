import { View, Text, StyleSheet, Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

const screen = Dimensions.get("screen");

function LineItem(props) {
  if (props.data !== undefined) {
    return (
      <View style={styles.container}>
        <Text style={styles.label}>{props.label}</Text>
        <Text onPress={props.onPress} style={styles.body}>
          {props.body}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: RFValue(7, screen.height),
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
  },
  label: {
    flex: 1,
    fontSize: RFValue(9, screen.height),
    fontWeight: "bold",
  },
  body: {
    flex: 5,
    fontSize: RFValue(8, screen.height),
  },
});

export default LineItem;
