import "./taskList.css";
import { useState, useRef } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Dimensions } from "react-native";

const windowWidth = Dimensions.get("window");
const screen = Dimensions.get("screen");


function DraggableModal({
  className,
  open,
  footer,
  title,
  onCancel,
  modalBody,
  maskStyle,
  style,
  onOk,
  handleRefresh,
  bodyStyle,
  disableDragging = [],
  shouldDisableDrag
}) {
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };
  
  return (
    <Modal
      width={windowWidth.width <= 1050 ? 750 : undefined}
      className={className}
      open={open}
      footer={footer}
      title={title}
      centered
      handleRefresh={handleRefresh}
      style={style}
      maskStyle={maskStyle}
      onOk={onOk}
      onCancel={onCancel}
      bodyStyle={bodyStyle}
      modalRender={(modal) => {
        const isDraggingDisabled =
          isTouchDevice() || disableDragging.some((value) => value === true);
        return (
          <Draggable
            disabled={isDraggingDisabled || shouldDisableDrag}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        );
      }}
    >
      {modalBody}
    </Modal>
  );
}

export default DraggableModal;