import React, { useEffect, useState, useRef } from "react";
import { Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import ajaxCall from "./shared-components/ajaxCall";
import ProfileIconStack from "./shared-components/profileIconStack";
import "./shared-components/taskList.css";
import TaskListLayout from "./shared-components/taskListLayout";
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";
import dayjs from "dayjs";

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function TaskList() {
  const [data, setData] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [mountedRef, setMountedRef] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deferToggle, setDeferToggle] = useState(false);
  const [claimedToggle, setClaimedToggle] = useState(false);
  const [selectedViewValue, setSelectedViewValue] = useState("normal view");
  const [selectedCategoryValue, setSelectedCategoryValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [completedTasksModal, setCompletedTasksModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [departmentItems, setDepartmentItems] = useState([]);
  const [departmentView, setDepartmentView] = useState([]);
  const [departmentIds, setDepartmentIds] = useState([]);
  const [employeeDepartmentIds, setEmployeeDepartmentIds] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employeeDropdownItems, setEmployeeDropdownItems] = useState([]);
  const [employeeDropdownValue, setEmployeeDropdownValue] = useState([]);
  const [url, setUrl] = useState(null);
  const [subTaskItemPressed, setSubTaskItemPressed] = useState(false);
  const [firstDropdownOptions, setFirstDropdownOptions] = useState([
    { label: "Category View", value: "category view" },
    { label: "Normal View", value: "normal view" },
  ]);
  const [claimedPressed, setClaimedPressed] = useState(false);
  const [headerProps, setHeaderProps] = useState([]);

  const [rollLogHistory, setRollLogHistory] = useState([]);
  const getRolledUpTasks = async () => {
    const json = await ajaxCall(
      global.urlBase + "rollUpLogHistory/rolledTo/" + global.userInfo.id
    );
    setRollLogHistory(json);
  };
  useEffect(() => {
    if (global.userInfo !== undefined) {
      getRolledUpTasks();
    }
  }, [global.userInfo]);

  const [allRollLogs, setAllRollLogs] = useState([]);

  const getAllRolledUpTasks = async () => {
    const json = await ajaxCall(global.urlBase + "rollUpLogHistory");
    setAllRollLogs(json);
  };
  useEffect(() => {
    if (global.userInfo !== undefined) {
      getAllRolledUpTasks();
    }
  }, [global.userInfo]);

  const getTasks = async () => {
    const json = await ajaxCall(url);
    getRolledUpTasks();
    getAllRolledUpTasks();
    if (json.length === 0) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else {
      setLoading(false);
    }
    const tasksWithMessages = json.map(task => ({
      ...task,
      messages: task.messages || [],
    }));
    setData(tasksWithMessages);
  };
  
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    const json = await ajaxCall(
      "https://wrapperapi.integrityprodserver.com/api/users"
    );
    setUsers(json);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const [rolledUpToggle, setRolledUpToggle] = useState(false);
  const [rolledUpTasksData, setRolledUpTasksData] = useState([]);

  useEffect(() => {
    if (url !== null) {
      getTasks();
    }
  }, [url]);

  useEffect(() => {
    if (!modalVisible && subTaskItemPressed) {
      getTasks();
      setSubTaskItemPressed(false);
    }
  }, [modalVisible, subTaskItemPressed]);

  const handleUserInfoData = () => {
    if (global.userInfo !== undefined) {
      const managerOfDepartmentsArray = global.userInfo.managerOfDepartments;
      setDepartmentIds(
        managerOfDepartmentsArray.map((obj) => {
          return obj.id;
        })
      );
      const departmentList = managerOfDepartmentsArray.map((obj) => {
        return { label: obj.name, value: obj.id, departmentId: obj.id };
      });
      setDepartmentItems(departmentList);
      const employeesArray = global.userInfo.employees;
      setEmployeeDepartmentIds(
        employeesArray.map((obj) => {
          return obj;
        })
      );

      const employeeList = employeesArray.map((obj) => {
        return {
          label: obj.name,
          value: obj.id,
          departmentId: obj.department.id,
        };
      });
      if (departmentView.length === 0) {
        setEmployeeDropdownItems(employeeList);
      }
      setAllEmployees(employeeList);
      handleEmployeeDropdownItems();
    }
  };

  const addEmployeeViewToCategoryDropdown = () => {
    if (global.userInfo !== undefined) {
      if (global.userInfo.employees.length > 0) {
        const employeeViewOption = {
          label: "Employee View",
          value: "employee view",
        };
        const options = firstDropdownOptions.concat(employeeViewOption);
        const uniqueOptions = Array.from(
          new Set(options.map((option) => option.value))
        ).map((value) => {
          return options.find((option) => option.value === value);
        });
        setFirstDropdownOptions(uniqueOptions);
      } else {
        if (selectedViewValue === "employee view") {
          removeSelectedCategory();
          setSelectedViewValue("normal view");
        }
      }
    }
  };

  const handleEmployeeDropdownItems = () => {
    if (departmentView.length > 0) {
      var selectedDepartments = departmentView;
      var availableEmployees = [];

      allEmployees.forEach(function (ele) {
        if (selectedDepartments.includes(ele.departmentId)) {
          availableEmployees.push(ele);
        }
      });
      setEmployeeDropdownItems(availableEmployees);
    } else {
      allEmployees.length > 0
        ? setEmployeeDropdownItems(allEmployees)
        : undefined;
    }
  };

  useEffect(() => {
    addEmployeeViewToCategoryDropdown();
  }, [global.userInfo]);

  useEffect(() => {
    headerPropFunction();
  }, [
    firstDropdownOptions,
    selectedViewValue,
    selectedDates,
    categoryDropdownOptions,
    selectedCategoryValue,
    deferToggle,
    claimedToggle,
    data,
    rolledUpToggle,
  ]);

  const filterData = () => {
    var dataSet = data;
    var rolledUpDataSet = rollLogHistory;

    if (
      (!rolledUpToggle && selectedViewValue === "normal view") ||
      selectedViewValue === "category view"
    ) {
      const dataSetIds = new Set(dataSet.map((item) => item.id));
      // Filter rolledUpDataSet based on dataSetIds
      rolledUpDataSet = rolledUpDataSet.filter((item) =>
        dataSetIds.has(item.taskID)
      );
      const taskIDsFromRolledUpData = rolledUpDataSet.map(
        (item) => item.taskID
      );
      const uniqueTaskIDsFromRolledUpData = Array.from(
        new Set(taskIDsFromRolledUpData)
      );
      const rolledUpTasks = dataSet.filter((task) =>
        uniqueTaskIDsFromRolledUpData.includes(task.id)
      );
      const rolledUpTaskIds = rolledUpTasks.map((task) => task.id);
      dataSet = dataSet.filter((task) => !rolledUpTaskIds.includes(task.id));
      rolledUpDataSet = rolledUpDataSet.map((rollLog) => {
        const correspondingTask = rolledUpTasks.find(
          (task) => task.id === rollLog.taskID
        );
        return {
          user_id: parseInt(rollLog.rolledFrom),
          rollUpLogHistory: rollLog,
          data: correspondingTask,
        };
      });
      rolledUpDataSet = rolledUpDataSet.reduce((acc, item) => {
        const userId = item.user_id;
        const userIndex = acc.findIndex((user) => user.user_id === userId);
        if (userIndex === -1) {
          acc.push({
            user_id: userId,
            data: [item],
          });
        } else {
          acc[userIndex].data.push(item);
        }
        return acc;
      }, []);
    }
    dataSet = dataSet.filter((obj) =>
      obj.status ? obj.status.is_last_status == 0 : null
    );
    rolledUpDataSet = !rolledUpToggle && selectedViewValue === 'normal view' || selectedViewValue === 'category view'
      ? rolledUpDataSet.filter((obj) =>
          obj.data.some((obj) =>
            obj.data && obj.data.status
              ? obj.data.status.is_last_status == 0
              : false
          )
        )
      : [];

    if (inputValue !== "") {
      dataSet = dataSet.filter((obj) => {
        return (
          obj.name.toLowerCase().includes(inputValue) ||
          obj.name.includes(inputValue)
        );
      });

      rolledUpDataSet = !rolledUpToggle
        ? rolledUpDataSet
            .map((user) => {
              const filteredData = user.data.filter((obj) => {
                return (
                  obj.data &&
                  obj.data.name &&
                  (obj.data.name.toLowerCase().includes(inputValue) ||
                    obj.data.name.includes(inputValue))
                );
              });
              return {
                user_id: user.user_id,
                data: filteredData,
              };
            })
            .filter((user) => user.data.length > 0)
        : [];
    }

    if (selectedDates.length > 0) {
      if (selectedDates.some((date) => date === "")) {
        setSelectedDates([]);
      }
      dataSet = dataSet.filter(
        (obj) =>
          new Date(obj.due_date) >= new Date(selectedDates[0]) &&
          new Date(obj.due_date) <= new Date(selectedDates[1])
      );
      rolledUpDataSet = !rolledUpToggle
        ? rolledUpDataSet
            .map((user) => ({
              user_id: user.user_id,
              data: user.data.filter(
                (obj) =>
                  obj.data &&
                  obj.data.due_date &&
                  new Date(obj.data.due_date) >= new Date(selectedDates[0]) &&
                  new Date(obj.data.due_date) <= new Date(selectedDates[1])
              ),
            }))
            .filter((user) => user.data.length > 0)
        : [];
    }

    if (selectedCategoryValue.length > 0) {
      dataSet = dataSet.filter((obj) => {
        return (
          obj.category !== null &&
          selectedCategoryValue.includes(obj.category.name)
        );
      });
      rolledUpDataSet = !rolledUpToggle
        ? rolledUpDataSet.map((user) => {
            return {
              user_id: user.user_id,
              data: user.data.filter((obj) => {
                return (
                  obj.data &&
                  obj.data.category &&
                  selectedCategoryValue.includes(obj.data.category.name)
                );
              }),
            };
          })
        : [];
    }

    if (deferToggle) {
      dataSet = dataSet.filter((item) => {
        return (
          parseInt(moment(item.defer_date).format("YYYYMMDD")) >
          parseInt(moment(new Date()).format("YYYYMMDD"))
        );
      });
      rolledUpDataSet = !rolledUpToggle
        ? rolledUpDataSet.map((user) => {
            return {
              user_id: user.user_id,
              data: user.data.filter((obj) => {
                return (
                  obj.data &&
                  obj.data.defer_date &&
                  parseInt(moment(obj.data.defer_date).format("YYYYMMDD")) >
                    parseInt(moment(new Date()).format("YYYYMMDD"))
                );
              }),
            };
          })
        : [];
    }

    if (!claimedToggle) {
      dataSet = dataSet.filter((item) => {
        if (
          (item.claimed_users &&
            item.claimed_users.Primary &&
            item.claimed_users.Primary.includes(global.userInfo.id)) ||
          (item.claimed_users &&
            item.claimed_users.Secondary &&
            Array.isArray(item.claimed_users.Secondary) &&
            item.claimed_users.Secondary.includes(global.userInfo.id)) ||
          !item.claimed_users
        ) {
          return true;
        }
      });

      rolledUpDataSet = !rolledUpToggle
        ? rolledUpDataSet.map((user) => {
            return {
              user_id: user.user_id,
              data: user.data.filter((obj) => {
                if (
                  (obj.data &&
                    obj.data.claimed_users &&
                    ((obj.data.claimed_users.Primary &&
                      obj.data.claimed_users.Primary.includes(
                        global.userInfo.id
                      )) ||
                      (obj.data.claimed_users.Secondary &&
                        obj.data.claimed_users.Secondary.includes(
                          global.userInfo.id
                        )))) ||
                  !obj.data.claimed_users
                ) {
                  return true;
                }
                return false;
              }),
            };
          })
        : [];
    }
    rolledUpDataSet = !rolledUpToggle
        ? rolledUpDataSet.map((user) => {
            return {
              user_id: user.user_id,
              data: user.data.filter((obj) => {
                return (
                  obj.data.status && obj.data.status.is_last_status !== 1
                );
              }),
            };
          })
        : [];
    setFilteredData(dataSet);
    setRolledUpTasksData(rolledUpDataSet);
  };

  useEffect(() => {
    filterData();
  }, [
    data,
    deferToggle,
    claimedToggle,
    selectedCategoryValue,
    inputValue,
    selectedDates,
    rolledUpToggle,
    selectedViewValue,
    rollLogHistory,
  ]);

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => a.rawPriority - b.rawPriority,
        multiple: 1,
      },
      className: "sorted",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
        multiple: 2,
      },
      className: "sorted",
    },
    {
      title: "Claimed Users",
      dataIndex: "claimedUsers",
      key: "claimedUsers",
      render: (claimedUsers) => {
        if (claimedUsers) {
          return (
            <>
              {claimedUsers.Primary && claimedUsers.Primary.length > 0 && (
                <ProfileIconStack
                  key={claimedUsers.Primary}
                  listOfUsers={claimedUsers.Primary}
                  marginRight={RFValue(10, screen.height)}
                  userObject={users}
                />
              )}
              {claimedUsers.Secondary && claimedUsers.Secondary.length > 0 && (
                <ProfileIconStack
                  key={claimedUsers.Secondary}
                  listOfUsers={claimedUsers.Secondary}
                  inactiveAvatarColor={"rgba(0, 0, 0, 1)"}
                  userObject={users}
                />
              )}
            </>
          );
        }
      },
    },
  ];

  if (deferToggle) {
    columns.push({
      title: "Defer Date",
      dataIndex: "deferDate",
      key: "deferDate",
    });
  }

  const priorityMap = {
    1: "Very High",
    2: "High",
    3: "High",
    4: "Medium",
    5: "Medium",
    6: "Medium",
    7: "Medium",
    8: "Low",
    9: "Low",
    10: "Low",
    11: "Very Low",
  };

  function getPriority(priority) {
    return priorityMap[priority] || "Unknown";
  }

  const processedRolledData = rolledUpTasksData.map((item) => ({
    key: item.id,
    category: item.category && item.category.name,
    rawPriority: item.priority,
    priority: item.priority && getPriority(item.priority),
    task: item.name && item.name,
    status: item.status && item.status.status,
    dueDate: item.due_date && dayjs(item.due_date).format("MM/DD/YYYY"),
    deferDate: item.defer_date && dayjs(item.defer_date).format("MM/DD/YYYY"),
    claimedUsers: item.claimed_users,
  }));

  const processedData = filteredData.map((item) => ({
    key: item.id,
    category: item.category && item.category.name,
    rawPriority: item.priority,
    priority: item.priority && getPriority(item.priority),
    task: item.name && item.name,
    status: item.status && item.status.status,
    dueDate: item.due_date && dayjs(item.due_date).format("MM/DD/YYYY"),
    deferDate: item.defer_date && dayjs(item.defer_date).format("MM/DD/YYYY"),
    claimedUsers: item.claimed_users,
  }));

  const completedTasks = data.filter((item) => {
    if (item.status && item.status.is_last_status === 1) {
      return item;
    }
  });

  const processedCompletedData = completedTasks.map((item) => ({
    key: item.id,
    category: item.category && item.category.name,
    rawPriority: item.priority,
    priority: item.priority && getPriority(item.priority),
    task: item.name && item.name,
    status: item.status && item.status.status,
    dueDate: item.due_date && dayjs(item.due_date).format("MM/DD/YYYY"),
    deferDate: item.defer_date && dayjs(item.defer_date).format("MM/DD/YYYY"),
    claimedUsers: item.claimed_users,
  }));

  const categoryDropdownOptions = data
    .map((item) => ({
      value: item.category ? item.category.name : null,
      label: item.category ? item.category.name : null,
    }))
    .filter(
      (item, index, self) =>
        self.findIndex((t) => t.value === item.value) === index
    )
    .filter((item) => item.value !== null);

  function headerPropFunction() {
    setHeaderProps({
      // search bar props
      searchPlaceholder: "Search Tasks",
      onInputChange: (event) => {
        setInputValue(event.target.value);
      },

      // datepicker props
      onDateChange: (date, dateString) => {
        setSelectedDates(dateString);
      },
      selectedDates: selectedDates,

      // choose table view props

      firstDropdownOptions: firstDropdownOptions,
      // firstDropdownDefaultValue: "normal view",
      secondDropdownOnChange: (value) => {
        setSelectedCategoryValue(value);
      },
      firstDropdownOnChange: (value) => {
        setSelectedViewValue(value);
      },
      selectedViewValue: selectedViewValue,

      // choose category view props
      secondDropdownPlaceholder: "Choose Category",
      secondDropdownOptions: categoryDropdownOptions,
      secondDropdownMode: "multiple",
      selectedCategoryValue: selectedCategoryValue,

      // future defer date switch props
      switchOne: deferToggle,
      handleSwitchOne: () => setDeferToggle(!deferToggle),
      switchOnePlaceholder: deferToggle
        ? "Displaying Future Defer Dates"
        : "Hiding Future Defer Dates",

      // claimed tasks switch props
      handleSwitchTwo: () => setClaimedToggle(!claimedToggle),
      switchTwo: claimedToggle,
      switchTwoPlaceholder: claimedToggle
        ? "All Claimed Tasks"
        : "My Claimed Tasks",

      // rolled up toggle props
      rolledUpToggle: rolledUpToggle,
      switchThree: rolledUpToggle,
      handleSwitchThree: () => setRolledUpToggle(!rolledUpToggle),
      switchThreePlaceholder: rolledUpToggle
        ? "Hiding Rolled Tasks Table"
        : "Displaying Rolled Tasks Table",
    });
  }

  const tableProps = {
    // employee view props
    employeeList: allEmployees,
    departmentItems: departmentItems,
    departmentViewOnChange: (value) => {
      setDepartmentView(value);
    },
    departmentView: departmentView,
    employeeDropdownItems: employeeDropdownItems,
    employeeDropdownOnChange: (value) => {
      setEmployeeDropdownValue(value);
    },
    employeeDropdownValue: employeeDropdownValue,
    tableLoading: loading ? true : false,
    columns: columns,
    processedRolledData: processedRolledData,
    processedData: processedData,
    completedTasks: completedTasks,
    processedCompletedData: processedCompletedData,
    claimedPressed: claimedPressed,
    setClaimedPressed: setClaimedPressed,
    selectedTable: selectedTable,
    handleRowClick: (record, table) => {
      return {
        onMouseEnter: (event) => {
          document.body.style.cursor = "pointer";
        },
        onMouseLeave: (event) => {
          document.body.style.cursor = "default";
        },
        onClick: () => {
          setSelectedTable(table);
          setSelectedRow(record);
          setRowId(record.key);
          setModalVisible(true);
        },
      };
    },
    onOk: () => setModalVisible(false),
    onCancel: () => {
      setModalVisible(false);
      setSelectedRow(null);
      setRowId(null);
    },
    modalVisible: modalVisible,
    setModalVisible: setModalVisible,
    completedTasksModal: completedTasksModal,
    setCompletedTasksModal: setCompletedTasksModal,
    selectedRow: selectedRow,
    rowClassName: (record, index) => {
      if (!record.dueDate || typeof record.dueDate !== "string") {
        return ""; // or some default row class name
      }

      let separator;
      if (record.dueDate.match(/\//)) {
        separator = "/";
      } else if (record.dueDate.match(/-/)) {
        separator = "-";
      } else {
        return ""; // or some default row class name
      }

      const [month, day, year] = record.dueDate.split(separator);
      const rearrangedDateStr = `${year}${month.padStart(2, "0")}${day.padStart(
        2,
        "0"
      )}`;
      // remove the slashes (/) from the date string
      const formattedDateStr = rearrangedDateStr.replace(/\//g, "");
      const date = parseInt(formattedDateStr);
      const today = parseInt(dayjs().format("YYYYMMDD"));
      if (date < today) {
        return "red-row";
      } else if (date === today) {
        return "yellow-row";
      } else {
        return "";
      }
    },
  };

  const readItemFromStorage = async () => {
    try {
      const chooseViewItem = await AsyncStorage.getItem("@category");
      if (
        chooseViewItem &&
        chooseViewItem !== undefined &&
        chooseViewItem !== ""
      ) {
        let array = chooseViewItem.split(",");
        setSelectedCategoryValue(array);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const writeItemToStorage = async () => {
    try {
      await AsyncStorage.setItem("@category", selectedCategoryValue);
    } catch (error) {
      console.log(error);
    }
  };

  const removeSelectedCategory = async () => {
    try {
      await AsyncStorage.removeItem("@category");
    } catch (e) {
      //remove error
    }
  };

  const getToggleData = async () => {
    try {
      const response = await AsyncStorage.getItem("@key");
      if (response === "true") {
        setDeferToggle(true);
      }
    } catch (e) {
      // read error
    }
  };

  const setToggleData = async () => {
    try {
      var toggleString = "";
      if (deferToggle) {
        var toggleString = "true";
      } else {
        var toggleString = "false";
      }
      await AsyncStorage.setItem("@key", toggleString);
    } catch (e) {
      // save error
    }
  };

  const getClaimedToggleData = async () => {
    try {
      const response = await AsyncStorage.getItem("@claimedKey");
      if (response === "true") {
        setClaimedToggle(true);
      }
    } catch (e) {
      // read error
    }
  };

  const setClaimedToggleData = async () => {
    try {
      await AsyncStorage.setItem(
        "@claimedKey",
        claimedToggle ? "true" : "false"
      );
    } catch (e) {
      // save error
    }
  };

  const setDueDateData = async () => {
    try {
      await AsyncStorage.setItem("@dueDate", selectedDates);
    } catch (error) {
      //save error
    }
  };

  const getDueDateData = async () => {
    try {
      let dateString = await AsyncStorage.getItem("@dueDate");
      let dueDateArray = dateString.split(",");
      setSelectedDates(dueDateArray);
    } catch (e) {
      // read error
    }
  };

  const getView = async () => {
    try {
      const categoryResponse = await AsyncStorage.getItem("@categoryKey");
      if (categoryResponse !== (undefined || null)) {
        setSelectedViewValue(categoryResponse);
      }
    } catch (e) {
      // read error
    }
  };

  const setView = async () => {
    try {
      await AsyncStorage.setItem("@categoryKey", selectedViewValue);
    } catch (e) {
      // save error
    }
  };

  const removeSelectedDepartments = async () => {
    try {
      await AsyncStorage.removeItem("@departmentsKey");
    } catch (e) {
      //remove error
    }
  };

  const storeSelectedDepartments = async () => {
    try {
      const stringedDepartmentView = departmentView.toString();
      await AsyncStorage.setItem("@departmentsKey", stringedDepartmentView);
    } catch (e) {
      // save error
    }
  };

  const getSelectedDepartments = async () => {
    try {
      var arrayOfSelectedDepartments = [];
      var arrayOfNumberedDepartments = [];
      const retrievedSelectedDepartments = await AsyncStorage.getItem(
        "@departmentsKey"
      );
      if (retrievedSelectedDepartments !== "NaN") {
        arrayOfSelectedDepartments.push(retrievedSelectedDepartments);
        for (let i = 0; i < arrayOfSelectedDepartments.length; i++) {
          const arrayOfSeparatedDepartmentStrings =
            arrayOfSelectedDepartments[i].split(",");
          for (let i = 0; i < arrayOfSeparatedDepartmentStrings.length; i++) {
            const stringToNumber = parseInt(
              arrayOfSeparatedDepartmentStrings[i]
            );
            arrayOfNumberedDepartments.push(stringToNumber);
          }
        }
      }
      setDepartmentView(arrayOfNumberedDepartments);
    } catch (e) {
      // save error
    }
  };

  const storeSelectedEmployees = async () => {
    try {
      const stringedEmployeeValue = employeeDropdownValue.toString();
      await AsyncStorage.setItem("@employeesKey", stringedEmployeeValue);
    } catch (e) {
      // save error
    }
  };

  const getSelectedEmployees = async () => {
    try {
      var arrayOfSelectedEmployees = [];
      var arrayOfNumberedEmployees = [];
      const retrievedSelectedEmployees = await AsyncStorage.getItem(
        "@employeesKey"
      );
      if (retrievedSelectedEmployees !== "NaN") {
        arrayOfSelectedEmployees.push(retrievedSelectedEmployees);
        for (let i = 0; i < arrayOfSelectedEmployees.length; i++) {
          const arrayOfSeparatedEmployeeStrings =
            arrayOfSelectedEmployees[i].split(",");
          for (let i = 0; i < arrayOfSeparatedEmployeeStrings.length; i++) {
            const stringToNumber = parseInt(arrayOfSeparatedEmployeeStrings[i]);
            arrayOfNumberedEmployees.push(stringToNumber);
          }
        }
      }
      setEmployeeDropdownValue(arrayOfNumberedEmployees);
    } catch (e) {
      // save error
    }
  };

  const removeSelectedEmployees = async () => {
    try {
      await AsyncStorage.removeItem("@employeesKey");
    } catch (e) {
      //remove error
    }
  };

  const changeUrlBasedOnEmployee = async () => {
    const employeesId = employeeDropdownItems.map((obj) => {
      return obj.value;
    });
    var selectedEmployeesArray = employeesId;
    if (employeeDropdownValue.length > 0) {
      selectedEmployeesArray = employeeDropdownValue;
    }
    var employeeArray = [];
    if (selectedEmployeesArray.length > 0) {
      for (let i = 0; i < selectedEmployeesArray.length; i++) {
        employeeArray.push("user_id[]=" + selectedEmployeesArray[i] + "&");
      }
      setUrl(
        global.urlBase +
          "tasks?" +
          employeeArray.toString().replaceAll(",", "").slice(0, -1)
      );
    }
  };

  useEffect(() => {
    handleUserInfoData();
  }, [global.userInfo, global.urlBase, departmentView]);

  useEffect(() => {
    handleEmployeeDropdownItems();
  }, [departmentView, allEmployees]);

  const employeeIds =
    global.userInfo !== undefined
      ? global.userInfo.employees.map((employee) => employee.id)
      : null;

  useEffect(() => {
    if (selectedViewValue === "employee view" && employeeIds !== null) {
      setUrl(global.urlBase + "tasks/user/" + employeeIds);
    } else {
      setUrl(global.urlBase + "tasks");
    }
  }, [employeeDropdownValue, employeeDropdownItems, selectedViewValue]);

  useEffect(() => {
    addEmployeeViewToCategoryDropdown();
  }, [global.userInfo]);

  useEffect(() => {
    getDueDateData();
    getView();
    getSelectedDepartments();
    getToggleData();
    getClaimedToggleData();
    readItemFromStorage();
    getSelectedEmployees();
  }, []);

  useEffect(() => {
    if (mountedRef) {
      writeItemToStorage();
    }
  }, [selectedCategoryValue]);

  useEffect(() => {
    if (mountedRef) {
      setToggleData();
    }
  }, [deferToggle]);

  useEffect(() => {
    if (mountedRef) {
      setDueDateData();
    }
  }, [selectedDates]);

  useEffect(() => {
    if (mountedRef) {
      setClaimedToggleData();
    }
  }, [claimedToggle]);

  useEffect(() => {
    if (mountedRef) {
      setView();
    }
  }, [selectedViewValue]);

  useEffect(() => {
    if (mountedRef) {
      storeSelectedDepartments();
    }
  }, [departmentView]);

  useEffect(() => {
    if (mountedRef) {
      if (departmentView.length === 0) {
        removeSelectedDepartments();
      }
    }
  }, [departmentView]);

  useEffect(() => {
    if (mountedRef) {
      storeSelectedEmployees();
    }
  }, [employeeDropdownValue]);

  useEffect(() => {
    if (mountedRef) {
      if (employeeDropdownValue.length === 0) {
        removeSelectedEmployees();
      }
    }
  }, [employeeDropdownValue]);

  useEffect(() => {
    setTimeout(function () {
      setMountedRef(true);
    }, 1000);
  }, []);

  return (
    <TaskListLayout
      handleRefresh={getTasks}
      deferToggle={deferToggle}
      filteredData={filteredData}
      data={data}
      {...headerProps}
      {...tableProps}
      rowId={rowId}
      rolledUpTasksData={rolledUpTasksData}
      allRollLogs={allRollLogs}
      users={users}
      subTaskItemPressed={subTaskItemPressed}
      setSubTaskItemPressed={setSubTaskItemPressed}
    />
  );
}

export default TaskList;
