import { View, Text, StyleSheet } from "react-native";
import { useEffect } from "react";
import { RFValue } from "react-native-responsive-fontsize";
import { DatePicker } from "antd";
import moment from "moment";
import "./taskList.css";
import dayjs from "dayjs";

function DeferButton(props) {
  if (props.data !== undefined) {
    if (props.data.deferrable === 1) {
      const changeDueAndDeferDates = (date) => {
        props.setDeferDate(date);
        props.setDueDate(date);
      };

      const handleDeferDate = async () => {
        var body = {
          defer_date: props.deferDate,
          due_date: props.dueDate,
        };
        try {
          const response = await fetch(
            global.urlBase + "tasks/" + props.taskId,
            {
              headers: new Headers({
                Authorization: "Bearer " + global.token,
                "Content-Type": "application/json",
              }),
              method: "PUT",
              body: JSON.stringify(body),
            }
          );
        } catch (error) {
          console.error(error);
        }
      };

      useEffect(() => {
        if (props.deferDate !== undefined) {
          handleDeferDate();
        }
      }, [props.deferDate]);

      return (
        <View style={{ alignItems: "center", flex: 1, flexDirection: "row" }}>
          <Text
            style={{
              flex: 1,
              fontSize: RFValue(9, screen.height),
              fontWeight: "bold",
            }}
          >
            Defer Date
          </Text>
          <View style={{ flex: 5 }}>
            <DatePicker
              onFocus={props.onDatePickerFocus}
              onBlur={props.onDatePickerBlur}
              allowClear={true}
              clearIcon={null}
              value={props.deferDate ? dayjs(props.deferDate) : undefined}
              onChange={changeDueAndDeferDates}
              placeholder="Set Defer Date"
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
              format="MM/DD/YYYY"
              style={{ backgroundColor: "rgba(0, 0, 165, 0.8)", width: 200 }}
              className="datepicker"
            />
          </View>
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  body: {
    flex: 5,
    fontSize: RFValue(7, screen.height),
  },
  container: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
  },
  label: {
    flex: 1,
    fontSize: RFValue(9, screen.height),
    fontWeight: "bold",
  },
});

export default DeferButton;
