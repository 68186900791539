    const ajaxCall = async (url) => {
        try {
            const response = await fetch(url, { 'headers': new Headers({ Authorization: 'Bearer ' + global.token }) });
            const json = await response.json();
            return json;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

export default ajaxCall;