import { View, Text, StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";



function Header(props) {
    if (props.data !== undefined) {
        return (
            <View style={styles.titleContainer}>
                <Text style={styles.titleLabel}>{props.title}</Text>
                <Text style={styles.titleText}>{props.body}</Text>
            </View>
        )
    };
};

const styles = StyleSheet.create({
    titleContainer: {
        flex: 1,
        textAlign: 'center',

    },
    titleLabel: {
        flex: 1,
        fontSize: RFValue(12, screen.height),
        fontWeight: 'bold'
    },
    titleText: {
        flex: 1,
        fontSize: RFValue(10, screen.height)
    }
});

export default Header;
