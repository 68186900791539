import React, { useEffect, useState, useRef } from "react";
import { Button, Switch } from "antd";
import ajaxCall from "./shared-components/ajaxCall";
import ajaxPut from "./shared-components/ajaxPut";
import TaskListLayout from "./shared-components/taskListLayout";
import "./shared-components/taskList.css";
import TemplateModal from "./shared-components/templateViewModal";
import AsyncStorage from "@react-native-async-storage/async-storage";

function ScheduledTemplateList() {
  const [mountedRef, setMountedRef] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);

  const [users, setUsers] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [templateModalVisible, setTemplateModalVisible] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [selectedViewValue, setSelectedViewValue] = useState("normal view");
  const [selectedCategoryValue, setSelectedCategoryValue] = useState([]);
  const [firstDropdownOptions, setFirstDropdownOptions] = useState([
    { label: "Category View", value: "category view" },
    { label: "Normal View", value: "normal view" },
  ]);
  const [inactiveToggle, setInactiveToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowId, setRowId] = useState(undefined);
  const [claimedPressed, setClaimedPressed] = useState(false);

  const debounceTimeout = useRef(null);
  const debounceDuration = 500;

  const getScheduledTemplates = async () => {
    const json = await ajaxCall(global.urlBase + "scheduledTemplates/user/" + global.userInfo.id);
    setData(json);
  };
  
  const getTaskTemplate = async () => {
    if (templateData.length === 0) {
      const json = await ajaxCall(global.urlBase + "taskTemplate");
      setTemplateData(json);
    }
  };

  const getStatuses = async () => {
    const json = await ajaxCall(global.urlBase + "statuses");
    setStatuses(json);
  };
 
  const getCategories = async () => {
    const json = await ajaxCall(global.urlBase + "categories");
    setCategories(json);
  };

  const getUsers = async () => {
    const json = await ajaxCall(
      "https://wrapperapi.integrityprodserver.com/api/users"
    );
    setUsers(json);
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      getScheduledTemplates();
      getTaskTemplate();
      getCategories();
      getStatuses();
      getUsers();  
    }, debounceDuration);
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [global.urlBase]);

  const showTemplateModal = () => {
    setTemplateModalVisible(true);
  };

  const categoryDropdownOptions = categories
    .map((item) => ({
      value: item.name ? item.name : null,
      label: item.name ? item.name : null,
    }))
    .filter(
      (item, index, self) =>
        self.findIndex((t) => t.value === item.value) === index
    )
    .filter((item) => item.value !== null);

  const filterData = () => {
    var dataSet = data;

    dataSet = dataSet.map((obj) => {
      if (obj.task_template.category_id !== null) {
        if (categories.length > 0) {
          const category = categories.find(
            (c) => c.id === parseInt(obj.task_template.category_id)
          );
          if (category !== undefined) {
            return {
              ...obj,
              category: { name: category.name },
              name: obj.name,
              priority: obj.task_template.priority,
              description: obj.description,
              link: obj.task_template.link,
            };
          }
        }
      }
      return obj;
    });
    if (inputValue !== "") {
      dataSet = dataSet.filter((obj) => {
        return (
          obj.name.includes(inputValue) ||
          obj.name.toLowerCase().includes(inputValue) ||
          obj.description.includes(inputValue) ||
          obj.description.toLowerCase().includes(inputValue)
        );
      });
    }

    if (selectedCategoryValue.length > 0) {
      dataSet = dataSet.filter((obj) => {
        return (
          obj.category_name !== null &&
          selectedCategoryValue.includes(obj.category.name)
        );
      });
    }

    if (!inactiveToggle) {
      dataSet = dataSet.filter((obj) => {
        return obj.active === 1;
      });
    }

    setFilteredData(dataSet);
  };

  useEffect(() => {
    filterData();
  }, [data, inactiveToggle, selectedCategoryValue, inputValue, categories]);

  const readItemFromStorage = async () => {
    try {
      const chooseViewItem = await AsyncStorage.getItem("@category");
      if (
        chooseViewItem &&
        chooseViewItem !== undefined &&
        chooseViewItem !== ""
      ) {
        let array = chooseViewItem.split(",");
        setSelectedCategoryValue(array);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const writeItemToStorage = async () => {
    try {
      await AsyncStorage.setItem("@category", selectedCategoryValue);
    } catch (error) {
      console.log(error);
    }
  };

  const getToggleData = async () => {
    try {
      const response = await AsyncStorage.getItem("@key");
      if (response === "true") {
        setInactiveToggle(true);
      }
    } catch (e) {
      // read error
    }
  };

  const setToggleData = async () => {
    try {
      var toggleString = "";
      if (inactiveToggle) {
        var toggleString = "true";
      } else {
        var toggleString = "false";
      }
      await AsyncStorage.setItem("@key", toggleString);
    } catch (e) {
      // save error
    }
  };

  const getView = async () => {
    try {
      const categoryResponse = await AsyncStorage.getItem("@categoryKey");
      if (categoryResponse !== (undefined || null)) {
        setSelectedViewValue(categoryResponse);
      }
    } catch (e) {
      // read error
    }
  };

  const setView = async () => {
    try {
      await AsyncStorage.setItem("@categoryKey", selectedViewValue);
    } catch (e) {
      // save error
    }
  };

  useEffect(() => {
    getView();
    getToggleData();
    readItemFromStorage();
  }, []);

  useEffect(() => {
    if (mountedRef) {
      writeItemToStorage();
    }
  }, [selectedCategoryValue]);

  useEffect(() => {
    if (mountedRef) {
      setToggleData();
    }
  }, [inactiveToggle]);

  useEffect(() => {
    if (mountedRef) {
      setView();
    }
  }, [selectedViewValue]);

  useEffect(() => {
    setTimeout(function () {
      setMountedRef(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (mountedRef) {
      setToggleData();

    }
  }, [inactiveToggle]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => a.rawPriority - b.rawPriority,
        multiple: 1,
      },
      className: "sorted",
    },
    {
      title: "Template Name",
      dataIndex: "task",
      key: "task",
    },
  ];

  if (inactiveToggle) {
    columns.push({
      title: "Active/Inactive",
      dataIndex: "active",
      key: "active",
      render: (text, record) => (
        <Switch
          onClick={() => {
            handleButtonClick(record);
          }}
          checked={text === "Active" ? true : false}
        />
      ),
    });
  }

  const handleButtonClick = (record) => {
    const id = record.key;
    const endpoint = `${global.urlBase}scheduledTemplates/${id}`;
    const body = { active: record.active === "Active" ? 0 : 1 };

    ajaxPut(endpoint, body)
      .then((data) => {
        getScheduledTemplates();
      })
      .catch((error) => {});
  };

  const priorityMap = {
    1: "Very High",
    2: "High",
    3: "High",
    4: "Medium",
    5: "Medium",
    6: "Medium",
    7: "Medium",
    8: "Low",
    9: "Low",
    10: "Low",
    11: "Very Low",
  };

  function getPriority(priority) {
    return priorityMap[priority] || "Unknown";
  }

  const activeMap = {
    0: "Inactive",
    1: "Active",
  };

  function getActivity(activity) {
    return activeMap[activity];
  }

  const processedData = filteredData.map((item) => ({
    key: item.id,
    name: item.name && item.name,
    description: item.description && item.description,
    category: item.category && item.category.name,
    rawPriority: item.priority,
    priority: item.priority && getPriority(parseInt(item.priority)),
    task: item.task_template.templateName && item.task_template.templateName,
    active:
      typeof item.active === "number" ? getActivity(item.active) : undefined,
  }));

  const headerProps = {
    // menu props (styling and hiding/displaying of filters)
    showRollUpTable: false,
    menuWidth: 750,
    button: true,
    buttonWidth: 335,
    rangePicker: false,
    showSwitchTwo: false,
    showSwitchThree: false,
    // search bar props
    searchPlaceholder: "Search Scheduled Templates",
    onInputChange: (event) => {
      setInputValue(event.target.value);
    },

    // choose view dropdown props
    firstDropdownOptions: firstDropdownOptions,
    firstDropdownOnChange: (value) => {
      setSelectedViewValue(value);
    },
    selectedViewValue: selectedViewValue,

    // category dropdown props
    secondDropdownPlaceholder: "Choose Category",
    secondDropdownOptions: categoryDropdownOptions,
    secondDropdownOnChange: (value) => {
      setSelectedCategoryValue(value);
    },
    selectedCategoryValue: selectedCategoryValue,
    secondDropdownMode: "multiple",

    // active/inactive scheduled tasks props
    switchOne: inactiveToggle,
    handleSwitchOne: () => setInactiveToggle(!inactiveToggle),
    switchOnePlaceholder: inactiveToggle
      ? "Displaying Inactive Scheduled Templates:"
      : "Hiding Inactive Scheduled Templates:",

    // scheduled template button props
    buttonPlaceholder: "Create Scheduled Template",
    buttonOnClick: showTemplateModal,
  };

  const tableProps = {
    columns: columns,
    processedData: processedData,
    showCompletedTasksTable: false,
    tableLoading: loading ? true : false,
    inactiveToggle: inactiveToggle,
    selectedRow: selectedRow,
    setClaimedPressed: setClaimedPressed,
    handleRowClick: (record) => {
      return {
        onMouseEnter: (event) => {
          document.body.style.cursor = "pointer";
        },
        onMouseLeave: (event) => {
          document.body.style.cursor = "default";
        },
        onClick: (event) => {
          setSelectedRow(record);
          setRowId(record.key);
          if (event && !event.target.closest(".ant-switch")) {
            setTemplateModalVisible(true);
          }
        },
      };
    },
    onOk: () => setModalVisible(false),
    onCancel: () => setModalVisible(false),
    modalVisible: modalVisible,
    setModalVisible: setModalVisible,
  };
  useEffect(() => {
    if (templateModalVisible === false) {
      setRowId(undefined);
    }
  }, [templateModalVisible]);
 
  return (
    <>
      <TaskListLayout
        {...headerProps}
        {...tableProps}
        filteredData={filteredData}
        data={data}
        rowId={rowId}
      />
      {templateModalVisible && (
        <TemplateModal
          visible={templateModalVisible}
          setVisible={setTemplateModalVisible}
          categories={categories}
          statuses={statuses}
          users={users}
          rowId={rowId}
          templateData={filteredData}
          templateType={"scheduledTemplate"}
          taskTemplates={templateData}
        />
      )}
    </>
  );
}

export default ScheduledTemplateList;
