import { View, Text } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { Toast } from "react-native-toast-message/lib/src/Toast";

function ToastConfig(props) {
  const toastConfig = {
    success: (internalState) => (
      <View
        style={{
          backgroundColor: "white",
          borderRadius: 5,
          borderWidth: 1,
          shadowColor: "gray",
          shadowRadius: 10,
          flexDirection: "row",
          maxWidth: 400,
        }}
      >
        <View style={{ backgroundColor: "green" }}>
          <Text> </Text>
        </View>
        <View style={{ flexDirection: "column" }}>
          <View style={{ padding: 10 }}>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              {internalState.text1}
            </Text>
          </View>
          <View style={{ padding: 10 }}>
            <Text>Action: {internalState.text2}</Text>
          </View>
          <View style={{ padding: 10 }}>
            {props.successMessage !== undefined && (
              <Text style={{ fontSize: 11, alignSelf: "flex-start" }}>
                <AntDesign name="checkcircle" /> {props.successMessage}
              </Text>
            )}
            {props.successMessage === undefined && (
              <Text style={{ fontSize: 11, alignSelf: "flex-start" }}>
                <AntDesign name="checkcircle" /> Data has been updated
              </Text>
            )}
          </View>
        </View>
      </View>
    ),
    error: (internalState) => (
      <View
        style={{
          backgroundColor: "white",
          borderRadius: 5,
          borderWidth: 1,
          shadowColor: "gray",
          shadowRadius: 10,
          flexDirection: "row",
          maxWidth: 400,
        }}
      >
        <View style={{ backgroundColor: "red" }}>
          <Text> </Text>
        </View>
        <View style={{ flexDirection: "column" }}>
          <View style={{ padding: 10 }}>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              {internalState.text1}
            </Text>
          </View>
          <View style={{ padding: 10 }}>
            <Text>Action: {internalState.text2}</Text>
          </View>
          {props.errorMessage !== undefined && (
            <View style={{ padding: 10 }}>
              <Text>
                <AntDesign name="closecircle" /> {props.errorMessage}
              </Text>
            </View>
          )}
          {props.errorMessage === undefined && (
            <View style={{ padding: 10 }}>
              <Text>
                <AntDesign name="closecircle" /> Data has not been updated
              </Text>
            </View>
          )}
        </View>
      </View>
    ),
  };

  return (
    <View style={{ zIndex: 100 }}>
      <Toast config={toastConfig} />
    </View>
  );
}

export default ToastConfig;
