import {
  Layout,
  Input,
  Menu,
  DatePicker,
  Switch,
  Card,
  Select,
  Tag,
  Button,
} from "antd";
import { View, StyleSheet, Dimensions } from "react-native";
import moment from "moment";
import { Item } from "rc-menu";
import dayjs from "dayjs";

function GenericListHeader({
  cards,
  // cards = [{ cardContent: "Add Content" }, { cardContent: "Add Content" }],
  firstDropdownDefaultValue,
  firstDropdownPlaceholder = "Select an Item",
  button = false,
  buttonOnClick,
  buttonWidth = 250,
  buttonPlaceholder,
  dropdown = true,
  firstDropdown = true,
  firstDropdownMode,
  firstDropdownOptions,
  firstDropdownOnChange,
  handleSwitchOne,
  handleSwitchTwo,
  handleSwitchThree,
  onDateChange,
  onInputChange,
  rangePicker = true,
  search = true,
  selectable = false,
  secondDropdown = true,
  secondDropdownMode,
  secondDropdownOptions,
  secondDropdownDefaultValue,
  secondDropdownOnChange,
  secondDropdownPlaceholder = "Select an Item",
  searchPlaceholder = "Search",
  inputStyles,
  selectedDates,
  selectedCategoryValue,
  selectedViewValue,
  showArrow = true,
  dropdownStyle = { width: "100%" },
  showSwitchOne = true,
  showSwitchTwo = true,
  showSwitchThree = true,
  switchOnePlaceholder = "Toggle Switch:",
  switchTwoPlaceholder = "Toggle Switch:",
  switchThreePlaceholder = "Toggle Switch:",
  switchOne,
  switchTwo,
  switchThree,
  menuWidth = 850,
}) {
  const { Header } = Layout;
  const { width, height } = Dimensions.get("window");
  const { RangePicker } = DatePicker;

  let dateArray = [];
  if (selectedDates && selectedDates.length > 0) {
    for (let i = 0; i < selectedDates.length; i++) {
      dateArray.push(dayjs(selectedDates[i]));
    }
  }

  const createStyles = (width) => {
    return StyleSheet.create({
      cards: {
        alignItems: "center",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
      },
      cardsStyle: {
        borderTopColor: "rgb(33, 150, 243)",
        borderTopWidth: 10,
      },
      header: {
        height: "auto",
        display: "flex",
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: "#F2F2F2",
        paddingRight: 20,
        paddingLeft: 20,
      },
      menu: {
        height: "auto",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
      menuTopBorder: {
        borderRadius: 10,
        borderTopColor: "rgb(33, 150, 243)",
        borderTopWidth: 10,
        width: width > 1400 ? menuWidth : "100%",
      },
    });
  };
  const styles = createStyles(width);

  return (
    <View>
      <Header style={styles.header}>
        <View style={styles.cards}>
          {cards && cards.length > 0
            ? cards.map((card) => (
                <Card style={styles.cardsStyle}>{card.cardContent}</Card>
              ))
            : null}
        </View>
        <View style={styles.menuTopBorder}>
          <Menu
            style={{
              width: width > 1400 ? menuWidth : "100%",
              height: "auto",
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
            selectable={selectable}
            key="menu"
          >
            {search && (
              <View>
                <Menu.Item key="search">
                  <Input
                    style={inputStyles}
                    placeholder={searchPlaceholder}
                    onChange={onInputChange}
                  />
                </Menu.Item>
              </View>
            )}
            {rangePicker && (
              <Menu.Item key="rangepicker">
                <RangePicker
                  onChange={onDateChange}
                  style={{ width: "100%" }}
                  format="MM/DD/YYYY"
                  value={dateArray}
                />
              </Menu.Item>
            )}
            {dropdown && (
              <View style={{ flexDirection: "row" }}>
                {firstDropdown && (
                  <Menu.Item
                    style={{ height: "auto", width: "100%" }}
                    key="firstDropdown"
                  >
                    <Select
                      mode={firstDropdownMode}
                      options={firstDropdownOptions}
                      placeholder={firstDropdownPlaceholder}
                      showArrow={showArrow}
                      style={dropdownStyle}
                      onChange={firstDropdownOnChange}
                      value={selectedViewValue}
                    />
                  </Menu.Item>
                )}
                {secondDropdown && (
                  <Menu.Item
                    style={{ height: "auto", width: "100%" }}
                    key="secondDropdown"
                  >
                    <Select
                      defaultValue={secondDropdownDefaultValue}
                      mode={secondDropdownMode}
                      options={secondDropdownOptions}
                      placeholder={secondDropdownPlaceholder}
                      showArrow={showArrow}
                      style={dropdownStyle}
                      onChange={secondDropdownOnChange}
                      value={selectedCategoryValue}
                    />
                  </Menu.Item>
                )}
              </View>
            )}
            <View style={{ flexDirection: "row" }}>
              {showSwitchOne && (
                <Menu.Item key="switch1" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {switchOnePlaceholder}
                    <Switch
                      style={{ marginLeft: 6 }}
                      checked={switchOne}
                      onChange={handleSwitchOne}
                    />
                  </div>
                </Menu.Item>
              )}
              {showSwitchTwo && (
                <Menu.Item key="switch2" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {switchTwoPlaceholder}
                    <Switch
                      style={{ marginLeft: 6 }}
                      checked={switchTwo}
                      onChange={handleSwitchTwo}
                    />
                  </div>
                </Menu.Item>
              )}
              {showSwitchThree && (
                <Menu.Item key="switch3" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {switchThreePlaceholder}
                    <Switch
                      style={{ marginLeft: 6 }}
                      checked={switchThree}
                      onChange={handleSwitchThree}
                    />
                  </div>
                </Menu.Item>
              )}
              {!showSwitchTwo && button && (
                <View>
                  <Menu.Item key="button">
                    <Button
                      style={{ width: buttonWidth }}
                      onClick={buttonOnClick}
                    >
                      {buttonPlaceholder}
                    </Button>
                  </Menu.Item>
                </View>
              )}
            </View>
            {showSwitchTwo && button && (
              <View>
                <Menu.Item key="button">
                  <Button onClick={buttonOnClick}>{buttonPlaceholder}</Button>
                </Menu.Item>
              </View>
            )}
          </Menu>
        </View>
      </Header>
    </View>
  );
}

export default GenericListHeader;
