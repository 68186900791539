import { Checkbox } from "react-native-paper";
import {
  View,
  Text,
  ScrollView,
  FlatList,
  StyleSheet,
  Linking,
} from "react-native";
import ProgressBarComponent from "./progressBar";
import { RFValue } from "react-native-responsive-fontsize";

function SubTasks(props) {
  const handleSubTask = async (subTaskId, complete) => {
    var body = {
      complete: complete,
    };
    try {
      const response = await fetch(
        global.urlBase + "tasks/" + props.taskId + "/subtask/" + subTaskId,
        {
          headers: new Headers({
            Authorization: "Bearer " + global.token,
            "Content-Type": "application/json",
          }),
          method: "PUT",
          body: JSON.stringify(body),
        }
      );
    } catch (error)  {
        console.log(error);
    }
  };

  if (props.subTasks.length > 0) {
    return (
      <View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ flex: 2 }}>
            <Text style={styles.label}>Sub Tasks</Text>
          </View>
          <ProgressBarComponent
            count={props.count}
            progress={props.progress}
            subTasks={props.subTasks}
          />
        </View>
        <ScrollView>
          <View style={{ maxHeight: 200 }}>
            <FlatList
              data={props.subTasks}
              keyExtractor={(item) => item.id}
              renderItem={({ item, index }) => (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Checkbox.Item
                    color="rgba(0, 0, 165, 0.8)"
                    status={item.complete ? "checked" : "unchecked"}
                    onPress={async () => {
                      handleSubTask(item.id, !item.complete);
                      const tempArr = [...props.subTasks];
                      tempArr.splice(index, 1, {
                        ...item,
                        complete: !item.complete,
                      });
                      props.setSubTasks(tempArr);
                      const checkedTasks = tempArr.map((obj) => {
                        return obj.complete;
                      });
                      const tasks = checkedTasks.filter((obj) => {
                        return obj === true || obj === 1;
                      });
                      props.setProgress(tasks.length / props.subTasks.length);
                      props.setCount(tasks.length);
                      props.setSubTaskItemPressed(true);
                    }}
                  />
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {!item.complete && item.link && (
                      <Text
                        onPress={() => Linking.openURL(item.link)}
                        style={{
                          color: "blue",
                          textDecorationLine: "underline",
                        }}
                      >
                        {item.name}
                      </Text>
                    )}
                    {!item.complete && !item.link && <Text>{item.name}</Text>}
                    {item.complete && (
                      <Text style={{ color: "#B3B3B3" }}>{item.name}</Text>
                    )}
                    {!item.complete && <Text>- {item.descr}</Text>}
                    {item.complete && (
                      <Text style={{ color: "#B3B3B3" }}>- {item.descr}</Text>
                    )}
                  </View>
                </View>
              )}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  label: {
    flex: 1,
    fontSize: RFValue(12, screen.height),
    fontWeight: "bold",
  },
});


export default SubTasks;