const ajaxPut = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.token,
      }),
      body: JSON.stringify(body),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default ajaxPut;
