import ajaxCall from "./shared-components/ajaxCall";
import { View } from "react-native";
import { useEffect, useState } from "react";
import { Layout, Table } from "antd";

function WorkflowList() {
  const { Content } = Layout;

  const [workflowData, setWorkflowData] = useState([]);

  const getWorkflows = async () => {
    if (workflowData.length === 0) {
      const json = await ajaxCall(global.urlBase + "workflow");
      setWorkflowData(json);
    }
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const processedData = workflowData.map((item) => ({
    key: item.id,
    id: item.id && item.id,
    name: item.name && item.name,
    description: item.description && item.description,
  }));

  return (
    <Layout>
      <View
        style={{
          backgroundColor: "white",
          borderTopColor: "rgb(33, 150, 243)",
          borderTopWidth: 10,
          borderRadius: 10,
          margin: 20,
        }}
      >
        <Content>
          <Table
            dataSource={processedData}
            columns={columns}
            loading={workflowData.length > 0 ? false : true}
          />
        </Content>
      </View>
    </Layout>
  );
}

export default WorkflowList;
