import { ProgressBar } from 'react-native-paper';
import { View, Text } from 'react-native';

function ProgressBarComponent(props) {
    const handleProgress = () => {
        if (props.progress === 1) return '#24d326';
        return 'rgba(0, 0, 165, 0.8)';
    };
    return (
        <>
        <View style={{flex: 4}}>
            <ProgressBar progress={props.progress} width={200} color={handleProgress()} />
        </View>
        <View style={{ padding: 6 }}><Text style={{ fontWeight: 'bold' }}>{Math.round(props.progress * 100)}%</Text></View>
        <View><Text>({props.count} of {props.subTasks.length})</Text></View>
        </>
    )
};

export default ProgressBarComponent;