import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Text,
  Dimensions,
  ScrollView,
  TextInput,
  Pressable,
} from "react-native";
import Moment from "react-moment";
import { RFValue } from "react-native-responsive-fontsize";
import AsyncStorage, {
  useAsyncStorage,
} from "@react-native-async-storage/async-storage";
import ajaxCall from "./ajaxCall";
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function TaskMessages({ taskId, onMessageInputFocus, onMessageInputBlur }) {
  const [data, setData] = useState([]);
  const [tasksPressed, setTasksPressed] = useState(true);
  const [refIdPressed, setRefIdPressed] = useState(true);
  const [workflowPressed, setWorkflowPressed] = useState(true);
  const [dimensions, setDimensions] = useState({ window, screen });
  const [value, setValue] = useState("");
  const [mountedRef, setMountedRef] = useState(false);

  const getTaskMessages = async () => {
    const json = await ajaxCall(
      global.urlBase + "tasks/" + taskId + "/messages"
    );
    setData(json.sort((a, b) => b.updated_at - a.updated_at));
  };

  useEffect(() => {
    getTaskMessages();
  }, [taskId]);

  // this function handles the 'save' button located under the textInput component.
  const handleSave = async () => {
    setValue(value);
    var body = {
      task_id: taskId,
      user_id: userInfo.id.toString(),
      message: value,
    };
    try {
      const response = await fetch(global.urlBase + "messages", {
        headers: new Headers({
          Authorization: "Bearer " + global.token,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(body),
      });
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await fetch(
        global.urlBase + "tasks/" + taskId + "/messages",
        { headers: new Headers({ Authorization: "Bearer " + global.token }) }
      );
      const json = await response.json();
      //setData(json);
      setData(
        json.filter((obj) => {
          return obj.history != 1;
        })
      );
    } catch (error) {
      console.error(error);
    }
    setValue("");
  };

  // functions that change the color of the save/cancel button backgrounds when hovered over with the mouse
  function saveBackgroundHoverIn(e) {
    e.target.style.background = "rgba(12, 12, 255, 0.8)";
  }
  function saveBackgroundHoverOut(e) {
    e.target.style.background = "rgba(0, 0, 165, 0.8)";
  }
  function cancelBackgroundHoverIn(e) {
    e.target.style.background = "rgba(0, 0, 0, 0.14)";
  }
  function cancelBackgroundHoverOut(e) {
    e.target.style.background = "white";
  }

  const TabNavigator = () => {
    const handleTasksPress = () => {
      setTasksPressed(!tasksPressed);
    };
    const handleRefIdPress = () => {
      setRefIdPressed(!refIdPressed);
    };
    const handleWorkflowPress = () => {
      setWorkflowPressed(!workflowPressed);
    };
    function hoverIn(e) {
      e.target.style.background = "rgba(97, 94, 94, 0.44)";
    }
    function hoverOut(e) {
      e.target.style.background = "rgba(97, 94, 94, 0.57)";
    }
    function selectedTabHoverIn(e) {
      e.target.style.background = "rgba(50, 54, 213, 0.8)";
    }
    function selectedTabHoverOut(e) {
      e.target.style.background = "rgba(0, 0, 165, 0.8)";
    }
    return (
      <View style={styles.tabContainer}>
        {!tasksPressed && (
          <Pressable
            onPress={handleTasksPress}
            onHoverIn={hoverIn}
            onHoverOut={hoverOut}
            style={styles.unselectedTab}
          >
            <View>
              <Text style={styles.saveButtonText}>Tasks</Text>
            </View>
          </Pressable>
        )}
        {tasksPressed && (
          <Pressable
            onHoverOut={selectedTabHoverOut}
            onHoverIn={selectedTabHoverIn}
            onPress={handleTasksPress}
            style={styles.selectedTab}
          >
            <View>
              <Text style={styles.saveButtonText}>Tasks</Text>
            </View>
          </Pressable>
        )}
        {!workflowPressed && (
          <Pressable
            onPress={handleWorkflowPress}
            onHoverIn={hoverIn}
            onHoverOut={hoverOut}
            style={styles.unselectedTab}
          >
            <View>
              <Text style={styles.saveButtonText}>Workflow</Text>
            </View>
          </Pressable>
        )}
        {workflowPressed && (
          <Pressable
            onHoverOut={selectedTabHoverOut}
            onHoverIn={selectedTabHoverIn}
            onPress={handleWorkflowPress}
            style={styles.selectedTab}
          >
            <View>
              <Text style={styles.saveButtonText}>Workflow</Text>
            </View>
          </Pressable>
        )}
        {!refIdPressed && (
          <Pressable
            onPress={handleRefIdPress}
            onHoverIn={hoverIn}
            onHoverOut={hoverOut}
            style={styles.unselectedTabWithNoBorder}
          >
            <View>
              <Text style={styles.saveButtonText}>Ref Id</Text>
            </View>
          </Pressable>
        )}
        {refIdPressed && (
          <Pressable
            onHoverOut={selectedTabHoverOut}
            onHoverIn={selectedTabHoverIn}
            onPress={handleRefIdPress}
            style={styles.selectedTabWithNoBorder}
          >
            <View>
              <Text style={styles.saveButtonText}>Ref Id</Text>
            </View>
          </Pressable>
        )}
      </View>
    );
  };

  const filterData = () => {
    var dataSet = data;
    var arrayOfData = [];
    if (workflowPressed) {
      const workflowData = dataSet.filter((obj) => {
        return obj.Workflow_Relationship !== undefined && obj.history != 1;
      });
      for (let i = 0; i < workflowData.length; i++) {
        arrayOfData.push(workflowData[i]);
      }
      dataSet = arrayOfData;
    }
    if (refIdPressed) {
      const refIdData = dataSet.filter((obj) => {
        return obj.Ref_ID_Relationship !== undefined && obj.history != 1;
      });
      for (let i = 0; i < refIdData.length; i++) {
        arrayOfData.push(refIdData[i]);
      }
      dataSet = arrayOfData;
    }
    if (tasksPressed) {
      const tasksData = dataSet.filter((obj) => {
        return obj.history != 1;
      });
      for (let i = 0; i < tasksData.length; i++) {
        arrayOfData.push(tasksData[i]);
      }
      dataSet = arrayOfData;
    }
    if (!workflowPressed && !refIdPressed && !tasksPressed) {
      dataSet = [];
    }
    if (dataSet.length > 0) {
      const uniqueIds = [];
      const unique = dataSet.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id);
        if (!isDuplicate) {
          uniqueIds.push(element.id);
          return true;
        }
        return false;
      });
      dataSet = unique;
    }
    return dataSet;
  };

  const readTasksTab = async () => {
    try {
      const taskTab = await AsyncStorage.getItem("@tasksPressed");
      const value = taskTab !== null ? JSON.parse(taskTab) : null;
      if (value !== null) {
        setTasksPressed(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const writeTasksTabToStorage = async () => {
    try {
      const jsonValue = JSON.stringify(tasksPressed);
      await AsyncStorage.setItem("@tasksPressed", jsonValue);
    } catch (error) {
      console.log(error);
    }
  };

  const readWorkflowTab = async () => {
    try {
      const workflowTab = await AsyncStorage.getItem("@workflowPressed");
      const value = workflowTab !== null ? JSON.parse(workflowTab) : null;
      if (value !== null) {
        setWorkflowPressed(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const writeWorkflowTabToStorage = async () => {
    try {
      const jsonValue = JSON.stringify(workflowPressed);
      await AsyncStorage.setItem("@workflowPressed", jsonValue);
    } catch (error) {
      console.log(error);
    }
  };

  const readRefIdTab = async () => {
    try {
      const refIdTab = await AsyncStorage.getItem("@refIdPressed");
      const value = refIdTab !== null ? JSON.parse(refIdTab) : null;
      if (value !== null) {
        setRefIdPressed(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const writeRefIdTabToStorage = async () => {
    try {
      const jsonValue = JSON.stringify(refIdPressed);
      await AsyncStorage.setItem("@refIdPressed", jsonValue);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readTasksTab();
    readWorkflowTab();
    readRefIdTab();
  }, []);

  useEffect(() => {
    if (mountedRef) {
      writeTasksTabToStorage();
      writeWorkflowTabToStorage();
      writeRefIdTabToStorage();
    }
  }, [tasksPressed, workflowPressed, refIdPressed]);

  useEffect(() => {
    setTimeout(function () {
      setMountedRef(true);
    }, 1000);
  }, []);

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.historyText}>Messages/History</Text>
      </View>
      <TabNavigator />
      <ScrollView style={{ marginTop: 8, marginBottom: 8 }}>
        <FlatList
          data={filterData()}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <View style={styles.messageAndHistory}>
              <View style={styles.historyContainer}>
                {item.user !== null && (
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>{item.user.name}</Text>{" "}
                    on{" "}
                    <Moment format="MMM Do, YYYY [at] h:mm A">
                      {item.created_at}
                    </Moment>
                  </Text>
                )}
                {item.user === null && (
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Unknown user</Text>
                    <Text> on </Text>
                    <Moment format="MMM Do, YYYY [at] h:mm A">
                      {item.created_at}
                    </Moment>
                  </Text>
                )}
              </View>
              <View style={styles.message}>
                <Text>{item.message}</Text>
              </View>
            </View>
          )}
        />
      </ScrollView>
      <View>
        <TextInput
          onFocus={onMessageInputFocus}
          onBlur={onMessageInputBlur}
          style={styles.input}
          placeholder="Add a comment..."
          value={value}
          onChangeText={setValue}
        />
      </View>
      <View style={styles.buttonsContainer}>
        <View>
          <Pressable
            style={styles.saveButton}
            onPress={handleSave}
            onHoverIn={saveBackgroundHoverIn}
            onHoverOut={saveBackgroundHoverOut}
          >
            <Text style={styles.saveButtonText}>Save</Text>
          </Pressable>
        </View>
        <View>
          <Pressable
            style={styles.cancelButton}
            onPress={() => setValue("")}
            onHoverIn={cancelBackgroundHoverIn}
            onHoverOut={cancelBackgroundHoverOut}
          >
            <Text style={styles.cancelButtonText}>Cancel</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    maxHeight: 400,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  historyText: {
    fontWeight: "bold",
    fontSize: RFValue(8, screen.height),
  },
  messageAndHistory: {
    marginBottom: 10,
  },
  message: {
    borderBottomWidth: 2,
    borderBottomColor: "rgba(0, 0, 0, 0.14)",
  },
  historyContainer: {
    marginRight: 10,
  },
  textInputContainer: {
    marginBottom: 8,
  },
  input: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.26)",
    height: 40,
    padding: 10,
  },
  buttonsContainer: {
    flexDirection: "row",
    marginTop: 6,
  },
  saveButton: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
    paddingHorizontal: 26,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "rgba(0, 0, 165, 0.8)",
    width: 50,
  },
  saveButtonText: {
    fontSize: 10,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "white",
  },
  cancelButton: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
    paddingHorizontal: 26,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "white",
    marginLeft: 5,
    width: 60,
  },
  cancelButtonText: {
    fontSize: 10,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "rgba(0, 0, 0, 0.66)",
  },
  selectedTab: {
    flex: 1,
    alignItems: "center",
    borderRightWidth: 2,
    borderRightColor: "white",
    backgroundColor: "rgba(0, 0, 165, 0.8)",
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
  },
  selectedTabWithNoBorder: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 165, 0.8)",
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
  },
  unselectedTab: {
    flex: 1,
    alignItems: "center",
    borderRightWidth: 2,
    borderRightColor: "white",
    backgroundColor: "rgba(97, 94, 94, 0.57)",
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
  },
  unselectedTabWithNoBorder: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "rgba(97, 94, 94, 0.57)",
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
  },
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 6,
    marginBottom: 6,
    borderBottomWidth: 2,
    borderBottomColor: "black",
  },
});

export default TaskMessages;
