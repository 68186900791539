import React, { useEffect, useState } from "react";
import ajaxCall from "./shared-components/ajaxCall";
import ajaxPut from "./shared-components/ajaxPut";
import TaskListLayout from "./shared-components/taskListLayout";
import "./shared-components/taskList.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Switch } from "antd";

function ScheduledTaskList() {
  const [mountedRef, setMountedRef] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [selectedViewValue, setSelectedViewValue] = useState("normal view");
  const [selectedCategoryValue, setSelectedCategoryValue] = useState([]);
  const [firstDropdownOptions, setFirstDropdownOptions] = useState([
    { label: "Category View", value: "category view" },
    { label: "Normal View", value: "normal view" },
  ]);
  const [inactiveToggle, setInactiveToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowId, setRowId] = useState(undefined);
  const [claimedPressed, setClaimedPressed] = useState(false);

  const getScheduledTasks = async () => {
    const json = await ajaxCall(global.urlBase + "scheduledTasks");
    json.length > 0 ? setLoading(false) : setLoading(true);
    setData(json);
  };

  useEffect(() => {
    data.length === 0 ? getScheduledTasks() : undefined;
  }, [data, modalVisible]);

  const categoryDropdownOptions = data
    .map((item) => ({
      value: item.category ? item.category.name : null,
      label: item.category ? item.category.name : null,
    }))
    .filter(
      (item, index, self) =>
        self.findIndex((t) => t.value === item.value) === index
    )
    .filter((item) => item.value !== null);

  const filterData = () => {
    var dataSet = data;

    if (inputValue !== "") {
      dataSet = dataSet.filter((obj) => {
        return (
          obj.name.toLowerCase().includes(inputValue) ||
          obj.name.includes(inputValue)
        );
      });
    }

    if (selectedCategoryValue.length > 0) {
      dataSet = dataSet.filter((obj) => {
        return (
          obj.category !== null &&
          selectedCategoryValue.includes(obj.category.name)
        );
      });
    }

    if (!inactiveToggle) {
      dataSet = dataSet.filter((obj) => {
        return obj.active === 1;
      });
    }

    setFilteredData(dataSet);
  };

  useEffect(() => {
    filterData();
  }, [data, inactiveToggle, selectedCategoryValue, inputValue]);

  const readItemFromStorage = async () => {
    try {
      const chooseViewItem = await AsyncStorage.getItem("@category");
      if (
        chooseViewItem &&
        chooseViewItem !== undefined &&
        chooseViewItem !== ""
      ) {
        let array = chooseViewItem.split(",");
        setSelectedCategoryValue(array);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const writeItemToStorage = async () => {
    try {
      await AsyncStorage.setItem("@category", selectedCategoryValue);
    } catch (error) {
      console.log(error);
    }
  };

  const getToggleData = async () => {
    try {
      const response = await AsyncStorage.getItem("@key");
      if (response === "true") {
        setInactiveToggle(true);
      }
    } catch (e) {
      // read error
    }
  };

  const setToggleData = async () => {
    try {
      var toggleString = "";
      if (inactiveToggle) {
        var toggleString = "true";
      } else {
        var toggleString = "false";
      }
      await AsyncStorage.setItem("@key", toggleString);
    } catch (e) {
      // save error
    }
  };

  const getView = async () => {
    try {
      const categoryResponse = await AsyncStorage.getItem("@categoryKey");
      if (categoryResponse !== (undefined || null)) {
        setSelectedViewValue(categoryResponse);
      }
    } catch (e) {
      // read error
    }
  };

  const setView = async () => {
    try {
      await AsyncStorage.setItem("@categoryKey", selectedViewValue);
    } catch (e) {
      // save error
    }
  };

  useEffect(() => {
    getView();
    getToggleData();
    readItemFromStorage();
  }, []);

  useEffect(() => {
    if (mountedRef) {
      writeItemToStorage();
    }
  }, [selectedCategoryValue]);

  useEffect(() => {
    if (mountedRef) {
      setToggleData();
    }
  }, [inactiveToggle]);

  useEffect(() => {
    if (mountedRef) {
      setView();
    }
  }, [selectedViewValue]);

  useEffect(() => {
    setTimeout(function () {
      setMountedRef(true);
    }, 1000);
  }, []);

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => a.rawPriority - b.rawPriority,
        multiple: 1,
      },
      className: "sorted",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
  ];

  if (inactiveToggle) {
    columns.push({
      title: "Active/Inactive",
      dataIndex: "active",
      key: "active",
      render: (text, record) => (
        <Switch
          onClick={() => {
            handleButtonClick(record);
          }}
          checked={text === 'Active' ? true : false}
        />
      ),
    });
  }

  const handleButtonClick = (record) => {
    const id = record.key;
    const endpoint = `${global.urlBase}scheduledTasks/${id}`;
    const body = { active: record.active === "Active" ? 0 : 1 };

    ajaxPut(endpoint, body)
      .then((data) => {
        getScheduledTasks();
      })
      .catch((error) => {});
  };

  const priorityMap = {
    1: "Very High",
    2: "High",
    3: "High",
    4: "Medium",
    5: "Medium",
    6: "Medium",
    7: "Medium",
    8: "Low",
    9: "Low",
    10: "Low",
    11: "Very Low",
  };

  function getPriority(priority) {
    return priorityMap[priority] || "Unknown";
  }

  const activeMap = {
    0: "Inactive",
    1: "Active",
  };

  function getActivity(activity) {
    return activeMap[activity];
  }

  const processedData = filteredData.map((item) => ({
    key: item.id,
    category: item.category && item.category.name,
    rawPriority: item.priority,
    priority: item.priority && getPriority(item.priority),
    task: item.name && item.name,
    active:
      typeof item.active === "number" ? getActivity(item.active) : undefined,
  }));

  const headerProps = {
    // menu props (styling and hiding/displaying of filters)
    menuWidth: 500,
    button: false,
    rangePicker: false,
    showSwitchTwo: false,

    // search bar props
    searchPlaceholder: "Search Scheduled Tasks",
    onInputChange: (event) => {
      setInputValue(event.target.value);
    },

    // choose view dropdown props
    firstDropdownOptions: firstDropdownOptions,
    firstDropdownOnChange: (value) => {
      setSelectedViewValue(value);
    },
    selectedViewValue: selectedViewValue,

    // category dropdown props
    secondDropdownPlaceholder: "Choose Category",
    secondDropdownOptions: categoryDropdownOptions,
    secondDropdownOnChange: (value) => {
      setSelectedCategoryValue(value);
    },
    selectedCategoryValue: selectedCategoryValue,
    secondDropdownMode: "multiple",

    // active/inactive scheduled tasks props
    switchOne: inactiveToggle,
    handleSwitchOne: () => setInactiveToggle(!inactiveToggle),
    switchOnePlaceholder: inactiveToggle
      ? "Hide Inactive Scheduled Tasks:"
      : "Show Inactive Scheduled Tasks:",
  };

  const tableProps = {
    columns: columns,
    processedData: processedData,
    showCompletedTasksTable: false,
    tableLoading: loading ? true : false,
    inactiveToggle: inactiveToggle,
    selectedRow: selectedRow,
    setClaimedPressed: setClaimedPressed,
    handleRowClick: (record, index, event) => {
      return {
        onMouseEnter: (event) => {
          document.body.style.cursor = "pointer";
        },
        onMouseLeave: (event) => {
          document.body.style.cursor = "default";
        },
        onClick: () => {
          setSelectedRow(record);
          setRowId(record.key);
          if (event && !event.target.closest(".ant-btn")) {
            setModalVisible(true);
          }
        },
      };
    },
    onOk: () => setModalVisible(false),
    onCancel: () => setModalVisible(false),
    modalVisible: modalVisible,
    setModalVisible: setModalVisible,
  };

  return (
    <>
      <TaskListLayout
        {...headerProps}
        {...tableProps}
        filteredData={filteredData}
        data={data}
        rowId={rowId}
      />
    </>
  );
}

export default ScheduledTaskList;
