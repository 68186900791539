import ajaxCall from "./shared-components/ajaxCall";
import { View, StyleSheet } from "react-native";
import { useEffect, useState } from "react";
import { Layout, Table, Button } from "antd";
import CategoryModal from "./shared-components/categoryViewModal";

function CategoriesList() {
  const { Content, Header } = Layout;
  const [categoriesData, setCategoriesData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rowId, setRowId] = useState(undefined);

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (!visible) {
      setRowId(undefined);
    }
  }, [visible]);

  const getCategories = async () => {
    if (categoriesData.length === 0) {
      const json = await ajaxCall(global.urlBase + "categories");
      setCategoriesData(json);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  const processedData = categoriesData.map((item) => ({
    key: item.id,
    id: item.id && item.id,
    name: item.name && item.name,
  }));

  return (
    <Layout>
      <Header style={styles.header}>
        <Button onClick={showModal}>Create Category</Button>
      </Header>

      <View
        style={{
          backgroundColor: "white",
          borderTopColor: "rgb(33, 150, 243)",
          borderTopWidth: 10,
          borderRadius: 10,
          margin: 20,
        }}
      >
        <Content>
          <Table
            loading={categoriesData.length > 0 ? false : true}
            dataSource={processedData}
            columns={columns}
            onRow={(record) => {
              return {
                onClick: () => {
                  setRowId(record.key);
                  showModal();
                },
                style: {
                  cursor: "pointer",
                },
              };
            }}
          />
          <CategoryModal
            visible={visible}
            setVisible={setVisible}
            rowId={rowId}
            categoriesData={categoriesData}
          />
        </Content>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  header: {
    height: "auto",
    display: "flex",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#F2F2F2",
  },
});

export default CategoriesList;
