import ScheduledTemplateList from "../components/ScheduledTemplateList.js";

const ScheduledTemplateListPage = () => {
  return (
    <>
      <ScheduledTemplateList />
    </>
  );
};

export default ScheduledTemplateListPage;
