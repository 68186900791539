import { Select, Modal, Table, Layout } from "antd";
import { View, Text, Dimensions } from "react-native";
import { useState } from "react";
import ProfileIconStack from "./profileIconStack";
import moment from "moment";
import TaskModal from "./taskModal";
import "./taskList.css";
import { RFValue } from "react-native-responsive-fontsize";
import DraggableModal from "./draggableModal";

function EmployeeView({
  filteredData,
  departmentItems,
  departmentView,
  departmentViewOnChange,
  employeeDropdownItems,
  employeeDropdownValue,
  employeeDropdownOnChange,
  deferToggle,
  rowClassName,
  handleRowClick,
  onOk,
  onCancel,
  modalVisible,
  selectedRow,
  rowId,
  handleRefresh,
  claimedPressed,
  setClaimedPressed,
  employeeList,
  locale,
  users,
  newDataForClaimedTasks,
  subTaskItemPressed,
  setSubTaskItemPressed,
}) {
  if (global.userInfo !== undefined && employeeDropdownItems.length > 0) {
    const { Content } = Layout;
    const [loading, setLoading] = useState(true);
    const { width, height } = Dimensions.get("window");

    const [datePickerFocused, setDatePickerFocused] = useState(false);
    const [messageInputFocused, setMessageInputFocused] = useState(false);
    const [actionDropdownFocused, setActionDropdownFocused] = useState(false);

    const handleDatePickerFocus = () => {
      setDatePickerFocused(true);
    };

    const handleDatePickerBlur = () => {
      setDatePickerFocused(false);
    };

    const handleMessageInputFocus = () => {
      setMessageInputFocused(true);
    };

    const handleMessageInputBlur = () => {
      setMessageInputFocused(false);
    };

    const handleActionDropdownFocus = () => {
      setActionDropdownFocused(true);
    };

    const handleActionDropdownBlur = () => {
      setActionDropdownFocused(false);
    };

    setTimeout(() => {
      setLoading(false);
    }, 750);

    const employeeIds = employeeList.map((employee) => employee.value);

    const uniqueArr = employeeIds.map((id) => {
      const data = filteredData.filter((obj) => {
        if (Array.isArray(obj.user_id)) {
          return obj.user_id.includes(id);
        } else {
          return obj.user_id === id;
        }
      });
      return { user_id: id, data };
    });

    for (let i = 0; i < employeeDropdownItems.length; i++) {
      for (let j = 0; j < uniqueArr.length; j++) {
        if (employeeDropdownItems[i].value === uniqueArr[j].user_id) {
          uniqueArr[j].user_id = employeeDropdownItems[i].label;
        }
      }
    }

    const handleDepartmentAndEmployeeDropdown = () => {
      let dataSet = uniqueArr;

      if (departmentView.length > 0) {
        const employeesInSelectedDepartments = employeeList.filter((obj) =>
          departmentView.includes(obj.departmentId)
        );
        const employeesDataInSelectedDepartments = uniqueArr.filter((obj) =>
          employeesInSelectedDepartments.find(
            (emp) => emp.label === obj.user_id
          )
        );
        dataSet = employeesDataInSelectedDepartments;
      }

      if (employeeDropdownValue.length > 0) {
        const selectedEmployees = employeeList.filter((obj) =>
          employeeDropdownValue.includes(obj.value)
        );
        const selectedEmployeesData = uniqueArr.filter((obj) =>
          selectedEmployees.find((emp) => emp.label === obj.user_id)
        );
        dataSet = selectedEmployeesData;
      }

      return dataSet;
    };

    const columns = [
      {
        key: "user_id",
        dataIndex: "user_id",
        render: (text, record) => (
          <>
            {text}
            <span> ({record.data.length})</span>
          </>
        ),
      },
    ];

    const expandedRowRender = (record) => {
      const columns = [
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "Priority",
          dataIndex: "priority",
          key: "priority",
          defaultSortOrder: "ascend",
          sorter: {
            compare: (a, b) => a.rawPriority - b.rawPriority,
            multiple: 1,
          },
          className: "sorted",
        },
        {
          title: "Task",
          dataIndex: "task",
          key: "task",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Due Date",
          dataIndex: "dueDate",
          key: "dueDate",
          defaultSortOrder: "ascend",
          sorter: {
            compare: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
            multiple: 2,
          },
          className: "sorted",
        },
        {
          title: "Claimed Users",
          dataIndex: "claimedUsers",
          key: "claimedUsers",
          render: (claimedUsers) => {
            if (claimedUsers) {
              return (
                <>
                  {claimedUsers.Primary && claimedUsers.Primary.length > 0 && (
                    <ProfileIconStack
                      key={claimedUsers.Primary}
                      listOfUsers={claimedUsers.Primary}
                      marginRight={10}
                      userObject={users}
                    />
                  )}
                  {claimedUsers.Secondary &&
                    claimedUsers.Secondary.length > 0 && (
                      <ProfileIconStack
                        key={claimedUsers.Secondary}
                        listOfUsers={claimedUsers.Secondary}
                        inactiveAvatarColor={"rgba(0, 0, 0, 1)"}
                        userObject={users}
                      />
                    )}
                </>
              );
            }
          },
        },
      ];

      if (deferToggle) {
        columns.push({
          title: "Defer Date",
          dataIndex: "deferDate",
          key: "deferDate",
        });
      }

      const priorityMap = {
        1: "Very High",
        2: "High",
        3: "High",
        4: "Medium",
        5: "Medium",
        6: "Medium",
        7: "Medium",
        8: "Low",
        9: "Low",
        10: "Low",
        11: "Very Low",
      };

      function getPriority(priority) {
        return priorityMap[priority] || "Unknown";
      }

      const processedData = record.data.map((item) => ({
        key: item.id,
        category: item.category && item.category.name,
        rawPriority: item.priority,
        priority: item.priority && getPriority(item.priority),
        task: item.name && item.name,
        status: item.status && item.status.status,
        dueDate: item.due_date && moment(item.due_date).format("MM-DD-YYYY"),
        deferDate:
          item.defer_date && moment(item.defer_date).format("MM-DD-YYYY"),
        claimedUsers: item.claimed_users,
      }));

      return (
        <>
          <Table
            columns={columns}
            dataSource={processedData}
            rowClassName={rowClassName}
            loading={loading}
            onRow={handleRowClick}
            locale={locale}
          />
        </>
      );
    };

    return (
      <View>
        <View style={{ margin: 20 }}>
          <View style={{ paddingTop: 8 }}>
            <Text style={{ fontWeight: "bold", marginBottom: 8 }}>
              Departments:
            </Text>
            <Select
              placeholder="Select a Department"
              options={departmentItems}
              mode="multiple"
              onChange={departmentViewOnChange}
              value={departmentView}
            />
          </View>
          <View style={{ paddingTop: 8, paddingBottom: 8, zIndex: -4 }}>
            <Text style={{ fontWeight: "bold", marginBottom: 8 }}>
              Employees:
            </Text>
            <Select
              placeholder="Select an Employee"
              options={employeeDropdownItems}
              mode="multiple"
              onChange={employeeDropdownOnChange}
              value={employeeDropdownValue}
            />
          </View>
        </View>
        <View
          style={{
            backgroundColor: "white",
            borderTopColor: "rgb(33, 150, 243)",
            borderTopWidth: 10,
            borderRadius: 10,
            margin: 20,
          }}
        >
          <Content>
            <Table
              rowKey="user_id"
              columns={columns}
              dataSource={
                loading ? undefined : handleDepartmentAndEmployeeDropdown()
              }
              expandable={{ expandedRowRender }}
              loading={loading}
              locale={locale}
            />
          </Content>
          <DraggableModal
            maskStyle={{ zIndex: 1 }}
            className="modalStyle"
            footer={null}
            style={
              // { minWidth: "40%", zIndex: 10 }
              { minWidth: width < 1025 ? "90%" : "70%", zIndex: 10 }
            }
            centered
            open={modalVisible}
            onOk={onOk}
            onCancel={onCancel}
            handleRefresh={handleRefresh}
            modalBody={
              selectedRow && (
                <TaskModal
                  selectedRow={selectedRow}
                  filteredData={filteredData}
                  rowId={rowId}
                  handleRefresh={handleRefresh}
                  modalVisible={modalVisible}
                  claimedPressed={claimedPressed}
                  setClaimedPressed={setClaimedPressed}
                  onDatePickerFocus={handleDatePickerFocus}
                  onDatePickerBlur={handleDatePickerBlur}
                  onMessageInputFocus={handleMessageInputFocus}
                  onMessageInputBlur={handleMessageInputBlur}
                  onActionDropdownFocus={handleActionDropdownFocus}
                  onActionDropdownBlur={handleActionDropdownBlur}
                  newDataForClaimedTasks={newDataForClaimedTasks}
                  subTaskItemPressed={subTaskItemPressed}
                  setSubTaskItemPressed={setSubTaskItemPressed}
                />
              )
            }
            disableDragging={[
              datePickerFocused,
              messageInputFocused,
              actionDropdownFocused,
            ]}
          />
        </View>
      </View>
    );
  }
}

export default EmployeeView;
