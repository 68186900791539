import { Table, Empty, Modal } from "antd";
import { View, Dimensions } from "react-native";
import "./taskList.css";
import ProfileIconStack from "./profileIconStack";
import TaskModal from "./taskModal";
import { useState, useRef, Fragment } from "react";
import DraggableModal from "./draggableModal";
import dayjs from "dayjs";
import "./tableStyles.css";
import "./taskList.css";

function RollUpView({
  selectedViewValue,
  rolledUpTasksData,
  deferToggle,
  rowClassName,
  onOk,
  onCancel,
  handleRefresh,
  claimedPressed,
  setClaimedPressed,
  filteredData,
  newDataForClaimedTasks,
  allRollLogs,
  users,
  subTaskItemPressed,
  setSubTaskItemPressed,
}) {
  const clickTimerRef = useRef(null);
  const longPressTimerRef = useRef(null);
  const { width, height } = Dimensions.get("window");
  const [showRollUpModal, setShowRollUpModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showRollLogHistory, setShowRollLogHistory] = useState(false);

  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [messageInputFocused, setMessageInputFocused] = useState(false);
  const [actionDropdownFocused, setActionDropdownFocused] = useState(false);

  const handleDatePickerFocus = () => {
    setDatePickerFocused(true);
  };

  const handleDatePickerBlur = () => {
    setDatePickerFocused(false);
  };

  const handleMessageInputFocus = () => {
    setMessageInputFocused(true);
  };

  const handleMessageInputBlur = () => {
    setMessageInputFocused(false);
  };

  const handleActionDropdownFocus = () => {
    setActionDropdownFocused(true);
  };

  const handleActionDropdownBlur = () => {
    setActionDropdownFocused(false);
  };

  const CustomEmpty = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>No active rolled tasks</span>}
    />
  );

  const handleSingleClick = (recordKey) => {
    setShowRollUpModal(true);
    setSelectedRow(recordKey);
  };

  const handleDoubleClick = (recordKey) => {
    setShowRollLogHistory(true);
    setSelectedRow(recordKey);
  };

  function findUserNameById(id) {
    const user = users.find((user) => user.id === id);
    return user ? user.name : null;
  }

  const columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (text, record) => {
        const userName = findUserNameById(record.user_id);
        return (
          <>
            Rolled From: {userName}
            <span> ({record.data.length})</span>
          </>
        );
      },
    },
  ];
  
  let combinedRollUpHistory = [];

  const expandedRowRender = (record) => {
    const nestedColumns = [
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        defaultSortOrder: "ascend",
        sorter: {
          compare: (a, b) => a.rawPriority - b.rawPriority,
          multiple: 1,
        },
        className: "sorted",
      },
      {
        title: "Task",
        dataIndex: "task",
        key: "task",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        defaultSortOrder: "ascend",
        sorter: {
          compare: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
          multiple: 2,
        },
        className: "sorted",
      },
      {
        title: "Claimed Users",
        dataIndex: "claimedUsers",
        key: "claimedUsers",
        render: (claimedUsers) => {
          if (claimedUsers) {
            return (
              <>
                {claimedUsers.Primary && claimedUsers.Primary.length > 0 && (
                  <ProfileIconStack
                    key={claimedUsers.Primary}
                    listOfUsers={claimedUsers.Primary}
                    marginRight={10}
                    userObject={users}
                  />
                )}
                {claimedUsers.Secondary &&
                  claimedUsers.Secondary.length > 0 && (
                    <ProfileIconStack
                      key={claimedUsers.Secondary}
                      listOfUsers={claimedUsers.Secondary}
                      inactiveAvatarColor={"rgba(0, 0, 0, 1)"}
                      userObject={users}
                    />
                  )}
              </>
            );
          }
        },
      },
    ];

    if (selectedViewValue !== "category view") {
      nestedColumns.unshift({
        title: "Category",
        dataIndex: "category",
        key: "category",
      });
    }

    if (deferToggle) {
      nestedColumns.push({
        title: "Defer Date",
        dataIndex: "deferDate",
        key: "deferDate",
      });
    }

    const priorityMap = {
      1: "Very High",
      2: "High",
      3: "High",
      4: "Medium",
      5: "Medium",
      6: "Medium",
      7: "Medium",
      8: "Low",
      9: "Low",
      10: "Low",
      11: "Very Low",
    };

    function getPriority(priority) {
      return priorityMap[priority] || "Unknown";
    }

    const processedData = record.data.map((item) => {
      const data = item.data || item;
      return {
        key: data.id,
        category:
          selectedViewValue !== "category view"
            ? data.category?.name
            : undefined,
        rawPriority: data.priority,
        priority: data.priority && getPriority(data.priority),
        task: data.name && data.name,
        status: data.status && data.status.status,
        dueDate: data.due_date && dayjs(data.due_date).format("MM/DD/YYYY"),
        deferDate:
          data.defer_date && dayjs(data.defer_date).format("MM/DD/YYYY"),
        claimedUsers: data.claimed_users,
        rollUpLogHistory: record.rolledUpLogHistory,
      };
    });

    let taskSpecificRollUpHistory = [];

    // Iterate through allRollLogs
    allRollLogs.forEach((log) => {
      // Check if there's a matching key in processedData
      const matchingKey = processedData.filter(
        (data) => data.key === log.taskID
      );

      // If there's a match, add the log object to taskSpecificRollUpHistory
      if (matchingKey.length > 0) {
        taskSpecificRollUpHistory.push(log);
      }
    });

    function findUserNameById(id) {
      const user = users.find((user) => user.id === id);
      return user ? user.name : null;
    }

    const updatedTaskSpecificRollUpHistory = taskSpecificRollUpHistory.map(
      (item) => {
        return {
          ...item,
          rolledFrom: findUserNameById(parseInt(item.rolledFrom)),
          rolledTo: findUserNameById(parseInt(item.rolledTo)),
        };
      }
    );

    combinedRollUpHistory.push(...updatedTaskSpecificRollUpHistory);

    const rowIdArray =
      selectedViewValue !== "category view"
        ? record.data.map((item) => item.data && item.data.id)
        : null;

    return (
      <>
        <Table
          columns={nestedColumns}
          dataSource={processedData}
          rowClassName={rowClassName}
          rowKey={(record) => record.key}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: (event) => {
                clearTimeout(clickTimerRef.current);
                clickTimerRef.current = setTimeout(
                  () => handleSingleClick(record.key),
                  250
                );
              },
              onDoubleClick: (event) => {
                clearTimeout(clickTimerRef.current);
                handleDoubleClick(record.key);
              },
              onTouchStart: (event) => {
                longPressTimerRef.current = setTimeout(
                  handleDoubleClick(record.key),
                  500
                );
              },
              onTouchEnd: (event) => {
                clearTimeout(longPressTimerRef.current);
              },
              onMouseEnter: (event) => {
                document.body.style.cursor = "pointer";
              },
              onMouseLeave: (event) => {
                document.body.style.cursor = "default";
              },
            };
          }}
        />
      </>
    );
  };

  const RollUpHistoryContentForModal = () => {
    return combinedRollUpHistory.map((item, index) => {
      if (item.taskID === selectedRow) {
        return (
          <Fragment key={index}>
            <p>
              Rolled From: <strong>{item.rolledFrom}</strong> - Rolled To:{" "}
              <strong>{item.rolledTo}</strong> (
              {dayjs(item.dateRolled).format("MM/DD/YYYY")})
            </p>
          </Fragment>
        );
      }
    });
  };

  const categoryViewExpandedRowRender = (record) => {
    const categorizedColumns = [
      {
        key: "rowCategory",
        dataIndex: "rowCategory",
        render: (text, record) => (
          <>
            {text}
            <span> ({record.data.length})</span>
          </>
        ),
      },
    ];

    let uniqueArr = {};
    const data = record.data.map((obj) => obj);
    data.forEach((obj) => {
      if (obj.data.category) {
        if (!uniqueArr[obj.data.category.name]) {
          uniqueArr[obj.data.category.name] = {
            rowCategory: obj.data.category.name,
            data: [],
            rolledUpLogHistory: obj.rollUpLogHistory,
          };
        }
        uniqueArr[obj.data.category.name].data.push(obj.data);
      }
    });
    uniqueArr = Object.values(uniqueArr);

    return (
      <Table
        rowKey="rowCategory"
        columns={categorizedColumns}
        dataSource={uniqueArr}
        expandedRowRender={expandedRowRender}
        pagination={false}
      />
    );
  };

  return (
    <View>
      <div className="table-container">
        <Table
          className="table-border"
          showHeader={false}
          columns={columns}
          dataSource={rolledUpTasksData}
          pagination={false}
          expandedRowRender={
            selectedViewValue === "category view"
              ? categoryViewExpandedRowRender
              : expandedRowRender
          }
          rowKey="user_id"
          locale={{
            emptyText: <CustomEmpty />,
          }}
        />
        {showRollUpModal && (
          <DraggableModal
            maskStyle={{ zIndex: 1 }}
            className="modalStyle"
            footer={null}
            style={
              filteredData.some((item) => item.hasOwnProperty("active"))
                ? { minWidth: "40%", zIndex: 10 }
                : { minWidth: width < 1025 ? "90%" : "70%", zIndex: 10 }
            }
            centered
            open={showRollUpModal}
            onOk={onOk}
            onCancel={() => {
              onCancel;
              setShowRollUpModal(false);
            }}
            handleRefresh={handleRefresh}
            modalBody={
              selectedRow && (
                <TaskModal
                  selectedRow={selectedRow}
                  filteredData={rolledUpTasksData
                    .map((item) =>
                      item.data.map((item) => item.data && item.data)
                    )
                    .flat()}
                  rowId={selectedRow}
                  handleRefresh={handleRefresh}
                  modalVisible={showRollUpModal}
                  claimedPressed={claimedPressed}
                  setClaimedPressed={setClaimedPressed}
                  onDatePickerFocus={handleDatePickerFocus}
                  onDatePickerBlur={handleDatePickerBlur}
                  onMessageInputFocus={handleMessageInputFocus}
                  onMessageInputBlur={handleMessageInputBlur}
                  onActionDropdownFocus={handleActionDropdownFocus}
                  onActionDropdownBlur={handleActionDropdownBlur}
                  newDataForClaimedTasks={newDataForClaimedTasks}
                  users={users}
                  subTaskItemPressed={subTaskItemPressed}
                  setSubTaskItemPressed={setSubTaskItemPressed}
                />
              )
            }
            disableDragging={[
              datePickerFocused,
              messageInputFocused,
              actionDropdownFocused,
            ]}
          />
        )}
      </div>
      <Modal
        title={<p className="modal-title">Roll Up History</p>}
        open={showRollLogHistory}
        onOk={() => setShowRollLogHistory(false)}
        onCancel={() => setShowRollLogHistory(false)}
      >
        <RollUpHistoryContentForModal selectedRow={selectedRow} />
      </Modal>
    </View>
  );
}

export default RollUpView;
