import "./taskList.css";
import { useState, useEffect } from "react";
import { Form, Input, Button, notification, Select, Radio } from "antd";
import { View } from "react-native";
import DraggableModal from "./draggableModal";

function StatusModal({ visible, setVisible, rowId, statusesData }) {
  const [form] = Form.useForm();
  const [statusName, setStatusName] = useState("");
  const [firstStatusDisabled, setFirstStatusDisabled] = useState(false);
  const [lastStatusDisabled, setLastStatusDisabled] = useState(false);
  const [firstStatus, setFirstStatus] = useState(0);
  const [lastStatus, setLastStatus] = useState(0);

  const handleCancel = () => {
    setVisible(false);
    setFirstStatus(0);
    setLastStatus(0);
    setFirstStatusDisabled(false);
    setLastStatusDisabled(false);
    form.resetFields();
  };

  useEffect(() => {
    if (rowId !== undefined) {
      const status = statusesData.find((data) => data.id === rowId);
      form.setFieldsValue({
        statusName: status.status,
        firstStatus: status.is_first_status,
        lastStatus: status.is_last_status,
      });
      setStatusName(status.status);
      setFirstStatus(status.is_first_status);
      setLastStatus(status.is_last_status);
      status.is_first_status === 1
        ? setLastStatusDisabled(true)
        : setLastStatusDisabled(false);
      status.is_last_status === 1
        ? setFirstStatusDisabled(true)
        : setFirstStatusDisabled(false);
    }
  }, [rowId]);

  const createNewStatus = async () => {
    var body = {
      status: statusName,
      is_first_status: parseInt(firstStatus),
      is_last_status: parseInt(lastStatus),
    };
    try {
      const response = await fetch(global.urlBase + "statuses", {
        headers: new Headers({
          Authorization: "Bearer " + global.token,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(body),
      });
      if (response.status === 201) {
        notification.success({
          message: "Success!",
          description: "Status successfully created.",
          duration: 2,
          onClose: () => {
            location.reload();
          },
        });
      } else {
        const error = await response.json();
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "There was an error creating the status",
      });
    }
  };

  const updateStatus = async () => {
    var body = {
      status: statusName,
      is_first_status: parseInt(firstStatus),
      is_last_status: parseInt(lastStatus),
    };
    try {
      const response = await fetch(global.urlBase + "statuses/" + rowId, {
        headers: new Headers({
          Authorization: "Bearer " + global.token,
          "Content-Type": "application/json",
        }),
        method: "PUT",
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        notification.success({
          message: "Success!",
          description: "Your changes have been saved.",
          duration: 2,
          onClose: () => {
            location.reload();
          },
        });
      } else {
        const error = await response.json();
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "There was an error updating the status",
      });
    }
  };

  return (
    <>
      <DraggableModal
        className="custom-modal"
        open={visible}
        footer={false}
        title={"Status View"}
        onCancel={handleCancel}
        modalBody={
          <Form
            form={form}
            style={{ paddingBottom: 20 }}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label="Status Name:"
              name="statusName"
              rules={[
                {
                  required: true,
                  message: "Please input the status name",
                },
              ]}
            >
              <Input
                maxLength={255}
                placeholder="Enter status name"
                onChange={(e) => {
                  setStatusName(e.target.value);
                }}
                value={statusName}
                showCount
              />
            </Form.Item>
            <Form.Item label="First Status ?">
              <Radio.Group
                value={firstStatus}
                onChange={(e) => {
                  setFirstStatus(e.target.value);
                  if (e.target.value === 1) {
                    setLastStatus(0);
                    setLastStatusDisabled(true);
                  } else {
                    setLastStatusDisabled(false);
                  }
                }}
              >
                <Radio disabled={firstStatusDisabled} value={1}>
                  True
                </Radio>
                <Radio value={0}>False</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Last Status ?">
              <Radio.Group
                value={lastStatus}
                onChange={(e) => {
                  setLastStatus(e.target.value);
                  if (e.target.value === 1) {
                    setFirstStatus(0);
                    setFirstStatusDisabled(true);
                  } else {
                    setFirstStatusDisabled(false);
                  }
                }}
              >
                <Radio disabled={lastStatusDisabled} value={1}>
                  True
                </Radio>
                <Radio value={0}>False</Radio>
              </Radio.Group>
            </Form.Item>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    await form.validateFields(["statusName"]);
                    {
                      rowId === undefined ? createNewStatus() : updateStatus();
                    }
                  } catch (error) {
                    const errorMessages = [
                      ...new Set(
                        error.errorFields.map(
                          (fieldError) => fieldError.name[0]
                        )
                      ),
                    ];

                    const splitErrorMessages = errorMessages.map(
                      (errorMessage) =>
                        errorMessage.split(/(?=[A-Z])/).join(" ")
                    );

                    const capitalizedErrorMessages = splitErrorMessages.map(
                      (errorMessage) =>
                        errorMessage.charAt(0).toUpperCase() +
                        errorMessage.slice(1)
                    );

                    notification.error({
                      duration: null,
                      message: "Invalid Form",
                      description:
                        "The following fields are invalid: " +
                        capitalizedErrorMessages.join(", "),
                    });
                  }
                }}
              >
                Submit
              </Button>
            </View>
          </Form>
        }
      />
    </>
  );
}

export default StatusModal;
