import ajaxCall from "./shared-components/ajaxCall";
import { View, StyleSheet } from "react-native";
import { useEffect, useState, useMemo, useRef } from "react";
import { Layout, Table } from "antd";
import TemplateModal from "./shared-components/templateViewModal";
import GenericListHeader from "./shared-components/taskListHeader";

function TemplateList() {
  const { Content, Header } = Layout;
  const [users, setUsers] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rowId, setRowId] = useState(undefined);
  const [inputValue, setInputValue] = useState("");

  const debounceTimeout = useRef(null);
  const debounceDuration = 500;

  const getTaskTemplate = async () => {
    if (templateData.length === 0) {
      const json = await ajaxCall(global.urlBase + "taskTemplate");
      setTemplateData(json);
    }
  };

  const getStatuses = async () => {
    const json = await ajaxCall(global.urlBase + "statuses");
    setStatuses(json);
  };

  const getCategories = async () => {
    const json = await ajaxCall(global.urlBase + "categories");
    setCategories(json);
  };

  const getUsers = async () => {
    const json = await ajaxCall(
      "https://wrapperapi.integrityprodserver.com/api/users"
    );
    setUsers(json);
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      getTaskTemplate();
      getCategories();
      getStatuses();
      getUsers();
    }, debounceDuration);
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [global.urlBase]);

  const showModal = () => {
    setVisible(true);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const processedData = useMemo(() => {
    const filteredData = templateData.filter((item) => {
      const nameMatch =
        item.templateName &&
        item.templateName.toLowerCase().includes(inputValue.toLowerCase());
      const descMatch =
        item.templateDescription &&
        item.templateDescription
          .toLowerCase()
          .includes(inputValue.toLowerCase());
      return nameMatch || descMatch;
    });

    return filteredData.map(({ id, templateName, templateDescription }) => ({
      key: id,
      id,
      name: templateName,
      description: templateDescription,
    }));
  }, [inputValue, templateData]);

  useEffect(() => {
    if (!visible) {
      setRowId(undefined);
    }
  }, [visible]);

  return (
    <Layout>
      <GenericListHeader
        button={true}
        buttonOnClick={showModal}
        buttonPlaceholder="Create Template"
        firstDropdown={false}
        secondDropdown={false}
        showSwitchOne={false}
        showSwitchTwo={false}
        rangePicker={false}
        searchPlaceholder="Search by template name or description"
        onInputChange={(event) => {
          setInputValue(event.target.value);
        }}
        menuWidth={"auto"}
        buttonWidth={335}
        showSwitchThree={false}
      />
      <View
        style={{
          backgroundColor: "white",
          borderTopColor: "rgb(33, 150, 243)",
          borderTopWidth: 10,
          borderRadius: 10,
          margin: 20,
        }}
      >
        <Content>
          <Table
            dataSource={processedData}
            columns={columns}
            loading={templateData.length > 0 ? false : true}
            onRow={(record) => {
              return {
                onClick: () => {
                  setRowId(record.key);
                  showModal();
                },
                style: {
                  cursor: "pointer",
                },
              };
            }}
          />
          {visible && (
            <TemplateModal
              visible={visible}
              setVisible={setVisible}
              categories={categories}
              statuses={statuses}
              users={users}
              rowId={rowId}
              templateData={templateData}
              templateType={"taskTemplate"}
            />
          )}
        </Content>
      </View>
    </Layout>
  );
}

export default TemplateList;
