import { Layout, Table, Empty } from "antd";
import NormalTable from "./normalTable";
import CategoryTable from "./categoryTable";
import GenericListHeader from "./taskListHeader";
import { View } from "react-native";
import "./taskList.css";
import EmployeeView from "./employeeView";
import RollUpView from "./rollUpView";

function TaskListLayout({
  rolledUpTasksData,
  newDataForClaimedTasks,
  employeeList,
  data,
  deferToggle,
  searchPlaceholder,
  firstDropdownOnChange,
  secondDropdownOnChange,
  firstDropdownDefaultValue,
  firstDropdownOptions,
  firstDropdownPlaceholder,
  firstDropdown,
  filterData,
  filteredData,
  handleSwitchOne,
  handleSwitchTwo,
  handleSwitchThree,
  secondDropdownPlaceholder,
  columns,
  handleRowClick,
  onDateChange,
  onInputChange,
  processedData,
  processedRolledData,
  rolledUpToggle,
  processedCompletedData,
  rowClassName,
  switchOne,
  switchTwo,
  switchThree,
  secondDropdownMode,
  secondDropdownOptions,
  selectedDates,
  selectedViewValue,
  selectedCategoryValue,
  switchOnePlaceholder,
  switchTwoPlaceholder,
  switchThreePlaceholder,
  onOk,
  onCancel,
  modalVisible,
  selectedRow,
  rowId,
  tableLoading,
  departmentItems,
  departmentView,
  departmentViewOnChange,
  employeeDropdownItems,
  employeeDropdownValue,
  employeeDropdownOnChange,
  handleRefresh,
  completedTasks,
  claimedPressed,
  setClaimedPressed,
  button,
  buttonPlaceholder,
  rangePicker,
  secondDropdown,
  showSwitchTwo,
  showSwitchThree,
  menuWidth,
  showCompletedTasksTable = true,
  inactiveToggle,
  buttonOnClick,
  allRollLogs,
  users,
  showRollUpTable = true,
  buttonWidth,
  selectedTable,
  subTaskItemPressed,
  setSubTaskItemPressed
}) {
  const completedTasksColumns = [
    {
      key: "rowCatgory",
      dataIndex: "rowCategory",
    },
  ];

  const CustomEmpty = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>No Tasks to Display</span>}
    />
  );

  return (
    <Layout>
      <GenericListHeader
        firstDropdown={firstDropdown}
        menuWidth={menuWidth}
        showSwitchTwo={showSwitchTwo}
        showSwitchThree={showSwitchThree}
        secondDropdown={secondDropdown}
        button={button}
        buttonPlaceholder={buttonPlaceholder}
        buttonWidth={buttonWidth}
        buttonOnClick={buttonOnClick}
        onDateChange={onDateChange}
        onInputChange={onInputChange}
        firstDropdownDefaultValue={firstDropdownDefaultValue}
        secondDropdownOptions={secondDropdownOptions}
        data={data}
        filterData={filterData}
        firstDropdownOptions={firstDropdownOptions}
        firstDropdownOnChange={firstDropdownOnChange}
        handleSwitchOne={handleSwitchOne}
        handleSwitchTwo={handleSwitchTwo}
        handleSwitchThree={handleSwitchThree}
        searchPlaceholder={searchPlaceholder}
        firstDropdownPlaceholder={firstDropdownPlaceholder}
        secondDropdownMode={secondDropdownMode}
        secondDropdownOnChange={secondDropdownOnChange}
        secondDropdownPlaceholder={secondDropdownPlaceholder}
        switchOnePlaceholder={switchOnePlaceholder}
        switchTwoPlaceholder={switchTwoPlaceholder}
        switchThreePlaceholder={switchThreePlaceholder}
        switchOne={switchOne}
        switchTwo={switchTwo}
        switchThree={switchThree}
        selectedCategoryValue={selectedCategoryValue}
        selectedViewValue={selectedViewValue}
        selectedDates={selectedDates}
        rangePicker={rangePicker}
      />
      {selectedViewValue === "normal view" && (
        <>
          {!rolledUpToggle && showRollUpTable && (
            <View style={{ margin: 20 }}>
              <RollUpView
                selectedViewValue={selectedViewValue}
                rolledUpTasksData={rolledUpTasksData}
                deferToggle={deferToggle}
                rowClassName={rowClassName}
                handleRowClick={handleRowClick}
                modalVisible={modalVisible}
                onOk={onOk}
                onCancel={onCancel}
                selectedRow={selectedRow}
                handleRefresh={handleRefresh}
                claimedPressed={claimedPressed}
                setClaimedPressed={setClaimedPressed}
                rowId={rowId}
                filteredData={filteredData}
                newDataForClaimedTasks={newDataForClaimedTasks}
                allRollLogs={allRollLogs}
                users={users}
                subTaskItemPressed={subTaskItemPressed}
                setSubTaskItemPressed={setSubTaskItemPressed}          
              />
            </View>
          )}
          <View
            style={{
              backgroundColor: "white",
              borderTopColor: "rgb(33, 150, 243)",
              borderTopWidth: 10,
              borderRadius: 10,
              margin: 20,
            }}
          >
            <NormalTable
              rowClassName={rowClassName}
              tableLoading={tableLoading}
              columns={columns}
              processedData={processedData}
              handleRowClick={(record) => handleRowClick(record, "normalTable")}
              onOk={onOk}
              onCancel={onCancel}
              modalVisible={modalVisible && selectedTable === "normalTable"}
              selectedRow={selectedRow}
              rowId={rowId}
              filteredData={filteredData}
              handleRefresh={handleRefresh}
              claimedPressed={claimedPressed}
              setClaimedPressed={setClaimedPressed}
              newDataForClaimedTasks={newDataForClaimedTasks}
              subTaskItemPressed={subTaskItemPressed}
              setSubTaskItemPressed={setSubTaskItemPressed}        
              locale={{
                emptyText: <CustomEmpty />,
              }}
              users={users}
            />
          </View>
          {showCompletedTasksTable && (
            <View
              style={{
                backgroundColor: "white",
                borderTopColor: "rgb(33, 150, 243)",
                borderTopWidth: 10,
                borderRadius: 10,
                margin: 20,
              }}
            >
              <Table
                expandedRowRender={(record) => (
                  <NormalTable
                    completedTasksTable={true}
                    rowClassName={rowClassName}
                    columns={columns}
                    processedData={processedCompletedData}
                    handleRowClick={(record) =>
                      handleRowClick(record, "completedTasksTable")
                    }
                    onOk={onOk}
                    onCancel={onCancel}
                    modalVisible={
                      modalVisible && selectedTable === "completedTasksTable"
                    }
                    selectedTable={selectedTable}
                    selectedRow={selectedRow}
                    rowId={rowId}
                    filteredData={completedTasks}
                    handleRefresh={handleRefresh}
                    claimedPressed={claimedPressed}
                    setClaimedPressed={setClaimedPressed}
                    users={users}
                    subTaskItemPressed={subTaskItemPressed}
                    setSubTaskItemPressed={setSubTaskItemPressed}              
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>No Completed Tasks</span>}
                        />
                      ),
                    }}
                  />
                )}
                pagination={false}
                loading={tableLoading}
                columns={completedTasksColumns}
                rowKey="rowCategory"
                dataSource={[
                  { rowCategory: "Completed Tasks", data: completedTasks },
                ]}
              />
            </View>
          )}
        </>
      )}
      {selectedViewValue === "category view" && (
        <>
          {!rolledUpToggle && showRollUpTable && (
            <View style={{ margin: 20 }}>
              <RollUpView
                selectedViewValue={selectedViewValue}
                rolledUpTasksData={rolledUpTasksData}
                deferToggle={deferToggle}
                rowClassName={rowClassName}
                handleRowClick={handleRowClick}
                modalVisible={modalVisible}
                onOk={onOk}
                onCancel={onCancel}
                selectedRow={selectedRow}
                handleRefresh={handleRefresh}
                claimedPressed={claimedPressed}
                setClaimedPressed={setClaimedPressed}
                rowId={rowId}
                filteredData={filteredData}
                newDataForClaimedTasks={newDataForClaimedTasks}
                allRollLogs={allRollLogs}
                users={users}
                subTaskItemPressed={subTaskItemPressed}
                setSubTaskItemPressed={setSubTaskItemPressed}          
              />
            </View>
          )}
          <View>
            <CategoryTable
              inactiveToggle={inactiveToggle}
              handleRowClick={(record) =>
                handleRowClick(record, "categoryTable")
              }
              rowClassName={rowClassName}
              filteredData={filteredData}
              deferToggle={deferToggle}
              onOk={onOk}
              onCancel={onCancel}
              modalVisible={modalVisible && selectedTable === "categoryTable"}
              selectedRow={selectedRow}
              rowId={rowId}
              users={users}
              tableLoading={tableLoading}
              handleRefresh={handleRefresh}
              claimedPressed={claimedPressed}
              setClaimedPressed={setClaimedPressed}
              newDataForClaimedTasks={newDataForClaimedTasks}
              subTaskItemPressed={subTaskItemPressed}
              setSubTaskItemPressed={setSubTaskItemPressed}        
              locale={{
                emptyText: <CustomEmpty />,
              }}
            />
          </View>
          {showCompletedTasksTable && (
            <View
              style={{
                backgroundColor: "white",
                borderTopColor: "rgb(33, 150, 243)",
                borderTopWidth: 10,
                borderRadius: 10,
                margin: 20,
              }}
            >
              <Table
                expandedRowRender={(record) => (
                  <NormalTable
                    completedTasksTable={true}
                    rowClassName={rowClassName}
                    columns={columns}
                    processedData={processedCompletedData}
                    handleRowClick={(record) =>
                      handleRowClick(record, "completedTasksTable")
                    }
                    onOk={onOk}
                    onCancel={onCancel}
                    modalVisible={
                      modalVisible && selectedTable === "completedTasksTable"
                    }
                    selectedRow={selectedRow}
                    rowId={rowId}
                    filteredData={completedTasks}
                    handleRefresh={handleRefresh}
                    claimedPressed={claimedPressed}
                    setClaimedPressed={setClaimedPressed}
                    subTaskItemPressed={subTaskItemPressed}
                    setSubTaskItemPressed={setSubTaskItemPressed}              
                    users={users}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>No Completed Tasks</span>}
                        />
                      ),
                    }}
                  />
                )}
                pagination={false}
                loading={tableLoading}
                columns={completedTasksColumns}
                rowKey="rowCategory"
                dataSource={[
                  { rowCategory: "Completed Tasks", data: completedTasks },
                ]}
              />
            </View>
          )}
        </>
      )}
      {selectedViewValue === "employee view" && (
        <EmployeeView
          departmentItems={departmentItems}
          departmentView={departmentView}
          departmentViewOnChange={departmentViewOnChange}
          employeeDropdownItems={employeeDropdownItems}
          employeeDropdownValue={employeeDropdownValue}
          employeeDropdownOnChange={employeeDropdownOnChange}
          filteredData={filteredData}
          handleRowClick={handleRowClick}
          rowClassName={rowClassName}
          deferToggle={deferToggle}
          onOk={onOk}
          onCancel={onCancel}
          modalVisible={modalVisible}
          selectedRow={selectedRow}
          rowId={rowId}
          users={users}
          tableLoading={tableLoading}
          handleRefresh={handleRefresh}
          claimedPressed={claimedPressed}
          setClaimedPressed={setClaimedPressed}
          employeeList={employeeList}
          newDataForClaimedTasks={newDataForClaimedTasks}
          subTaskItemPressed={subTaskItemPressed}
          setSubTaskItemPressed={setSubTaskItemPressed}    
          locale={{
            emptyText: <CustomEmpty />,
          }}
        />
      )}
    </Layout>
  );
}

export default TaskListLayout;
