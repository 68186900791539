import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Button,
  StyleSheet,
  ActivityIndicator,
  TextInput,
} from "react-native";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { KeycloakProvider, useKeycloak } from "expo-keycloak-auth";
import AppConfig from "./app.json";
import TaskListPage from "./pages/TaskListPage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ScheduledTaskListPage from "./pages/ScheduledTaskListPage";
import { Linking } from "react-native";
import ajaxCall from "./components/shared-components/ajaxCall";
import TemplateListPage from "./pages/TemplateListPage";
import CategoriesListPage from "./pages/CategoriesListPage";
import StatusesListPage from "./pages/StatusesListPage";
import WorkflowListPage from "./pages/WorkflowListPage";
import ScheduledTemplateListPage from "./pages/ScheduledTemplateListPage";

export default function App() {
  const keycloakConfiguration = {
    clientId: "wms",
    realm: "Integrity_Home_Exteriors", // your realm name
    url: "https://keycloak.integrityprodserver.com", // This is usually a url ending with /auth
    scheme: AppConfig.expo.scheme,
    secret: "9tJlvVpaz7dVbLdK8RsDpK4WkxAuLOJg",
    refreshTimeBuffer: 59,
  };

  return (
    <KeycloakProvider {...keycloakConfiguration}>
      <View>
        <Auth />
      </View>
    </KeycloakProvider>
  );

  return <></>;
}

export const Auth = () => {
  var {
    ready, // If the discovery is already fetched and ready to prompt authentication flow
    login, // The login function - opens the browser
    isLoggedIn, // Helper boolean to use e.g. in your components down the tree
    token, // Access token, if available
    logout, // The logout function - Logs the user out
  } = useKeycloak();

  global.token = { token }["token"];
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("auth")) {
    token = urlParams.get("auth");
    isLoggedIn = true;
    global.token = token;
  }
  
  const [user, setUser] = useState(undefined);
  const [urlBase, setUrlBase] = useState(
    "https://wmsapi.integrityprodserver.com/api/"
  );
  const getUserInfo = async () => {
    if (token) {
      try {
        const response = await fetch(
          "https://wrapperapi.integrityprodserver.com/api/users/me",
          { headers: new Headers({ Authorization: "Bearer " + global.token }) }
        );
        const json = await response.json();
        if (json.email) {
          Linking.getInitialURL().then((url) => {
            if (
              url.toString().includes("localhost") ||
              url.toString().includes("intserver-dev") ||
              url.toString().includes("192.168.2.77") ||
              url.toString().includes("bs-local.com")
            ) {
              setUrlBase(
                "http://intserver-dev/~" +
                  json["email"].split("@")[0] +
                  "/wms-alpha/wms-backend/public/api/"
              );
            }
          });
        }
        setUser(json);
      } catch (error) {
        console.error(error);
      }
    }
    // return getUserInfo;
  };

  useEffect(() => {
    getUserInfo();
  }, [global.token]);

  global.userInfo = user;
  global.urlBase = urlBase;

  if (!ready) {
    return <ActivityIndicator />;
  }

  if (!isLoggedIn) {
    return (
      <View style={{ margin: 24 }}>
        <Button onPress={login} title="Login" />
      </View>
    );
  }

  var button = "";
  if (urlParams.get("auth")) {
    button = "";
  } else {
    button = (
      <Button onPress={logout} title={"Logout"} style={{ marginTop: 24 }} />
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/taskList" element={<TaskListPage />} />
          <Route exact path="/templateList" element={<TemplateListPage />} />
          <Route exact path="/statusesList" element={<StatusesListPage />} />
          <Route exact path="/workflowList" element={<WorkflowListPage />} />
          <Route
            exact
            path="/categoriesList"
            element={<CategoriesListPage />}
          />
          <Route
            exact
            path="/scheduledTaskList"
            element={<ScheduledTaskListPage />}
          />
          <Route
            exact
            path="/scheduledTemplateList"
            element={<ScheduledTemplateListPage />}
          />
        </Routes>
      </BrowserRouter>
      <View style={{ margin: 24 }}>{button}</View>
    </>
  );
};
