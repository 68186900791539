import WorkflowList from "../components/WorkflowsListView";

const WorkflowListPage = () => {
  return (
    <>
      <WorkflowList />
    </>
  );
};

export default WorkflowListPage;
