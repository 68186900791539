import React, { useState, useEffect } from "react";
import { Avatar, Popover } from "antd";
import { Dimensions } from "react-native";
import ajaxCall from "./ajaxCall";

const ProfileIconStack = ({
  inactiveAvatarColor,
  activeAvatarColor = "rgba(39, 103, 245)",
  listOfUsers,
  maxIcons = 3,
  activeShape = "circle",
  inactiveShape = "circle",
  activePopoverColor,
  inactivePopoverColor,
  textColor,
  marginRight,
  marginLeft,
  userObject,
}) => {
  const [users, setUsers] = useState([]);
 
  useEffect(() => {
    // Create a Set for efficient membership checking
    const idSet = new Set(listOfUsers);

    // Filter the userObject array to only include users whose id is in idSet
    const filteredUsers = userObject !== undefined ? userObject.filter((user) => idSet.has(user.id)) : [];

    // Update the state with the filtered users
    setUsers(filteredUsers);
  }, [listOfUsers, userObject]);

  const [hoveringId, setHoveringId] = useState(null);
  const [avatarSize, setAvatarSize] = useState(32);

  useEffect(() => {
    const updateAvatarSize = () => {
      const window = Dimensions.get("window");
      const calculatedAvatarSize = Math.min(
        window.width / 30,
        window.height / 30
      );
      const avatarSize = Math.max(calculatedAvatarSize, 20);
      setAvatarSize(avatarSize);
    };

    updateAvatarSize();

    // update the avatar size when the window dimensions change
    Dimensions.addEventListener("change", updateAvatarSize);

    return () => {
      Dimensions.removeEventListener("change", updateAvatarSize);
    };
  }, []);

  const handleMouseEnter = (userId) => {
    setHoveringId(userId);
  };

  const handleMouseLeave = () => {
    setHoveringId(null);
  };

  const renderProfileIcon = (user, index) => {
    const style = {
      cursor: "pointer",
      position: "relative",
      backgroundColor: activeAvatarColor,
      marginRight: marginRight,
      fontSize: avatarSize / 2, // Adjust the font size based on avatarSize
    };

    // Set the zIndex based on the index of the avatar in the array
    style.zIndex = users.length - index;

    // Add front style if the user is being hovered over
    if (user.id === hoveringId) {
      style.zIndex = 1001;
    }

    // Split the name into an array of words
    const nameWords = user.name.split(" ");

    // Extract the first letter of the first and last names
    const initials = `${nameWords[0][0]}${nameWords[nameWords.length - 1][0]}`;

    return (
      <Avatar
        shape={activeShape}
        gap={8}
        key={user.id}
        size={avatarSize}
        style={style}
        onMouseEnter={() => handleMouseEnter(user.id)}
        onMouseLeave={handleMouseLeave}
      >
        {initials}
      </Avatar>
    );
  };

  const renderPopoverContent = (user) => {
    return (
      <div>
        <p style={{ color: textColor }}>{user.name}</p>
      </div>
    );
  };

  const numAdditionalUsers = users.length - maxIcons;

  const renderAdditionalUsersPopoverContent = () => {
    const renderProfileIconInPopover = (user, index) => {
      const style = {
        cursor: "pointer",
        backgroundColor: activeAvatarColor,
      };

      // Split the name into an array of words
      const nameWords = user.name.split(" ");

      // Extract the first letter of the first and last names
      const initials = `${nameWords[0][0]}${
        nameWords[nameWords.length - 1][0]
      }`;

      return (
        <Avatar
          shape={activeShape}
          gap={8}
          key={user.id}
          size={avatarSize}
          style={{ ...style, marginRight: 10 }}
          onMouseEnter={() => handleMouseEnter(user.id)}
          onMouseLeave={handleMouseLeave}
        >
          {initials}
        </Avatar>
      );
    };

    return (
      <Avatar.Group>
        {users.slice(maxIcons).map((user, index) => (
          <Popover
            key={user.id}
            content={renderPopoverContent(user)}
            color={activePopoverColor}
          >
            {renderProfileIconInPopover(user, index)}
          </Popover>
        ))}
      </Avatar.Group>
    );
  };

  return (
    <Avatar.Group style={{ marginLeft: marginLeft }}>
      {users.slice(0, maxIcons).map((user, index) => (
        <Popover
          key={user.id}
          content={renderPopoverContent(user)}
          color={activePopoverColor}
        >
          {renderProfileIcon(user, index)}
        </Popover>
      ))}
      {numAdditionalUsers > 0 && (
        <Popover
          content={renderAdditionalUsersPopoverContent()}
          trigger="hover"
          color={inactivePopoverColor}
        >
          <Avatar
            shape={inactiveShape}
            gap={8}
            style={{
              marginLeft: 3,
              cursor: "pointer",
              backgroundColor: inactiveAvatarColor,
            }}
            size={avatarSize}
          >
            +{numAdditionalUsers}
          </Avatar>
        </Popover>
      )}
    </Avatar.Group>
  );
};

export default ProfileIconStack;
