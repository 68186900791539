import { Layout, Table, Modal } from "antd";
import { View, Dimensions } from "react-native";
import moment from "moment";
import TaskModal from "./taskModal";
import ProfileIconStack from "./profileIconStack";
import { useState } from "react";
import DraggableModal from "./draggableModal";
import dayjs from "dayjs";

function CategoryTable({
  filteredData,
  deferToggle,
  rowClassName,
  tableLoading,
  handleRowClick,
  onOk,
  onCancel,
  modalVisible,
  selectedRow,
  rowId,
  handleRefresh,
  claimedPressed,
  setClaimedPressed,
  inactiveToggle,
  locale,
  users,
  subTaskItemPressed,
  setSubTaskItemPressed,
}) {
  const { Content } = Layout;
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const { width, height } = Dimensions.get("window");

  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [messageInputFocused, setMessageInputFocused] = useState(false);
  const [actionDropdownFocused, setActionDropdownFocused] = useState(false);

  const handleDatePickerFocus = () => {
    setDatePickerFocused(true);
  };

  const handleDatePickerBlur = () => {
    setDatePickerFocused(false);
  };

  const handleMessageInputFocus = () => {
    setMessageInputFocused(true);
  };

  const handleMessageInputBlur = () => {
    setMessageInputFocused(false);
  };

  const handleActionDropdownFocus = () => {
    setActionDropdownFocused(true);
  };

  const handleActionDropdownBlur = () => {
    setActionDropdownFocused(false);
  };

  const handleRowExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowIds([...expandedRowIds, record.rowCategory]);
    } else {
      setExpandedRowIds(
        expandedRowIds.filter((id) => id !== record.rowCategory)
      );
    }
  };

  let uniqueArr = {};
  filteredData.forEach((obj) => {
    if (obj.category) {
      if (!uniqueArr[obj.category.name]) {
        uniqueArr[obj.category.name] = {
          rowCategory: obj.category.name,
          data: [],
        };
      }
      uniqueArr[obj.category.name].data.push(obj);
    }
  });
  uniqueArr = Object.values(uniqueArr);

  const columns = [
    {
      key: "rowCategory",
      dataIndex: "rowCategory",
      render: (text, record) => (
        <>
          {text}
          <span> ({record.data.length})</span>
        </>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    if (!filteredData.some((item) => item.hasOwnProperty("active"))) {
      const columns = [
        {
          title: "Priority",
          dataIndex: "priority",
          key: "priority",
          defaultSortOrder: "ascend",
          sorter: {
            compare: (a, b) => a.rawPriority - b.rawPriority,
            multiple: 1,
          },
          className: "sorted",
        },
        {
          title: "Task",
          dataIndex: "task",
          key: "task",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Due Date",
          dataIndex: "dueDate",
          key: "dueDate",
          defaultSortOrder: "ascend",
          sorter: {
            compare: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
            multiple: 2,
          },
          className: "sorted",
        },
        {
          title: "Claimed Users",
          dataIndex: "claimedUsers",
          key: "claimedUsers",
          render: (claimedUsers) => {
            if (claimedUsers) {
              return (
                <>
                  {claimedUsers.Primary && claimedUsers.Primary.length > 0 && (
                    <ProfileIconStack
                      key={claimedUsers.Primary}
                      listOfUsers={claimedUsers.Primary}
                      marginRight={10}
                      userObject={users}
                    />
                  )}
                  {claimedUsers.Secondary &&
                    claimedUsers.Secondary.length > 0 && (
                      <ProfileIconStack
                        key={claimedUsers.Secondary}
                        listOfUsers={claimedUsers.Secondary}
                        inactiveAvatarColor={"rgba(0, 0, 0, 1)"}
                        userObject={users}
                      />
                    )}
                </>
              );
            }
          },
        },
      ];

      if (deferToggle) {
        columns.push({
          title: "Defer Date",
          dataIndex: "deferDate",
          key: "deferDate",
        });
      }

      const priorityMap = {
        1: "Very High",
        2: "High",
        3: "High",
        4: "Medium",
        5: "Medium",
        6: "Medium",
        7: "Medium",
        8: "Low",
        9: "Low",
        10: "Low",
        11: "Very Low",
      };

      function getPriority(priority) {
        return priorityMap[priority] || "Unknown";
      }

      const processedData = record.data
        .filter((item) => expandedRowIds.includes(item.category.name))
        .map((item) => {
          return {
            key: item.id,
            rawPriority: item.priority,
            priority: item.priority && getPriority(item.priority),
            task: item.name && item.name,
            status: item.status && item.status.status,
            dueDate: item.due_date && dayjs(item.due_date).format("MM/DD/YYYY"),
            deferDate:
              item.defer_date && dayjs(item.defer_date).format("MM/DD/YYYY"),
            claimedUsers: item.claimed_users,
          };
        });

      return (
        <>
          <Table
            columns={columns}
            dataSource={processedData}
            rowClassName={rowClassName}
            loading={tableLoading}
            onRow={handleRowClick}
            locale={locale}
          />
        </>
      );
    }

    if (filteredData.some((item) => item.hasOwnProperty("active"))) {
      const columns = [
        {
          title: "Priority",
          dataIndex: "priority",
          key: "priority",
          defaultSortOrder: "ascend",
          sorter: {
            compare: (a, b) => a.rawPriority - b.rawPriority,
            multiple: 1,
          },
          className: "sorted",
        },
        {
          title: "Task",
          dataIndex: "task",
          key: "task",
        },
      ];

      if (inactiveToggle) {
        columns.push({
          title: "Active/Inactive",
          dataIndex: "active",
          key: "active",
        });
      }

      const priorityMap = {
        1: "Very High",
        2: "High",
        3: "High",
        4: "Medium",
        5: "Medium",
        6: "Medium",
        7: "Medium",
        8: "Low",
        9: "Low",
        10: "Low",
        11: "Very Low",
      };

      function getPriority(priority) {
        return priorityMap[priority] || "Unknown";
      }

      const activeMap = {
        0: "Inactive",
        1: "Active",
      };

      function getActivity(activity) {
        return activeMap[activity];
      }

      const processedData = record.data
        .filter((item) => expandedRowIds.includes(item.category.name))
        .map((item) => ({
          key: item.id,
          category: item.category && item.category.name,
          rawPriority: item.priority,
          priority: item.priority && getPriority(item.priority),
          task: item.name && item.name,
          active:
            typeof item.active === "number"
              ? getActivity(item.active)
              : undefined,
        }));
      return (
        <>
          <Table
            columns={columns}
            dataSource={processedData}
            rowClassName={rowClassName}
            loading={tableLoading}
            onRow={handleRowClick}
            locale={locale}
          />
          <Modal
            footer={null}
            style={
              filteredData.some((item) => item.hasOwnProperty("active"))
                ? { minWidth: "40%", zIndex: 10 }
                : { minWidth: "70%", zIndex: 10 }
            }
            bodyStyle={{ padding: 0 }}
            centered
            open={modalVisible}
            onOk={onOk}
            onCancel={onCancel}
          >
            {selectedRow && (
              <TaskModal
                selectedRow={selectedRow}
                filteredData={filteredData}
                rowId={rowId}
                handleRefresh={handleRefresh}
                claimedPressed={claimedPressed}
                setClaimedPressed={setClaimedPressed}
                users={users}
              />
            )}
          </Modal>
        </>
      );
    }
  };

  return (
    <View
      style={{
        backgroundColor: "white",
        borderTopColor: "rgb(33, 150, 243)",
        borderTopWidth: 10,
        borderRadius: 10,
        margin: 20,
      }}
    >
      <Content>
        <Table
          pagination={false}
          rowKey="rowCategory"
          columns={columns}
          dataSource={uniqueArr}
          expandable={{
            expandedRowRender,
            onExpand: handleRowExpand,
            rowExpandable: (record) => record.data && record.data.length > 0,
          }}
          loading={tableLoading}
          locale={locale}
        />
        <DraggableModal
          footer={null}
          style={
            filteredData.some((item) => item.hasOwnProperty("active"))
              ? { minWidth: "40%", zIndex: 10 }
              : { minWidth: width < 1025 ? "90%" : "70%", zIndex: 10 }
          }
          bodyStyle={{ padding: 0 }}
          centered
          open={modalVisible}
          onOk={onOk}
          onCancel={onCancel}
          modalBody={
            selectedRow && (
              <TaskModal
                selectedRow={selectedRow}
                filteredData={filteredData}
                rowId={rowId}
                handleRefresh={handleRefresh}
                claimedPressed={claimedPressed}
                setClaimedPressed={setClaimedPressed}
                onDatePickerFocus={handleDatePickerFocus}
                onDatePickerBlur={handleDatePickerBlur}
                onMessageInputFocus={handleMessageInputFocus}
                onMessageInputBlur={handleMessageInputBlur}
                onActionDropdownFocus={handleActionDropdownFocus}
                onActionDropdownBlur={handleActionDropdownBlur}
                users={users}
                subTaskItemPressed={subTaskItemPressed}
                setSubTaskItemPressed={setSubTaskItemPressed}
              />
            )
          }
          disableDragging={[
            datePickerFocused,
            messageInputFocused,
            actionDropdownFocused,
          ]}
        />
      </Content>
    </View>
  );
}

export default CategoryTable;
