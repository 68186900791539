import TemplateList from "../components/TemplateListView";

const TemplateListPage = () => {
  return (
    <>
      <TemplateList />
    </>
  );
};

export default TemplateListPage;
