import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  Linking,
  ScrollView,
} from "react-native";
import { useEffect, useState, useRef } from "react";
import DeferButton from "./deferDateButton";
import Header from "./modalHeader";
import LineItem from "./lineItem";
import Moment from "react-moment";
import SubTasks from "./subtask";
import TaskAction from "../TaskAction";
import ToastConfig from "./toast";
import TaskMessages from "./TaskMessages";
import "./taskList.css";
import ProfileIconStack from "./profileIconStack";
import moment from "moment";
import ClaimedButton from "./claimedTasksButton";
import { Select } from "antd";
import { RFValue } from "react-native-responsive-fontsize";
import ajaxPut from "./ajaxPut";

const screen = Dimensions.get("screen");

function TaskModal({
  newDataForClaimedTasks,
  filteredData,
  rowId,
  handleRefresh,
  modalVisible,
  claimedPressed,
  setClaimedPressed,
  onDatePickerFocus,
  onDatePickerBlur,
  onMessageInputBlur,
  onMessageInputFocus,
  onActionDropdownFocus,
  onActionDropdownBlur,
  users,
  subTaskItemPressed,
  setSubTaskItemPressed,
}) {
  if (filteredData.length > 0) {
    const taskData = filteredData.find((item) => item.id === rowId);
    if (taskData !== undefined) {
      const [count, setCount] = useState(0);
      const [data, setData] = useState([]);
      const [deferDate, setDeferDate] = useState(null);
      const [deferrable, setDeferrable] = useState("");
      const [dimensions, setDimensions] = useState({ window, screen });
      const [dueDate, setDueDate] = useState(null);
      const [progress, setProgress] = useState(0);
      const [subTasks, setSubTasks] = useState([]);
      const [taskTagValue, setTaskTagValue] = useState([]);

      if (deferrable === "") {
        setDeferrable(taskData.deferrable);
      }
      if (
        taskData.defer_date &&
        taskData.defer_date !== null &&
        deferDate === null
      ) {
        setDeferDate(moment(taskData.defer_date).toDate());
      }

      if (taskData.due_date && taskData.due_date !== null && dueDate === null)
        setDueDate(moment(taskData.due_date).toDate());

      const tagOptions =
        taskData.tag_options && taskData.tag_options.length > 0
          ? taskData.tag_options.map((number) => ({
              key: number,
              value: number,
              label: number,
            }))
          : [];

      useEffect(() => {
        if (taskData.subtasks) {
          const addChecked = taskData.subtasks.map((obj) => ({ ...obj }));
          let emptyArr = [];
          for (let i = 0; i < addChecked.length; i++) {
            if (addChecked[i]["complete"] === 1) {
              addChecked[i]["complete"] = true;
              emptyArr.push(addChecked[i]["complete"]);
            } else {
              addChecked[i]["complete"] = false;
            }
          }
          setCount(emptyArr.length);
          if (taskData.subtasks.length > 0) {
            setProgress(emptyArr.length / taskData.subtasks.length);
          }
          setSubTasks(addChecked);
        }
      }, [taskData]);

      useEffect(() => {
        let tagArray = [];
        if (taskData.tags && taskData.tags.length > 0) {
          for (let i = 0; i < taskData.tags.length; i++) {
            tagArray.push(taskData.tags[i]["tag"]);
          }
        }
        setTaskTagValue(tagArray);
      }, [rowId]);

      const taskTagsDropdownOnChange = (value) => {
        setTaskTagValue(value);
        const taskId = rowId;
        const endpoint = `${global.urlBase}tasks/${taskId}/setTags`;
        const body = { tags: value };

        ajaxPut(endpoint, body)
          .then((data) => {
            handleRefresh; // Call getTasks here
          })
          .catch((error) => {
            console.error(error); // You should handle this error in a way that is appropriate for your application.
          });
      };

      const originalTaskData = useRef(null);

      useEffect(() => {
        originalTaskData.current = taskData;
      }, [taskData.id]);

      const [listOfPrimaryUsers, setListOfPrimaryUsers] = useState(undefined);
      const [listOfSecondaryUsers, setListOfSecondaryUsers] =
        useState(undefined);

      useEffect(() => {
        if (originalTaskData.current) {
          setListOfPrimaryUsers(
            originalTaskData.current.claimed_users?.Primary
          );
          setListOfSecondaryUsers(
            originalTaskData.current.claimed_users?.Secondary
          );
        }
      }, [originalTaskData.current]);

      useEffect(() => {
        if (listOfPrimaryUsers) {
          if (listOfPrimaryUsers.includes(global.userInfo.id)) {
            setClaimedPressed(true);
            return;
          }
          if (listOfSecondaryUsers) {
            if (listOfSecondaryUsers.includes(global.userInfo.id)) {
              setClaimedPressed(true);
              return;
            }
          }
          setClaimedPressed(false);
        }
      }, [listOfPrimaryUsers, listOfSecondaryUsers]);

      return (
        <View
          style={
            filteredData.some((item) => item.hasOwnProperty("active"))
              ? styles.lessModalContent
              : styles.modalContent
          }
        >
          <View style={styles.leftModalView}>
            <View
              style={
                filteredData.some((item) => item.hasOwnProperty("active"))
                  ? styles.smallerHeaderContainer
                  : styles.headerContainer
              }
            >
              <Header title={taskData.name} data={taskData} />
              {taskData.due_date && (
                <>
                  <View
                    style={{
                      borderWidth: 2,
                      borderColor: `#dcdcdc`,
                      height: RFValue(35, screen.height),
                    }}
                  />
                  <Header
                    title="Due Date"
                    body={
                      <Moment format="MMMM Do YYYY">{taskData.due_date}</Moment>
                    }
                    data={data}
                  />
                </>
              )}
              {taskData.status && (
                <>
                  <View
                    style={{
                      borderWidth: 2,
                      borderColor: `#dcdcdc`,
                      height: RFValue(35, screen.height),
                    }}
                  />
                  <Header
                    title="Status"
                    body={taskData.status != null && taskData.status.status}
                    data={taskData}
                  />
                </>
              )}
              {(taskData.claimed_users ||
                (taskData.claimed_users === null &&
                  taskData.user_id.length > 0)) && (
                <>
                  <View
                    style={{
                      borderWidth: 2,
                      borderColor: `#dcdcdc`,
                      height: RFValue(35, screen.height),
                      marginRight: 10,
                    }}
                  />
                  <ClaimedButton
                    taskData={taskData}
                    refresh={handleRefresh}
                    claimedPressed={claimedPressed}
                    setClaimedPressed={setClaimedPressed}
                    newDataForClaimedTasks={newDataForClaimedTasks}
                    modalVisible={modalVisible}
                    listOfPrimaryUsers={listOfPrimaryUsers}
                    setListOfPrimaryUsers={setListOfPrimaryUsers}
                    listOfSecondaryUsers={listOfSecondaryUsers}
                    setListOfSecondaryUsers={setListOfSecondaryUsers}
                  />
                </>
              )}
              {taskData.claimed_users && (
                <>
                  {listOfPrimaryUsers && listOfPrimaryUsers.length > 0 && (
                    <View style={{ marginLeft: 10 }}>
                      <ProfileIconStack
                        key={listOfPrimaryUsers}
                        listOfUsers={listOfPrimaryUsers}
                        marginRight={10}
                        data={taskData}
                        claimedPressed={claimedPressed}
                        userObject={users}
                      />
                    </View>
                  )}
                  {listOfSecondaryUsers && listOfSecondaryUsers.length > 0 && (
                    <ProfileIconStack
                      key={listOfSecondaryUsers}
                      listOfUsers={listOfSecondaryUsers}
                      inactiveAvatarColor={"rgba(0, 0, 0, 1)"}
                      data={taskData}
                      claimedPressed={claimedPressed}
                      marginLeft={
                        listOfPrimaryUsers.length === 0 ? 10 : undefined
                      }
                      userObject={users}
                    />
                  )}
                </>
              )}
            </View>
            <ScrollView
              style={[
                styles.lineItems,
                { height: RFValue(300, screen.height), margin: 15 },
              ]}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: RFValue(9, screen.height),
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Description
                </Text>
                <View
                  style={{
                    flex: 5,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      fontSize: RFValue(8, screen.height),
                    }}
                    dangerouslySetInnerHTML={{ __html: taskData.description }}
                  />
                </View>
              </View>
              <LineItem
                label="Link"
                body={
                  <Text
                    style={{ color: "blue", textDecorationLine: "underline" }}
                    adjustsFontSizeToFit={true}
                    onPress={() => {
                      Linking.openURL(taskData.link);
                    }}
                  >
                    {taskData.link_title}
                  </Text>
                }
                data={taskData}
              />
              {taskData.tag_options && taskData.tag_options.length > 0 && (
                <View
                  style={{
                    alignItems: "center",
                    flex: 1,
                    flexDirection: "row",
                    marginTop: RFValue(7, screen.height),
                  }}
                >
                  <Text
                    style={{
                      flex: 1,
                      fontSize: RFValue(9, screen.height),
                      fontWeight: "bold",
                    }}
                  >
                    Task Tags
                  </Text>
                  <View style={{ flex: 5 }}>
                    <Select
                      mode="multiple"
                      options={tagOptions}
                      placeholder="Select a tag"
                      style={{ width: 200 }}
                      onChange={taskTagsDropdownOnChange}
                      value={taskTagValue}
                    />
                  </View>
                </View>
              )}
              {taskData.deferrable === 1 && (
                <View style={{ marginTop: RFValue(7, screen.height) }}>
                  <DeferButton
                    taskId={taskData.id}
                    label="Defer Date"
                    deferrable={deferrable}
                    dueDate={dueDate}
                    setDueDate={setDueDate}
                    deferDate={deferDate}
                    setDeferDate={setDeferDate}
                    data={taskData}
                    onDatePickerFocus={onDatePickerFocus}
                    onDatePickerBlur={onDatePickerBlur}
                  />
                </View>
              )}
            </ScrollView>
            <ToastConfig
              successMessage="Data has been updated"
              errorMessage="Data has not been updated"
            />
            {taskData.subtasks && (
              <View>
                <View style={styles.subTaskContainer}>
                  <SubTasks
                    taskData={taskData}
                    taskId={taskData.id}
                    subTasks={subTasks}
                    setSubTasks={setSubTasks}
                    count={count}
                    setCount={setCount}
                    progress={progress}
                    setProgress={setProgress}
                    handleRefresh={handleRefresh}
                    modalVisible={modalVisible}
                    subTaskItemPressed={subTaskItemPressed}
                    setSubTaskItemPressed={setSubTaskItemPressed}              
                  />
                </View>
              </View>
            )}
          </View>
          {taskData.messages && (
            <View style={styles.rightModalView}>
              <View style={styles.messagesAndHistoryContainer}>
                <TaskMessages
                  onMessageInputFocus={onMessageInputFocus}
                  onMessageInputBlur={onMessageInputBlur}
                  taskId={taskData.id}
                />
              </View>
              <View style={{ flex: 1 }}>
                <View style={{ flex: 1 }}>
                  <TaskAction
                    taskId={rowId}
                    refresh={handleRefresh}
                    modalVisible={modalVisible}
                    onActionDropdownBlur={onActionDropdownBlur}
                    onActionDropdownFocus={onActionDropdownFocus}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: "center",
    borderBottomWidth: 4,
    borderColor: `#dcdcdc`,
    flex: 1,
    flexDirection: "row",
    padding: 15,
    paddingTop: 20,
  },
  smallerHeaderContainer: {
    alignItems: "center",
    borderBottomWidth: 4,
    borderColor: `#dcdcdc`,
    flex: 0.25,
    flexDirection: "row",
    padding: 15,
    paddingTop: 20,
  },
  leftModalView: {
    flex: 3,
  },
  lineItems: {
    flex: 1,
    zIndex: 100,
    padding: 15,
  },
  messagesAndHistoryContainer: {
    flex: 3,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    zIndex: 2,
  },
  modalContent: {
    flex: 1,
    flexDirection: "row",
    minHeight: 400,
    maxHeight: "100%",
  },
  lessModalContent: {
    flex: 1,
    flexDirection: "row",
    minHeight: 250,
    maxHeight: "100%",
  },

  rightModalView: {
    borderLeftWidth: 4,
    borderColor: `#dcdcdc`,
    flex: 1,
    padding: 15,
  },
  subTaskContainer: {
    flex: 1.5,
    padding: 15,
    borderTopWidth: 4,
    borderColor: `#dcdcdc`,
  },
});

export default TaskModal;
