import "./taskList.css";
import { useState, useEffect } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { View } from "react-native";

function CategoryModal({ visible, setVisible, rowId, categoriesData }) {
  const [form] = Form.useForm();
  const [categoryName, setCategoryName] = useState("");

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (rowId !== undefined) {
      const category = categoriesData.find((data) => data.id === rowId);
      form.setFieldsValue({
        categoryName: category.name,
      });
      setCategoryName(category.name);
    }
  }, [rowId]);

  const createNewCategory = async () => {
    var body = {
      name: categoryName,
    };
    try {
      const response = await fetch(global.urlBase + "categories", {
        headers: new Headers({
          Authorization: "Bearer " + global.token,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(body),
      });
      if (response.status === 201) {
        notification.success({
          message: "Success!",
          description: "Category successfully created.",
          duration: 2,
          onClose: () => {
            location.reload();
          },
        });
      } else {
        const error = await response.json();
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "There was an error creating the category",
      });
    }
  };

  const updateCategory = async () => {
    var body = {
      name: categoryName,
    };
    try {
      const response = await fetch(global.urlBase + "categories/" + rowId, {
        headers: new Headers({
          Authorization: "Bearer " + global.token,
          "Content-Type": "application/json",
        }),
        method: "PUT",
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        notification.success({
          message: "Success!",
          description: "Your changes have been saved.",
          duration: 2,
          onClose: () => {
            location.reload();
          },
        });
      } else {
        const error = await response.json();
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "There was an error updating the category",
      });
    }
  };

  return (
    <>
      <Modal
        className="custom-modal"
        open={visible}
        footer={false}
        title={"Category View"}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          style={{ paddingBottom: 20 }}
          layout="vertical"
          preserve={false}
        >
          <Form.Item
            label="Category Name:"
            name="categoryName"
            rules={[
              {
                required: true,
                message: "Please input the category name",
              },
            ]}
          >
            <Input
              maxLength={255}
              placeholder="Enter category name"
              onChange={(e) => {
                setCategoryName(e.target.value);
              }}
              value={categoryName}
              showCount
            />
          </Form.Item>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              onClick={async () => {
                try {
                  await form.validateFields(["categoryName"]);
                  {
                    rowId === undefined
                      ? createNewCategory()
                      : updateCategory();
                  }
                } catch (error) {
                  const errorMessages = [
                    ...new Set(
                      error.errorFields.map((fieldError) => fieldError.name[0])
                    ),
                  ];

                  const splitErrorMessages = errorMessages.map((errorMessage) =>
                    errorMessage.split(/(?=[A-Z])/).join(" ")
                  );

                  const capitalizedErrorMessages = splitErrorMessages.map(
                    (errorMessage) =>
                      errorMessage.charAt(0).toUpperCase() +
                      errorMessage.slice(1)
                  );

                  notification.error({
                    duration: null,
                    message: "Invalid Form",
                    description:
                      "The following fields are invalid: " +
                      capitalizedErrorMessages.join(", "),
                  });
                }
              }}
            >
              Submit
            </Button>
          </View>
        </Form>
      </Modal>
    </>
  );
}

export default CategoryModal;
