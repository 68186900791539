import "./taskList.css";
import { useState, useRef, useEffect } from "react";
import ajaxPut from "./ajaxPut";
import {
  Form,
  Carousel,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Tag,
  notification,
  Checkbox,
  Row,
  Col,
  Table,
} from "antd";
import {
  View,
  Dimensions,
  SafeAreaView,
  Text,
  TouchableOpacity,
} from "react-native";
import dayjs from "dayjs";
import moment from "moment";
import DraggableModal from "./draggableModal";

function TemplateModal({
  visible,
  setVisible,
  categories,
  statuses,
  users,
  rowId,
  templateData,
  templateType,
  taskTemplates,
}) {
  const windowWidth = Dimensions.get("window");
  const { Group } = Checkbox;
  const [form] = Form.useForm();
  const ref = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [initialStatusButton, setInitialStatusButton] = useState(false);
  const [categoryButton, setCategoryButton] = useState(false);
  const [taskTemplateTypeButton, setTaskTemplateTypeButton] = useState(false);
  const [userButton, setUserButton] = useState(false);
  const [dueDateButton, setDueDateButton] = useState(false);
  const [triggerTypeButton, setTriggerTypeButton] = useState(false);
  const [deferDateButton, setDeferDateButton] = useState(false);
  const [pastDueActionButton, setPastDueActionButton] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);

  // Page 1
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [scheduledTemplateName, setScheduledTemplateName] = useState("");
  const [scheduledTemplateDescription, setScheduledTemplateDescription] =
    useState("");

  // Page 2
  const [taskTemplate, setTaskTemplate] = useState(undefined);
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [initialStatus, setInitialStatus] = useState(undefined);
  const [category, setCategory] = useState(undefined);
  const [priority, setPriority] = useState(undefined);
  const [user, setUser] = useState(undefined);

  // Page 3
  const [dueDate, setDueDate] = useState(undefined);
  const [triggerType, setTriggerType] = useState(undefined);
  const [parameters, setParameters] = useState([]);
  const [updatedParameters, setUpdatedParameters] = useState(undefined);
  const [triggerDefinition, setTriggerDefinition] = useState(undefined);
  const [nthWeekdaysWeek, setNthWeekdaysWeek] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [deferDate, setDeferDate] = useState(undefined);
  const [deferrable, setDeferrable] = useState(undefined);
  const [missable, setMissable] = useState(undefined);
  const [pastDueAction, setPastDueAction] = useState(0);

  // Page 4
  const [link, setLink] = useState(undefined);
  const [linkTitle, setLinkTitle] = useState(undefined);
  const [tagOptions, setTagOptions] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [referenceType, setReferenceType] = useState(undefined);
  const [sourceType, setSourceType] = useState(undefined);
  const [sourceParameters, setSourceParameters] = useState([]);

  useEffect(() => {
    if (rowId !== undefined) {
      const template = templateData.find((data) => data.id === rowId);
      form.setFieldsValue({
        templateName:
          templateType !== "scheduledTemplate"
            ? template.templateName
            : template.task_template.templateName,
        scheduledTemplateName: template.name,
        templateDescription:
          templateType !== "scheduledTemplate"
            ? template.templateDescription
            : template.task_template.templateDescription,
        scheduledTemplateDescription: template.description,
        taskName:
          templateType === "scheduledTemplate"
            ? template.task_template.templateName
            : template.name,
        taskDescription:
          templateType === "scheduledTemplate"
            ? template.task_template.templateDescription
            : template.description,
        priority: template.priority,
        deferrable: template.deferrable,
        missable:
          templateType !== "scheduledTemplate"
            ? template.missable
            : template.task_template.missable,
        link: templateType !== "scheduledTemplate" ? template.link : undefined,
        linkTitle:
          templateType !== "scheduledTemplate"
            ? template.link_title
            : template.task_template.link_title,
        tags: template.tag_options,
        referenceType: template.ref_type,
        sourceType: template.source_type,
        sourceParameters: template.source_parameters,
        triggerType: template.trigger_type,
        parameters: template.params,
        triggerDefinition:
          template.trigger_definition !== null && template.trigger_definition,
      });
      setTemplateName(
        templateType !== "scheduledTemplate"
          ? template.templateName
          : template.task_template.templateName
      );
      setScheduledTemplateName(template.name);
      setTemplateDescription(
        templateType !== "scheduledTemplate"
          ? template.templateDescription
          : template.task_template.description
      );
      setScheduledTemplateDescription(template.description);
      setTaskName(
        templateType === "scheduledTemplate"
          ? template.task_template.templateName
          : template.name
      );
      setTaskDescription(
        templateType === "scheduledTemplate"
          ? template.task_template.templateDescription
          : template.description
      );
      setInitialStatus(
        templateType !== "scheduledTemplate"
          ? template.status_id
          : template.task_template.status_id
      );
      setPriority(template.priority);
      setTaskTemplate(
        templateType === "scheduledTemplate"
          ? parseInt(template.task_template.id)
          : undefined
      );
      setCategory(
        templateType === "scheduledTemplate"
          ? template.task_template.category_id
          : template.category_id
      );
      setDeferrable(template.deferrable);
      setMissable(
        templateType !== "scheduledTemplate"
          ? template.missable
          : template.task_template.missable
      );
      setUser(
        templateType !== "scheduledTemplate"
          ? template.user_id
          : template.task_template.user_id
      );
      setDueDate(
        templateType !== "scheduledTemplate"
          ? template.due_date
          : template.task_template.due_date
      );
      setTriggerType(template.trigger_type);
      setParameters(
        template.params && Object.keys(template.params).length > 0
          ? Object.keys(template.params).map((key, index) => {
              return {
                index: index,
                key: key,
                value: template.params[key],
              };
            })
          : [{ index: 0, key: "", value: "" }]
      );
      setTriggerDefinition(
        template.trigger_definition && template.trigger_definition.days
      );
      setSelectedDays(
        template.trigger_definition && template.trigger_definition.days
      );
      setNthWeekdaysWeek(
        template.trigger_definition && template.trigger_definition.weeks
      );
      setDeferDate(template.defer_date);
      setPastDueAction(template.past_due_action_id);
      setLink(
        templateType !== "scheduledTemplate"
          ? template.link
          : template.task_template.link
      );
      setLinkTitle(
        templateType !== "scheduledTemplate"
          ? template.link_title
          : template.task_template.link_title
      );
      setTagOptions(template.tag_options ? template.tag_options : []);
      setReferenceType(
        templateType !== "scheduledTemplate"
          ? template.ref_type
          : template.task_template.ref_type
      );
      setSourceType(template.source_type);
      setSourceParameters(
        template.source_parameters &&
          Object.keys(template.source_parameters).length > 0
          ? Object.keys(template.source_parameters).map((key, index) => {
              return {
                index: index,
                key: key,
                value: template.source_parameters[key],
              };
            })
          : [{ index: 0, key: "", value: "" }]
      );

      if (
        isNaN(
          parseInt(
            templateType !== "scheduledTemplate"
              ? template.status_id
              : template.task_template.status_id
          )
        )
      ) {
        setInitialStatusButton(true);
        form.setFieldsValue({
          initialStatus:
            templateType !== "scheduledTemplate"
              ? template.status_id
              : template.task_template.status_id,
        });
      } else {
        setInitialStatusButton(false);
        form.setFieldsValue({
          initialStatus: parseInt(
            templateType !== "scheduledTemplate"
              ? template.status_id
              : template.task_template.status_id
          ),
        });
      }
      if (
        (templateType === "scheduledTemplate"
          ? template.task_template.category_id
          : template.category_id) &&
        (templateType === "scheduledTemplate"
          ? isNaN(parseInt(template.task_template.category_id))
          : isNaN(parseInt(template.category_id)))
      ) {
        setCategoryButton(true);
        form.setFieldsValue({
          category:
            templateType === "scheduledTemplate"
              ? template.task_template.category_id
              : template.category_id,
        });
      } else {
        setCategoryButton(false);
        if (
          templateType === "scheduledTemplate"
            ? !template.task_template.category_id
            : !template.category_id
        ) {
          form.setFieldsValue({ category: undefined });
        } else {
          form.setFieldsValue({
            category: parseInt(
              templateType === "scheduledTemplate"
                ? template.task_template.category_id
                : template.category_id
            ),
          });
        }
      }
      if (
        templateType === "scheduledTemplate" &&
        template.task_template.id &&
        isNaN(parseInt(template.task_template.id))
      ) {
        setTaskTemplateTypeButton(true);
        form.setFieldsValue({
          taskTemplate: parseInt(template.task_template.id),
        });
      } else {
        setTaskTemplateTypeButton(false);
        if (!template.id) {
          form.setFieldsValue({ taskTemplate: undefined });
        } else {
          form.setFieldsValue({
            taskTemplate: parseInt(
              templateType !== "scheduledTemplate"
                ? template.id
                : template.task_template.id
            ),
          });
        }
        const userId =
          templateType !== "scheduledTemplate"
            ? template.user_id
            : template.task_template.user_id;
        if (userId && isNaN(parseInt(userId))) {
          setUserButton(true);
          form.setFieldsValue({
            user: userId,
          });
        } else {
          setUserButton(false);
          if (!userId) {
            form.setFieldsValue({ user: undefined });
          } else {
            form.setFieldsValue({ user: parseInt(userId) });
          }
        }
        const date =
          templateType !== "scheduledTemplate"
            ? template.due_date
            : template.task_template.due_date;
        if (date && isNaN(parseInt(date))) {
          setDueDateButton(true);
          form.setFieldsValue({
            dueDate: date,
          });
        } else {
          setDueDateButton(false);
          if (!date) {
            form.setFieldsValue({ dueDate: undefined });
          } else {
            form.setFieldsValue({ dueDate: moment(date) });
          }
        }

        if (template.defer_date && isNaN(parseInt(template.defer_date))) {
          setDeferDateButton(true);
          form.setFieldsValue({
            deferDate: template.defer_date,
          });
        } else {
          setDeferDateButton(false);
          if (!template.defer_date) {
            form.setFieldsValue({ deferDate: undefined });
          } else {
            form.setFieldsValue({ deferDate: moment(template.defer_date) });
          }
        }

        if (
          template.past_due_action_id &&
          isNaN(parseInt(template.past_due_action_id))
        ) {
          setPastDueActionButton(true);
          form.setFieldsValue({
            pastDueAction: template.past_due_action_id,
          });
        } else {
          setPastDueActionButton(false);
          if (!template.past_due_action_id) {
            form.setFieldsValue({ pastDueAction: undefined });
          } else {
            form.setFieldsValue({
              pastDueAction: parseInt(template.past_due_action_id),
            });
          }
        }
      }
    }
  }, [rowId]);

  const goTo = (slide) => {
    ref.current.goTo(slide, false);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const goToFirstSlide = () => {
    if (ref.current) {
      ref.current.goTo(0, false);
    }
  };

  useEffect(() => {
    goToFirstSlide();
  }, [visible]);

  const handleEnter = (value) => {
    if (!value) return;
    setTagOptions([...tagOptions, value]);
    setTagInputValue("");
  };

  const handleClose = (removedTag) => {
    setTagOptions(tagOptions.filter((tag) => tag !== removedTag));
  };

  const getTitle = () => {
    switch (currentSlide) {
      case 0:
        return templateType === "scheduledTemplate"
          ? "Scheduled Template Details"
          : "Template Details";
      case 1:
        return templateType === "scheduledTemplate"
          ? "Task Template Details"
          : "Task Details";
      case 2:
        return templateType === "scheduledTemplate"
          ? "Scheduled Template Timing"
          : "Task Timing";
      case 3:
        return "Other Details";
      default:
        return "Template Name";
    }
  };

  const priorityOptions = [
    { label: "1 (Highest)", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10 (Lowest)", value: "10" },
  ];

  const daysOfWeek = [
    { label: "Sunday", value: "0" },
    { label: "Monday", value: "1" },
    { label: "Tuesday", value: "2" },
    { label: "Wednesday", value: "3" },
    { label: "Thursday", value: "4" },
    { label: "Friday", value: "5" },
    { label: "Saturday", value: "6" },
  ];

  const weeks = [
    { label: "Week 1", value: "1" },
    { label: "Week 2", value: "2" },
    { label: "Week 3", value: "3" },
    { label: "Week 4", value: "4" },
    { label: "Week 5", value: "5" },
  ];

  const WeekPicker = ({ style }) => {
    return (
      <Select
        onChange={(value) => {
          setNthWeekdaysWeek(value);
        }}
        value={nthWeekdaysWeek ? nthWeekdaysWeek : []}
        style={style}
        placeholder="Select a week"
        options={weeks}
        mode="multiple"
        autoClearSearchValue={false}
      ></Select>
    );
  };

  const DaysOfWeekPicker = () => {
    return (
      <Group
        value={triggerDefinition}
        onChange={(checkedValues) => {
          setTriggerDefinition(checkedValues);
        }}
      >
        <Row gutter={windowWidth.width <= 1050 ? [5, 16] : [109, 16]}>
          {daysOfWeek.map((day) => (
            <Col key={day.value} xs={24} sm={12} md={12} lg={4} xl={4}>
              <Checkbox value={day.value}>{day.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Group>
    );
  };

  const DayOfMonthSelector = () => {
    const onDayPress = (day) => {
      const dayString = day.toString();
      if (selectedDays.includes(dayString)) {
        setSelectedDays(
          selectedDays.filter((selectedDay) => selectedDay !== dayString)
        );
      } else {
        setSelectedDays([...selectedDays, dayString]);
      }
    };

    const generateCells = () => {
      let cells = [];
      let count = 1;
      for (let row = 0; row < 5; row++) {
        let rowItems = [];
        for (let col = 0; col < 7; col++) {
          const day = count <= 31 ? count : null;
          const dayString = day && day.toString();
          rowItems.push(
            <TouchableOpacity
              key={`col-${col}`}
              onPress={() => day && onDayPress(day)}
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: 40,
                height: 40,
                borderWidth: 1,
                borderColor: "lightgrey",
                backgroundColor: selectedDays.includes(dayString)
                  ? "#1677FF"
                  : "transparent",
                borderTopLeftRadius: row === 0 && col === 0 ? 10 : 0,
                borderTopRightRadius: row === 0 && col === 6 ? 10 : 0,
                borderBottomLeftRadius: row === 4 && col === 0 ? 10 : 0,
                borderBottomRightRadius: row === 4 && col === 6 ? 10 : 0,
              }}
            >
              {day && (
                <Text
                  style={{
                    fontSize: 16,
                    color: selectedDays.includes(dayString) ? "white" : "black",
                  }}
                >
                  {count}
                </Text>
              )}
            </TouchableOpacity>
          );
          count++;
        }
        cells.push(
          <View key={`row-${row}`} style={{ flexDirection: "row" }}>
            {rowItems}
          </View>
        );
      }
      return cells;
    };

    return (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "lightgrey",
          }}
        >
          {generateCells()}
        </View>
      </SafeAreaView>
    );
  };

  const triggerTypeOptions = [
    { label: "Day(s) of the Week", value: "dow" },
    { label: "Day(s) of the Month", value: "dom" },
    { label: "Last Day of Month", value: "ldom" },
    { label: "Last Business Day of Month", value: "lbdom" },
    { label: "Nth Weekday(s) of the Month", value: "nthWeekdays" },
    { label: "Last Businesss Day of Quarter", value: "lbdoq" },
    { label: "First Business Day of Quarter", value: "fbdoq" },
    { label: "Biannual", value: "biannual" },
    { label: "30 Days Before End of Quarter", value: "30DaysBeforeEOQ" },
  ];

  const TriggerTypeDropdown = () => {
    return (
      <>
        <Select
          style={{ backgroundColor: "white" }}
          options={triggerTypeOptions}
          placeholder="Select trigger type"
          value={form.getFieldValue("triggerType")}
          onChange={(value) => {
            form.setFieldsValue({ triggerType: value });
            setTriggerType(value.toString());
          }}
        />
      </>
    );
  };

  const removeEmptyStringKeys = (obj) => {
    if (obj && obj.hasOwnProperty("")) {
      const newObj = { ...obj };
      delete newObj[""];
      return newObj;
    }
    return obj;
  };

  const KeyInput = ({ record, sourceParameters, setSourceParameters }) => {
    const [inputValue, setInputValue] = useState(record.key);

    return (
      <Input
        value={inputValue}
        placeholder="Key"
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={(e) => {
          const newKey = e.target.value;
          const updatedParameters = [...sourceParameters];
          updatedParameters[record.index] = {
            index: record.index,
            key: newKey,
            value: record.value,
          };
          setSourceParameters(updatedParameters);
        }}
      />
    );
  };



  const createNewTemplate = async () => {
    const sourceParametersObject = sourceParameters.reduce((acc, cur) => {
      acc[cur.key.trim()] = cur.value.trim();
      return acc;
    }, {});
    const parametersObject = parameters.reduce((acc, cur) => {
      acc[cur.key.trim()] = cur.value.trim();
      return acc;
    }, {});


    const cleanedParameters = removeEmptyStringKeys(parametersObject);
    const cleanedSourceParameters = removeEmptyStringKeys(sourceParametersObject);

    if (cleanedParameters) {
      for (let key in cleanedParameters) {
        if (cleanedParameters.hasOwnProperty(key)) {
          const trimmedKey = key.trim();
          const trimmedValue = cleanedParameters[key].trim();
          delete cleanedParameters[key];
          cleanedParameters[trimmedKey] = trimmedValue;
        }
      }
    }

    if (cleanedSourceParameters) {
      for (let key in cleanedSourceParameters) {
        if (cleanedSourceParameters.hasOwnProperty(key)) {
          const trimmedKey = key.trim();
          const trimmedValue = cleanedSourceParameters[key].trim();
          delete cleanedSourceParameters[key];
          cleanedSourceParameters[trimmedKey] = trimmedValue;
        }
      }
    }

    var body =
      templateType !== "scheduledTemplate"
        ? {
            templateName: templateName,
            templateDescription: templateDescription,
            name: taskName,
            description: taskDescription,
            status_id: initialStatus,
            category_id: JSON.stringify(category),
            priority: priority,
            user_id: user,
            due_date: dueDate,
            deferrable: deferrable,
            defer_date: deferDate,
            missable: missable,
            past_due_action_id: pastDueAction,
            link: link,
            link_title: linkTitle,
            tag_options: tagOptions.length > 0 ? tagOptions : [],
            ref_type: referenceType,
          }
        : {
            name: scheduledTemplateName,
            templateDescription: templateDescription,
            template_id: taskTemplate,
            description: scheduledTemplateDescription,
            category: category,
            priority: JSON.stringify(priority),
            trigger_type: triggerType,
            trigger_definition:
              triggerType === "dom"
                ? { days: selectedDays }
                : triggerType === "dow"
                ? { days: triggerDefinition }
                : triggerType === "nthWeekdays"
                ? { weeks: nthWeekdaysWeek, days: triggerDefinition }
                : undefined,
            params: cleanedParameters,
            source_type: sourceType,
            source_parameters: cleanedSourceParameters,
            owner_id: global.userInfo.id,
          };

    const url =
      templateType !== "scheduledTemplate"
        ? "taskTemplate"
        : "scheduledTemplates";
    const completeUrl = global.urlBase + url;
    try {
      const response = await fetch(completeUrl, {
        headers: new Headers({
          Authorization: "Bearer " + global.token,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(body),
      });
      if (response.status === 201) {
        notification.success({
          message: "Success!",
          description: "Template successfully created.",
          duration: 2,
          onClose: () => {
            location.reload();
          },
        });
      } else {
        const error = await response.json();
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "There was an error creating the template",
      });
    }
  };

  const updateTemplate = async () => {
    const sourceParametersObject = sourceParameters.reduce((acc, cur) => {
      acc[cur.key.trim()] = cur.value.trim();
      return acc;
    }, {});
    const parametersObject = parameters.reduce((acc, cur) => {
      acc[cur.key.trim()] = cur.value.trim();
      return acc;
    }, {});


    const cleanedParameters = removeEmptyStringKeys(parametersObject);
    const cleanedSourceParameters = removeEmptyStringKeys(sourceParametersObject);

    if (cleanedParameters) {
      for (let key in cleanedParameters) {
        if (cleanedParameters.hasOwnProperty(key)) {
          const trimmedKey = key.trim();
          const trimmedValue = cleanedParameters[key].trim();
          delete cleanedParameters[key];
          cleanedParameters[trimmedKey] = trimmedValue;
        }
      }
    }

    if (cleanedSourceParameters) {
      for (let key in cleanedSourceParameters) {
        if (cleanedSourceParameters.hasOwnProperty(key)) {
          const trimmedKey = key.trim();
          const trimmedValue = cleanedSourceParameters[key].trim();
          delete cleanedSourceParameters[key];
          cleanedSourceParameters[trimmedKey] = trimmedValue;
        }
      }
    }

    const url =
      templateType !== "scheduledTemplate"
        ? "taskTemplate"
        : "scheduledTemplates";
    var body =
      templateType !== "scheduledTemplate"
        ? {
            templateName: templateName,
            templateDescription: templateDescription,
            name: taskName,
            description: taskDescription,
            status_id: initialStatus,
            category_id: JSON.stringify(category),
            priority: priority,
            user_id: user,
            due_date: dueDate,
            deferrable: deferrable,
            defer_date: deferDate ? deferDate : undefined,
            missable: missable,
            past_due_action_id: pastDueAction,
            link: link ? link : undefined,
            link_title: linkTitle ? linkTitle : undefined,
            tag_options: tagOptions.length > 0 ? tagOptions : [],
            ref_type: referenceType ? referenceType : undefined,
          }
        : {
            name: scheduledTemplateName,
            templateDescription: templateDescription,
            template_id: taskTemplate,
            description: scheduledTemplateDescription,
            category: category,
            priority: JSON.stringify(priority),
            trigger_type: triggerType,
            trigger_definition:
              triggerType === "dom"
                ? { days: selectedDays }
                : triggerType === "dow"
                ? { days: triggerDefinition }
                : triggerType === "nthWeekdays"
                ? { weeks: nthWeekdaysWeek, days: triggerDefinition }
                : undefined,
            params: cleanedParameters,
            source_type: sourceType,
            source_parameters: cleanedSourceParameters,
            owner_id: global.userInfo.id,
          };
    try {
      const response = await ajaxPut(global.urlBase + url + "/" + rowId, body);
      if (response) {
        notification.success({
          message: "Success!",
          description: "Your changes have been saved.",
          duration: 2,
          onClose: () => {
            location.reload();
          },
        });
      } else {
        const error = await response.json();
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "There was an error updating the template",
      });
    }
  };

  return (
    <>
      <DraggableModal
        shouldDisableDrag={true}
        className="custom-modal"
        open={visible}
        footer={false}
        title={getTitle()}
        onCancel={handleCancel}
        modalBody={
          <Carousel
            ref={ref}
            afterChange={(current) => {
              setCurrentSlide(current);
            }}
            speed={0}
            adaptiveHeight={true}
            dots={templateType === "scheduledTemplate" ? false : true}
          >
            <div>
              <Form
                form={form}
                style={{ paddingBottom: 20 }}
                layout="vertical"
                preserve={false}
              >
                {templateType !== "scheduledTemplate" && (
                  <>
                    <Form.Item
                      label="Template Name:"
                      name="templateName"
                      rules={[
                        {
                          required: true,
                          message: "Please input the template name",
                        },
                      ]}
                    >
                      <Input
                        maxLength={255}
                        placeholder="Enter template name"
                        onChange={(e) => {
                          setTemplateName(e.target.value);
                        }}
                        value={templateName}
                        showCount
                      />
                    </Form.Item>
                    <Form.Item
                      label="Template Description:"
                      name="templateDescription"
                      rules={[
                        {
                          required: true,
                          message: "Please input the template description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Enter template description"
                        rows={4}
                        maxLength={255}
                        onChange={(e) => {
                          setTemplateDescription(e.target.value);
                        }}
                        value={templateDescription}
                        showCount
                      />
                    </Form.Item>
                  </>
                )}
                {templateType === "scheduledTemplate" && (
                  <>
                    <Form.Item
                      label="Scheduled Template Name:"
                      name="scheduledTemplateName"
                      rules={[
                        {
                          required: true,
                          message: "Please input the scheduled template name",
                        },
                      ]}
                    >
                      <Input
                        maxLength={255}
                        placeholder="Enter scheduled template name"
                        onChange={(e) => {
                          setScheduledTemplateName(e.target.value);
                        }}
                        value={scheduledTemplateName}
                        showCount
                      />
                    </Form.Item>
                    <Form.Item
                      label="Scheduled Template Description:"
                      name="scheduledTemplateDescription"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the scheduled template description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Enter scheduled template description"
                        rows={4}
                        maxLength={255}
                        onChange={(e) => {
                          setScheduledTemplateDescription(e.target.value);
                        }}
                        value={scheduledTemplateDescription}
                        showCount
                      />
                    </Form.Item>
                    {templateType === "scheduledTemplate" && (
                      <Form.Item
                        label="Task Template Name:"
                        name="taskTemplate"
                        rules={[
                          {
                            required: true,
                            message: "Please provide the task template name",
                          },
                        ]}
                      >
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          {!taskTemplateTypeButton && (
                            <Select
                              placeholder="Select task template Name"
                              style={{ maxWidth: 275 }}
                              value={form.getFieldValue("taskTemplate")}
                              onChange={(value) => {
                                form.setFieldsValue({ taskTemplate: value });
                                setTaskTemplate(value.toString());
                              }}
                            >
                              {taskTemplates.map((template) => (
                                <Select.Option
                                  key={template.id}
                                  value={template.id}
                                >
                                  {template.templateName}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                          {taskTemplateTypeButton && (
                            <Input
                              maxLength={255}
                              showCount
                              placeholder="Enter task template name"
                              value={form.getFieldValue("taskTemplate")}
                              onChange={(e) => {
                                form.setFieldsValue({
                                  taskTemplate: e.target.value,
                                });
                                setTaskTemplate(e.target.value);
                              }}
                            />
                          )}
                          <View style={{ flex: 1 }}></View>
                          <Button
                            onClick={() => {
                              form.setFieldsValue({ taskTemplate: undefined });
                              setTaskTemplateTypeButton(
                                !taskTemplateTypeButton
                              );
                              setTaskTemplate("");
                            }}
                            style={{ marginLeft: 5 }}
                            type="primary"
                          >
                            {taskTemplateTypeButton
                              ? "Show task template dropdown"
                              : "Template Field"}
                          </Button>
                        </View>
                      </Form.Item>
                    )}
                  </>
                )}

                <View style={{ alignItems: "flex-end" }}>
                  <Button
                    type="primary"
                    onClick={async () => {
                      try {
                        await form.validateFields(
                          templateType !== "scheduledTemplate"
                            ? ["templateDescription", "templateName"]
                            : [
                                "templateDescription",
                                "templateName",
                                "scheduledTemplateName",
                                "scheduledTemplateDescription",
                                "taskTemplate",
                              ]
                        );
                        goTo(templateType === "scheduledTemplate" ? 3 : 1);
                        setCurrentSlide(
                          templateType === "scheduledTemplate" ? 3 : 1
                        );
                      } catch (error) {
                        // console.log(error);
                      }
                    }}
                  >
                    Next
                  </Button>
                </View>
              </Form>
            </div>
            <div>
              <Form style={{ paddingBottom: 20 }} layout="vertical" form={form}>
                {templateType !== "scheduledTemplate" && (
                  <>
                    <Form.Item
                      label={
                        templateType === "scheduledTemplate"
                          ? "Task Template Name:"
                          : "Task Name:"
                      }
                      name="taskName"
                      rules={[
                        {
                          required: true,
                          message: "Please input the task name",
                        },
                      ]}
                    >
                      <Input
                        maxLength={255}
                        placeholder="Enter task name"
                        onChange={(e) => {
                          setTaskName(e.target.value);
                        }}
                        value={taskName}
                        showCount
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        templateType === "scheduledTemplate"
                          ? "Task Template Description:"
                          : "Task Description:"
                      }
                      name="taskDescription"
                      rules={[
                        {
                          required: true,
                          message: "Please input the task description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Enter task description"
                        rows={4}
                        maxLength={512}
                        onChange={(e) => {
                          setTaskDescription(e.target.value);
                        }}
                        value={taskDescription}
                        showCount
                      />
                    </Form.Item>
                    <Form.Item
                      label="Initial Status:"
                      name="initialStatus"
                      rules={[
                        {
                          required: true,
                          message: "Please provide the initial status",
                        },
                      ]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {!initialStatusButton && (
                          <Select
                            placeholder="Select initial status"
                            value={form.getFieldValue("initialStatus")}
                            onChange={(value) => {
                              form.setFieldsValue({ initialStatus: value });
                              setInitialStatus(value.toString());
                            }}
                          >
                            {statuses.map((status) => (
                              <Select.Option key={status.id} value={status.id}>
                                {status.status}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {initialStatusButton && (
                          <Input
                            maxLength={255}
                            showCount
                            placeholder="Enter initial status"
                            value={form.getFieldValue("initialStatus")}
                            onChange={(e) => {
                              form.setFieldsValue({
                                initialStatus: e.target.value,
                              });
                              setInitialStatus(e.target.value);
                            }}
                          />
                        )}
                        <Button
                          onClick={() => {
                            form.setFieldsValue({ initialStatus: undefined });
                            setInitialStatusButton(!initialStatusButton);
                            setInitialStatus("");
                          }}
                          style={{ marginLeft: 5 }}
                          type="primary"
                        >
                          {initialStatusButton
                            ? "Show initial status dropdown"
                            : "Template Field"}
                        </Button>
                      </View>
                    </Form.Item>
                    <Form.Item
                      label="Category:"
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Please provide the category",
                        },
                      ]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {!categoryButton && (
                          <Select
                            placeholder="Select category"
                            value={form.getFieldValue("category")}
                            onChange={(value) => {
                              form.setFieldsValue({ category: value });
                              setCategory(value);
                            }}
                          >
                            {categories.map((category) => (
                              <Select.Option
                                key={category.id}
                                value={category.id}
                              >
                                {category.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {categoryButton && (
                          <Input
                            maxLength={255}
                            showCount
                            placeholder="Enter category name"
                            value={form.getFieldValue("category")}
                            onChange={(e) => {
                              form.setFieldsValue({ category: e.target.value });
                              setCategory(e.target.value);
                            }}
                          />
                        )}
                        <Button
                          onClick={() => {
                            form.setFieldsValue({ category: undefined });
                            setCategoryButton(!categoryButton);
                            setCategory("");
                          }}
                          style={{ marginLeft: 5 }}
                          type="primary"
                        >
                          {categoryButton
                            ? "Show category dropdown"
                            : "Template Field"}
                        </Button>
                      </View>
                    </Form.Item>
                    <Form.Item
                      label="Priority:"
                      name="priority"
                      rules={[
                        {
                          required: true,
                          message: "Please provide the priority value",
                        },
                      ]}
                    >
                      <Select
                        options={priorityOptions}
                        style={{ width: 175 }}
                        placeholder="Select priority value"
                        onChange={(value) => {
                          setPriority(value.toString());
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      preserve={false}
                      label="User:"
                      name="user"
                      rules={[
                        {
                          required: true,
                          message: "Please provide the user",
                        },
                      ]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {!userButton && (
                          <Select
                            placeholder="Select user"
                            value={form.getFieldValue("user")}
                            onChange={(value) => {
                              form.setFieldsValue({ user: value });
                              setUser(value.toString());
                            }}
                          >
                            {users.map((user) => (
                              <Select.Option key={user.id} value={user.id}>
                                {user.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {userButton && (
                          <Input
                            maxLength={255}
                            showCount
                            placeholder="Enter user name"
                            value={form.getFieldValue("user")}
                            onChange={(e) => {
                              form.setFieldsValue({ user: e.target.value });
                              setUser(e.target.value);
                            }}
                          />
                        )}
                        <Button
                          onClick={() => {
                            form.setFieldsValue({ user: undefined });
                            setUserButton(!userButton);
                            setUser("");
                          }}
                          style={{ marginLeft: 5 }}
                          type="primary"
                        >
                          {userButton ? "Show user dropdown" : "Template Field"}
                        </Button>
                      </View>
                    </Form.Item>
                  </>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      goTo(0);
                      setCurrentSlide(0);
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "rgba(22, 119, 255, 1)",
                      borderWidth: 1,
                      borderColor: "rgba(22, 119, 255, 1)",
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    onClick={async () => {
                      try {
                        await form.validateFields([
                          "taskTemplate",
                          "taskName",
                          "taskDescription",
                          "initialStatus",
                          "priority",
                          "category",
                          "user",
                        ]);
                        goTo(2);
                        setCurrentSlide(2);
                      } catch (error) {
                        // console.log(error);
                      }
                    }}
                  >
                    Next
                  </Button>
                </View>
              </Form>
            </div>
            <div>
              <Form style={{ paddingBottom: 20 }} layout="vertical" form={form}>
                {templateType !== "scheduledTemplate" && (
                  <>
                    {" "}
                    <Form.Item
                      label="Due Date:"
                      name="dueDate"
                      rules={[
                        {
                          required: true,
                          message: "Please provide the due date",
                        },
                      ]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {!dueDateButton && (
                          <DatePicker
                            value={form.getFieldValue("dueDate")}
                            format={"MM-DD-YYYY"}
                            onChange={(date, dateString) => {
                              form.setFieldsValue({ dueDate: date });
                              setDueDate(
                                moment(dateString).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        )}
                        {dueDateButton && (
                          <Input
                            maxLength={255}
                            showCount
                            placeholder="Enter a due date"
                            value={form.getFieldValue("dueDate")}
                            onChange={(e) => {
                              form.setFieldsValue({ dueDate: e.target.value });
                              setDueDate(e.target.value);
                            }}
                          />
                        )}
                        <Button
                          onClick={() => {
                            form.setFieldsValue({ dueDate: undefined });
                            setDueDateButton(!dueDateButton);
                            setDueDate("");
                          }}
                          style={{ marginLeft: 5 }}
                          type="primary"
                        >
                          {dueDateButton
                            ? "Show date picker"
                            : "Template Field"}
                        </Button>
                      </View>
                    </Form.Item>
                    <Form.Item
                      label="Deferrable:"
                      name="deferrable"
                      rules={[
                        {
                          required: true,
                          message: "Deferrable?",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Value"
                        onChange={(value) => {
                          setDeferrable(value);
                        }}
                      >
                        <Select.Option value="1">True</Select.Option>
                        <Select.Option value="0">False</Select.Option>
                      </Select>
                    </Form.Item>
                    {deferrable === "1" && (
                      <Form.Item
                        label="Defer Date"
                        name="deferDate"
                        rules={[
                          {
                            required: true,
                            message: "Select a defer date",
                          },
                        ]}
                      >
                        <View style={{ flexDirection: "row" }}>
                          {!deferDateButton && (
                            <DatePicker
                              value={form.getFieldValue("deferDate")}
                              format={"MM-DD-YYYY"}
                              onChange={(date, dateString) => {
                                form.setFieldsValue({ deferDate: date });
                                setDeferDate(
                                  dayjs(dateString).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          )}
                          {deferDateButton && (
                            <Input
                              maxLength={255}
                              showCount
                              placeholder="Enter a defer date"
                              value={form.getFieldValue("deferDate")}
                              onChange={(e) => {
                                form.setFieldsValue({
                                  deferDate: e.target.value,
                                });
                                setDeferDate(e.target.value);
                              }}
                            />
                          )}
                          <Button
                            onClick={() => {
                              form.setFieldsValue({ deferDate: undefined });
                              setDeferDateButton(!deferDateButton);
                              setDeferDate("");
                            }}
                            style={{ marginLeft: 5 }}
                            type="primary"
                          >
                            {deferDateButton
                              ? "Show defer date picker"
                              : "Template Field"}
                          </Button>
                        </View>
                      </Form.Item>
                    )}
                    <Form.Item
                      label="Missable:"
                      name="missable"
                      rules={[
                        {
                          required: true,
                          message: "Missable?",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Value"
                        onChange={(value) => {
                          setMissable(value);
                        }}
                      >
                        <Select.Option value="1">True</Select.Option>
                        <Select.Option value="0">False</Select.Option>
                      </Select>
                    </Form.Item>
                  </>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      goTo(1);
                      setCurrentSlide(1);
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "rgba(22, 119, 255, 1)",
                      borderWidth: 1,
                      borderColor: "rgba(22, 119, 255, 1)",
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    onClick={async () => {
                      form.setFieldsValue({ parameters: parameters });
                      try {
                        await form.validateFields([
                          "deferrable",
                          "dueDate",
                          "deferDate",
                          "missable",
                          "pastDueAction",
                          "triggerType",
                          "parameters",
                        ]);
                        goTo(3);
                        setCurrentSlide(3);
                      } catch (error) {
                        // console.log(error);
                      }
                    }}
                  >
                    Next
                  </Button>
                </View>
              </Form>
            </div>
            <div>
              <Form style={{ paddingBottom: 20 }} layout="vertical" form={form}>
                {templateType === "scheduledTemplate" && (
                  <>
                    <Form.Item
                      label="Trigger Type:"
                      name="triggerType"
                      rules={[
                        {
                          required: true,
                          message: "Please proved the trigger type",
                        },
                      ]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {!triggerTypeButton && <TriggerTypeDropdown />}
                        {triggerTypeButton && (
                          <Input
                            maxLength={255}
                            showCount
                            placeholder="Enter the trigger type"
                            value={form.getFieldValue("triggerType")}
                            onChange={(e) => {
                              form.setFieldsValue({
                                triggerType: e.target.value,
                              });
                              setTriggerType(e.target.value);
                            }}
                          />
                        )}
                        <Button
                          onClick={() => {
                            form.setFieldsValue({ triggerType: undefined });
                            setTriggerTypeButton(!triggerTypeButton);
                            setTriggerType("");
                          }}
                          style={{ marginLeft: 5 }}
                          type="primary"
                        >
                          {triggerTypeButton
                            ? "Show trigger type dropdown"
                            : "Template Field"}
                        </Button>
                      </View>
                    </Form.Item>
                    <View style={{ marginBottom: 10 }}>
                      {triggerType === "dom" && <DayOfMonthSelector />}
                      {triggerType === "dow" && <DaysOfWeekPicker />}
                      {triggerType === "nthWeekdays" && (
                        <>
                          <WeekPicker style={{ marginBottom: 10 }} />
                          <DaysOfWeekPicker />
                        </>
                      )}
                    </View>
                    <Form.Item label="Parameters:" name="parameters">
                      <div>
                        <Table
                          showHeader={false}
                          columns={[
                            {
                              title: "Parameter",
                              dataIndex: "parameterKey",
                              render: (_, record) => (
                                <KeyInput
                                  record={record}
                                  sourceParameters={parameters}
                                  setSourceParameters={setParameters}
                                />
                              ),
                            },
                            {
                              title: "Value",
                              dataIndex: "value",
                              render: (text, record) => (
                                <Input
                                  value={text}
                                  placeholder="Value"
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    const updatedParameters = [
                                      ...parameters,
                                    ];
                                    updatedParameters[record.index] = {
                                      index: record.index,
                                      key: record.key,
                                      value: newValue,
                                    };
                                    setParameters(updatedParameters);
                                  }}
                                />
                              ),
                            },
                          ]}
                          dataSource={parameters}
                          pagination={false}
                          size="small"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() => {
                              setParameters((prevArray) => [
                                ...prevArray,
                                {
                                  index: prevArray.length,
                                  key: "",
                                  value: "",
                                },
                              ]);
                            }}
                          >
                            Add Row
                          </Button>
                        </div>
                      </div>
                    </Form.Item>
                    {templateType === "scheduledTemplate" && (
                      <>
                        <Form.Item label="Source Type:" name="sourceType">
                          <Select
                            onChange={(value) => {
                              form.setFieldsValue({ sourceType: value });
                              setSourceType(value);
                            }}
                            options={[{ label: "Endpoint", value: "endpoint" }]}
                            placeholder="Select Source Type"
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          label="Source Parameters:"
                          name="sourceParameters"
                        >
                          <div>
                            <Table
                              showHeader={false}
                              columns={[
                                {
                                  title: "Parameter",
                                  render: (_, record) => (
                                    <KeyInput
                                      key={record.index}
                                      record={record}
                                      sourceParameters={sourceParameters}
                                      setSourceParameters={setSourceParameters}
                                    />
                                  ),
                                },
                                {
                                  title: "Value",
                                  dataIndex: "value",
                                  render: (text, record) => (
                                    <Input
                                      value={text}
                                      placeholder="Value"
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        const updatedParameters = [
                                          ...sourceParameters,
                                        ];
                                        updatedParameters[record.index] = {
                                          index: record.index,
                                          key: record.key,
                                          value: newValue,
                                        };
                                        setSourceParameters(updatedParameters);
                                      }}
                                    />
                                  ),
                                },
                              ]}
                              dataSource={sourceParameters}
                              pagination={false}
                              size="small"
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                type="primary"
                                onClick={() => {
                                  setSourceParameters((prevArray) => [
                                    ...prevArray,
                                    {
                                      index: prevArray.length,
                                      key: "",
                                      value: "",
                                    },
                                  ]);
                                }}
                              >
                                Add Row
                              </Button>
                            </div>
                          </div>
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
                {templateType !== "scheduledTemplate" && (
                  <>
                    <Form.Item label="Link:" name="link">
                      <Input
                        showCount
                        placeholder="Enter link"
                        onChange={(e) => {
                          setLink(e.target.value);
                        }}
                        value={link}
                      />
                    </Form.Item>
                    {link && link.length > 0 && (
                      <Form.Item label="Link Title:" name="linkTitle">
                        <Input
                          maxLength={255}
                          showCount
                          placeholder="Enter link title"
                          onChange={(e) => {
                            setLinkTitle(e.target.value);
                          }}
                          value={linkTitle}
                        />
                      </Form.Item>
                    )}
                    <Form.Item label="Tags:" name="tags">
                      <div>
                        <Input
                          placeholder="Enter tag and press enter"
                          maxLength={255}
                          showCount
                          onPressEnter={(e) => handleEnter(e.target.value)}
                          value={tagInputValue}
                          onChange={(e) => setTagInputValue(e.target.value)}
                        />
                        <div style={{ marginTop: 16 }}>
                          {tagOptions &&
                            tagOptions.map((tag) => (
                              <Tag
                                key={tag}
                                closable
                                onClose={() => handleClose(tag)}
                              >
                                {tag}
                              </Tag>
                            ))}
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item label="Reference Type:" name="referenceType">
                      <Input
                        maxLength={255}
                        showCount
                        placeholder="Enter reference type"
                        onChange={(e) => {
                          setReferenceType(e.target.value);
                        }}
                        value={referenceType}
                      />
                    </Form.Item>
                  </>
                )}

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      goTo(templateType === "scheduledTemplate" ? 0 : 2);
                      setCurrentSlide(
                        templateType === "scheduledTemplate" ? 0 : 2
                      );
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "rgba(22, 119, 255, 1)",
                      borderWidth: 1,
                      borderColor: "rgba(22, 119, 255, 1)",
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    onClick={async () => {
                      try {
                        await form.validateFields(
                          templateType !== "scheduledTemplate"
                            ? [
                                "templateDescription",
                                "templateName",
                                "taskName",
                                "taskDescription",
                                "initialStatus",
                                "priority",
                                "category",
                                "user",
                                "deferrable",
                                "dueDate",
                                "deferDate",
                                "missable",
                                "pastDueAction",
                              ]
                            : [
                                "templateName",
                                "templateDescription",
                                "scheduledTemplateName",
                                "scheduledTemplateDescription",
                                "taskTemplate",
                                "taskName",
                                "taskDescription",
                                "category",
                                "triggerType",
                                "parameters",
                                "sourceType",
                                "sourceParameters",
                              ]
                        );
                        goTo(3);
                        setCurrentSlide(3);
                        {
                          rowId === undefined
                            ? createNewTemplate()
                            : updateTemplate();
                        }
                      } catch (error) {
                        const errorMessages = [
                          ...new Set(
                            error.errorFields.map(
                              (fieldError) => fieldError.name[0]
                            )
                          ),
                        ];

                        const splitErrorMessages = errorMessages.map(
                          (errorMessage) =>
                            errorMessage.split(/(?=[A-Z])/).join(" ")
                        );

                        const capitalizedErrorMessages = splitErrorMessages.map(
                          (errorMessage) =>
                            errorMessage.charAt(0).toUpperCase() +
                            errorMessage.slice(1)
                        );

                        notification.error({
                          duration: null,
                          message: "Invalid Form",
                          description:
                            "The following fields are invalid: " +
                            capitalizedErrorMessages.join(", "),
                        });
                      }
                    }}
                  >
                    Submit
                  </Button>
                </View>
              </Form>
            </div>
          </Carousel>
        }
      />
    </>
  );
}

export default TemplateModal;
