import { Layout, Table, Modal } from "antd";
import { Dimensions, View } from "react-native";
import { useState } from "react";
import TaskModal from "./taskModal";
import "./taskList.css";
import DraggableModal from "./draggableModal";

function NormalTable({
  locale,
  columns,
  processedData,
  handleRowClick,
  onOk,
  onCancel,
  modalVisible,
  selectedRow,
  rowId,
  filteredData,
  tableLoading,
  rowClassName,
  handleRefresh,
  claimedPressed,
  setClaimedPressed,
  newDataForClaimedTasks,
  users,
  completedTasksTable,
  subTaskItemPressed,
  setSubTaskItemPressed,
}) {
  const { Content } = Layout;
  const { width, height } = Dimensions.get("window");
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [messageInputFocused, setMessageInputFocused] = useState(false);
  const [actionDropdownFocused, setActionDropdownFocused] = useState(false);
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 750);
  
  const handleDatePickerFocus = () => {
    setDatePickerFocused(true);
  };

  const handleDatePickerBlur = () => {
    setDatePickerFocused(false);
  };

  const handleMessageInputFocus = () => {
    setMessageInputFocused(true);
  };

  const handleMessageInputBlur = () => {
    setMessageInputFocused(false);
  };

  const handleActionDropdownFocus = () => {
    setActionDropdownFocused(true);
  };

  const handleActionDropdownBlur = () => {
    setActionDropdownFocused(false);
  };

  return (
    <div>
      <Content>
        <Table
          loading={completedTasksTable ? false : loading}
          columns={columns}
          dataSource={
            completedTasksTable
              ? processedData
              : loading
              ? undefined
              : processedData
          }
          onRow={handleRowClick}
          rowClassName={rowClassName}
          locale={locale}
        />
      </Content>
      <DraggableModal
        maskStyle={{ zIndex: 1 }}
        className="modalStyle"
        footer={null}
        style={
          filteredData.some((item) => item.hasOwnProperty("active"))
            ? { minWidth: "40%", zIndex: 10 }
            : { minWidth: width < 1025 ? "90%" : "70%", zIndex: 10 }
        }
        centered
        open={modalVisible}
        onOk={onOk}
        onCancel={onCancel}
        handleRefresh={handleRefresh}
        modalBody={
          selectedRow && (
            <TaskModal
              users={users}
              selectedRow={selectedRow}
              filteredData={filteredData}
              rowId={rowId}
              handleRefresh={handleRefresh}
              modalVisible={modalVisible}
              claimedPressed={claimedPressed}
              setClaimedPressed={setClaimedPressed}
              onDatePickerFocus={handleDatePickerFocus}
              onDatePickerBlur={handleDatePickerBlur}
              onMessageInputFocus={handleMessageInputFocus}
              onMessageInputBlur={handleMessageInputBlur}
              onActionDropdownFocus={handleActionDropdownFocus}
              onActionDropdownBlur={handleActionDropdownBlur}
              newDataForClaimedTasks={newDataForClaimedTasks}
              subTaskItemPressed={subTaskItemPressed}
              setSubTaskItemPressed={setSubTaskItemPressed}
            />
          )
        }
        disableDragging={[
          datePickerFocused,
          messageInputFocused,
          actionDropdownFocused,
        ]}
      />
    </div>
  );
}

export default NormalTable;
