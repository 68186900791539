import { StyleSheet, Text, View } from 'react-native';
import TaskList from '../components/TaskList.js';

const TaskListPage = (props) => {
    return (
        <>
            <TaskList />
        </>
    );
};

const styles = StyleSheet.create({
    container: {
    }
});

export default TaskListPage;
